import { useAppSelector } from '@/common';
import { HistoricalDepartureCarCount, selectDepartureCarCountHistorical15Min } from '@/stores/departures';
import { useMemo } from 'react';
import { WidgetWrapper } from '../components/wrapper';
import { selectOnPaceForHour } from '@/stores/onPace';
import WrappedOnPaceChart from '../components/charts/onPaceChart';
import { startOfHour, addMinutes, endOfHour, parseISO, addHours, isSameMinute, isAfter, isBefore } from 'date-fns';
import { type DepartureDatum } from '../components/charts/onPaceChart/types';
import { StandardWidgetProps } from '@/types';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'onPace',
  displayName: 'On Pace',
  description: 'Placeholder description',
};

export interface OnPaceProps extends StandardWidgetProps {}

const transformRecords = (startDate: Date, records: number[]): DepartureDatum[] => {
  if (records.length === 0) {
    return []; // Use an empty records array to indicate records are absent. Use this to conditionally render
  }

  const transformedRecords = [];
  transformedRecords.push({
    date: startDate,
    value: 0,
  });

  transformedRecords.push({
    date: addMinutes(startDate, 15),
    value: records[0],
  });

  transformedRecords.push({
    date: addMinutes(startDate, 30),
    value: records[1],
  });

  transformedRecords.push({
    date: addMinutes(startDate, 45),
    value: records[2],
  });

  transformedRecords.push({
    date: addMinutes(startDate, 60),
    value: records[3],
  });

  return transformedRecords;
};

const isSameOrAfter = (compareThis: Date, toThis: Date) => {
  return isSameMinute(compareThis, toThis) || isAfter(compareThis, toThis);
};

export const transformDepartures = (startDate: Date, departures: HistoricalDepartureCarCount[]): DepartureDatum[] => {
  // Find the historic departure counts that are within the current window. There may be up to 4.
  // Also, make sure that they're before 'now'. This is to stop showing data from the future, when using preConfiged test data
  const endDate = addHours(startDate, 1);
  const now = new Date();

  const dataInWindow = departures
    .filter((item) => {
      const intervalStart = parseISO(item.intervalStartTime);
      return (
        isSameOrAfter(intervalStart, startDate) && isBefore(intervalStart, endDate) && isBefore(intervalStart, now)
      );
    })
    .map((item) => {
      const intervalStart = parseISO(item.intervalStartTime);

      let endOfFifteenMins = addMinutes(intervalStart, 15);
      const modifiedDate = isAfter(endOfFifteenMins, now) ? now : endOfFifteenMins; // For historical data, we want to plot the final count at the END of the 15 minute block. But for the current 15 minute interval, we want to to plot that value NOW

      return {
        date: modifiedDate,
        value: item.departCarCount,
      };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  dataInWindow.unshift({
    date: startDate,
    value: 0,
  });

  let accumulator = 0;
  dataInWindow.forEach((datum) => {
    accumulator = accumulator + datum.value;
    datum.value = accumulator;
  });

  return dataInWindow;
};

const transformPrediction = (
  startDate: Date,
  prediction: number[],
  departuresCumulative: DepartureDatum[],
): DepartureDatum[] => {
  // Set the prediction value to the max of current and prediction, to prevent showing a prediction lower than the current
  const currents = departuresCumulative.map((departuresCumulative) => {
    return departuresCumulative.value;
  });
  const maxCurrent = Math.max(...currents);

  const output = prediction.map((pred) => {
    return {
      date: endOfHour(startDate),
      value: Math.max(pred, maxCurrent),
    };
  });
  return output;
};

const OnPace = (props: OnPaceProps & { children?: JSX.Element }): JSX.Element => {
  const onPaceForHour = useAppSelector(selectOnPaceForHour);

  const departCarCount15MinHistorical = useAppSelector(selectDepartureCarCountHistorical15Min('DELIVER'));

  const nowForDepartures = new Date();

  const { startDatetime } = useMemo(() => {
    const now = new Date();
    return {
      latestEvenDatetime: new Date(),
      startDatetime: startOfHour(now),
    };
  }, [departCarCount15MinHistorical]);

  const recordsCumulative = useMemo(() => {
    return transformRecords(startDatetime, onPaceForHour.records);
  }, [startDatetime, onPaceForHour.records]);
  const departuresCumulative = useMemo(() => {
    return transformDepartures(startDatetime, departCarCount15MinHistorical);
  }, [startDatetime, departCarCount15MinHistorical]);
  const prediction = useMemo(
    () => transformPrediction(startDatetime, onPaceForHour.prediction, departuresCumulative),
    [startDatetime, onPaceForHour.prediction],
  );

  const data = {
    recordsCumulative,
    departuresCumulative,
    prediction,
  };

  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <WrappedOnPaceChart data={data} />
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

export default OnPace;
