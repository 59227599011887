import { useContext, useMemo } from 'react';
import { type GaugeSVGProps, GaugeSVG } from './svg/GaugeSVG';
import { WidgetContentContext } from '../wrapper/widgetContentContext';
import WidgetMetricWrapper, { StandardMetricWrapperProps } from '../wrapper/WidgetMetricWrapper';

interface GaugeProps extends Omit<GaugeSVGProps, 'size'>, Omit<StandardMetricWrapperProps, 'children'> {
  measureUnit?: string;
  isActive?: boolean;
}

export const Gauge = (props: GaugeProps): JSX.Element => {
  const {
    maxValue,
    metricValue,
    targetValue,
    invert,
    textWidthPercentage = '60%',
    textHeightPercentage = '40%',
    isActive = true,
  } = props;

  const widgetContentContextValue = useContext(WidgetContentContext);

  const gaugeSize = useMemo(
    () => ({
      width:
        widgetContentContextValue.size.width -
        widgetContentContextValue.padding.left -
        widgetContentContextValue.padding.right,
      height:
        widgetContentContextValue.size.height -
        widgetContentContextValue.padding.top -
        widgetContentContextValue.padding.bottom,
    }),
    [
      widgetContentContextValue.size.width,
      widgetContentContextValue.size.height,
      widgetContentContextValue.padding.left,
      widgetContentContextValue.padding.right,
      widgetContentContextValue.padding.top,
      widgetContentContextValue.padding.bottom,
    ],
  );

  return (
    <WidgetMetricWrapper
      {...props}
      textHeightPercentage={textHeightPercentage}
      textWidthPercentage={textWidthPercentage}
    >
      <GaugeSVG
        size={gaugeSize}
        maxValue={maxValue}
        metricValue={metricValue}
        targetValue={targetValue}
        invert={invert}
        isActive={isActive}
      />
    </WidgetMetricWrapper>
  );
};
