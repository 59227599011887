import { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import Dashboard from '@/pages/Dashboard';

/*
 * The dashboard can be displayed either as a component within the Eyecue Control Center or in full-screen mode.
 * - In full-screen mode, the dashboard will adapt to the screen's aspect ratio.
 * - As a component, the dashboard maintains a 16:9 aspect ratio whenever possible.
 * However, this may cause overflow (either horizontally or vertically) depending on the screen size.
 * - To ensure the dashboard always fits within the viewport, we use the "IntersectionObserver" to monitor the top-right corner and the bottom of the dashboard,
 * adjusting its style as needed. For example, we enforce the height to prevent vertical overflow.
 * */
const useIntersectionObserver = (ref: any, onChange: (isIntersecting: boolean) => void): void => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      onChange(target.isIntersecting);
    });

    if (ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current !== null) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, onChange]);
};

const Preview = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const heightOffset = useMemo(() => {
    const params = new URLSearchParams(searchParams);
    return params.get('heightOffset') ?? '0';
  }, [searchParams]);

  const bottomObserver = useRef(null);
  const rightObserver = useRef(null);

  // Using style instead of Tailwind CSS because h-[calc(100vh-${heightOffset})] doesn't work on Safari
  const [style, setStyle] = useState<Record<string, string>>({ aspectRatio: '16 / 9' });

  useIntersectionObserver(bottomObserver, (isIntersecting) => {
    if (!isIntersecting) {
      setStyle({ height: `calc(-${heightOffset} + 100vh)` });
    }
  });

  useIntersectionObserver(rightObserver, (isIntersecting) => {
    if (!isIntersecting) {
      setStyle({ aspectRatio: '16 / 9' });
    }
  });

  return (
    <div style={style}>
      {/* Top right corner target */}
      <div className='flex h-0 w-full justify-end'>
        <div ref={rightObserver}></div>
      </div>
      {/* Dashboard */}
      <Dashboard editable>
        <Outlet />
      </Dashboard>
      {/* Bottom target */}
      <div className='mt-5'>
        <div ref={bottomObserver}></div>
      </div>
    </div>
  );
};

export default Preview;
