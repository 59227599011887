import {
  type LiveDepartureEvent,
  type DepartureState,
  type HistoricalDepartureRate,
  type HistoricalDepartureCarCount,
} from '../departures';
import { type ArrivalState } from '../arrivals';
import { type RoisState } from '../rois';
import { type SiteConfigState } from '../siteConfigs';
import { subMinutes, addSeconds, startOfHour, addMinutes, subHours } from 'date-fns';
import { random } from 'lodash';
import { clientConfig, dashboard, siteConfig, targetConfig } from './preloadedConfig';
import { type QueuesState } from '../queues';
import { type AveragesState } from '../averages';
import { type DashboardConfigState } from '@/stores/sites/dashboardConfigs';
import { REQUEST_STATUS } from '@/stores/sites/types';
import { LeaderboardMetric, type LeaderboardState } from '@/stores/leaderboard';
import leaderboardData from './leaderboardSampleData';

const buildPreloadedLiveDepartureEvents = (): LiveDepartureEvent[] => {
  const periodStart = subMinutes(new Date(), 15);

  const templateEvent = {
    cameraId: '013',
    roiId: 'deliver-1',
    lane: 1,
    roiType: 'DELIVER',
    siteId: 'fm-tst-nzd-0001',
  };

  const numEvents = 5;
  const events: LiveDepartureEvent[] = Array.from({ length: numEvents }, (_, i) => {
    const date = addSeconds(periodStart, random(-0.5, 0.5) * 60 * 5 * i); // Spaced about every minute, in a range of +/- 2.5 minutes
    return { ...templateEvent, vehicleId: `${i}`, timestamp: date.toISOString() };
  });
  return events;
};

const retimestampDepartEvents = <T extends { intervalStartTime: string }>(departures: T[]): T[] => {
  const now = new Date();
  const startOfThreeHoursAgo = subHours(startOfHour(now), 3);

  const retimestampedDepartures = departures.map((departure, i) => {
    const updatedDate = addMinutes(startOfThreeHoursAgo, 15 * i);
    const updatedInterval = updatedDate.toISOString();
    return {
      ...departure,
      intervalStartTime: updatedInterval,
    };
  });
  return retimestampedDepartures;
};

const configState: SiteConfigState = {
  siteConfig,
};

const dashboardState: DashboardConfigState = {
  data: dashboard,
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

const targetConfigState = {
  data: targetConfig,
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

const generalSiteConfigState = {
  data: {
    clientConfig,
    siteConfig: {
      clientId: 'tst',
      siteId: 'fm-tst-nzl-0001',
      details: {
        name: 'Test Site',
        timezone: 'America/Detroit',
      },
    },
  },
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

const departuresState: DepartureState = {
  departRate: {
    current: [
      {
        avgDepartInterval: 15.10747229411765,
        roiId: 0,
        roiType: 'DELIVER',
        version: 2,
      },
    ],
    interval15Min: <HistoricalDepartureRate[]>retimestampDepartEvents([
      {
        departRate: 65.50566333333335,
        intervalStartTime: '2023-11-21T21:30:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 50.184978124999994,
        intervalStartTime: '2023-11-21T21:15:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 34.525173277777775,
        intervalStartTime: '2023-11-21T21:00:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 39.91071985714286,
        intervalStartTime: '2023-11-21T20:45:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 52.487726384615385,
        intervalStartTime: '2023-11-21T20:30:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 33.317283624999995,
        intervalStartTime: '2023-11-21T20:15:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 33.60020847999999,
        intervalStartTime: '2023-11-21T20:00:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 47.7441896875,
        intervalStartTime: '2023-11-21T19:45:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 31.42002848148148,
        intervalStartTime: '2023-11-21T19:30:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 41.05136634782609,
        intervalStartTime: '2023-11-21T19:15:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 38.53789456,
        intervalStartTime: '2023-11-21T19:00:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 20.21326062162162,
        intervalStartTime: '2023-11-21T18:45:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 22.283514000000004,
        intervalStartTime: '2023-11-21T18:30:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 20.05536165116279,
        intervalStartTime: '2023-11-21T18:15:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departRate: 29.43127513793103,
        intervalStartTime: '2023-11-21T18:00:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
    ]),
  },
  departCarCount: {
    current: [
      {
        departCars: 60,
        roiId: 0,
        roiType: 'DELIVER',
        version: 2,
      },
    ],
    interval15Min: <HistoricalDepartureCarCount[]>retimestampDepartEvents([
      {
        departCarCount: 10,
        intervalStartTime: '2023-11-21T21:30:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 17,
        intervalStartTime: '2023-11-21T21:15:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 19,
        intervalStartTime: '2023-11-21T21:00:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 15,
        intervalStartTime: '2023-11-21T20:45:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 14,
        intervalStartTime: '2023-11-21T20:30:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 25,
        intervalStartTime: '2023-11-21T20:15:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 26,
        intervalStartTime: '2023-11-21T20:00:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 17,
        intervalStartTime: '2023-11-21T19:45:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 28,
        intervalStartTime: '2023-11-21T19:30:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 24,
        intervalStartTime: '2023-11-21T19:15:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 26,
        intervalStartTime: '2023-11-21T19:00:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 38,
        intervalStartTime: '2023-11-21T18:45:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 38,
        intervalStartTime: '2023-11-21T18:30:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 44,
        intervalStartTime: '2023-11-21T18:15:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
      {
        departCarCount: 30,
        intervalStartTime: '2023-11-21T18:00:00+00:00',
        roiId: 0,
        roiType: 'DELIVER',
      },
    ]),
  },
  mostDepartureCount: {
    fromTime: '2023-06-15T13:51:35Z',
    toTime: '2023-06-15T14:51:35Z',
    departCars: 459,
    version: 1,
  },
  liveDepartureEvents: buildPreloadedLiveDepartureEvents(),
  activeDepartureRate: {
    interval: 15,
    lanes: [
      {
        roiId: 'depart-1',
        lane: 1,
        activeDepartureRate: 14,
        isActive: true,
      },
      {
        roiId: 'depart-2',
        lane: 2,
        activeDepartureRate: 10,
        isActive: false,
      },
    ],
    total: { activeDepartureRate: 15, isActive: true },
  },
};

const arrivalsState: ArrivalState = {
  liveArrivalEvents: [],
};

// Function to generate a dynamic timestamp (e.g., 10 seconds ago)
export const generateDynamicTimestamp = (): string => {
  const currentDate = new Date();
  currentDate.setSeconds(currentDate.getSeconds() - 10);
  return currentDate.toISOString();
};

const roisState: RoisState = {
  rois: {
    roiId1: {
      roiId: 'deliver-dwell-1-a',
      assetsInfo: [
        {
          id: 'af56bed7-d3bd-4e14-8e63-fbd5cfc2a338',
          vehicleId: 'af56bed7-d3bd-4e14-8e63-fbd5cfc2a338',
          entryTime: '2024-01-19T21:19:25.180533-05:00',
        },
        {
          id: '9a22a053-e10d-4d51-bc01-c0f8e28686cd',
          vehicleId: '9a22a053-e10d-4d51-bc01-c0f8e28686cd',
          entryTime: '2024-01-19T21:19:17.684985-05:00',
        },
      ],
      vehicleCount: 1,
      leadCarResetTime: generateDynamicTimestamp(),
    },
    roiId2: {
      roiId: 'deliver-dwell-2-a',
      assetsInfo: [
        {
          id: 'af56bed7-d3bd-4e14-8e63-fbd5cfc2a338',
          vehicleId: 'af56bed7-d3bd-4e14-8e63-fbd5cfc2a338',
          entryTime: '2024-01-19T21:19:25.180533-05:00',
        },
        {
          id: '9a22a053-e10d-4d51-bc01-c0f8e28686cd',
          vehicleId: '9a22a053-e10d-4d51-bc01-c0f8e28686cd',
          entryTime: '2024-01-19T21:19:17.684985-05:00',
        },
      ],
      vehicleCount: 0,
      leadCarResetTime: generateDynamicTimestamp(),
    },
    roiId3: {
      roiId: 'deliver-dwell-3-a',
      assetsInfo: [
        {
          id: 'af56bed7-d3bd-4e14-8e63-fbd5cfc2a338',
          vehicleId: 'af56bed7-d3bd-4e14-8e63-fbd5cfc2a338',
          entryTime: '2024-01-19T21:19:25.180533-05:00',
        },
        {
          id: '9a22a053-e10d-4d51-bc01-c0f8e28686cd',
          vehicleId: '9a22a053-e10d-4d51-bc01-c0f8e28686cd',
          entryTime: '2024-01-19T21:19:17.684985-05:00',
        },
      ],
      vehicleCount: 2,
      leadCarResetTime: generateDynamicTimestamp(),
    },
    roiId4: {
      roiId: 'deliver-dwell-4-a',
      assetsInfo: [
        {
          id: 'af56bed7-d3bd-4e14-8e63-fbd5cfc2a338',
          vehicleId: 'af56bed7-d3bd-4e14-8e63-fbd5cfc2a338',
          entryTime: '2024-01-19T21:19:25.180533-05:00',
        },
        {
          id: '9a22a053-e10d-4d51-bc01-c0f8e28686cd',
          vehicleId: '9a22a053-e10d-4d51-bc01-c0f8e28686cd',
          entryTime: '2024-01-19T21:19:17.684985-05:00',
        },
      ],
      vehicleCount: 1,
      leadCarResetTime: generateDynamicTimestamp(),
    },
  },
};

const queuesState: QueuesState = {
  functionalZones: {
    version: 1,
    functionalZones: {
      entry: {
        queueLevel: 3,
        timeUtc: '2020-03-20T01:31:12.467113+00:00',
      },
      preorder: {
        queueLevel: 0,
        timeUtc: '2020-03-20T01:31:12.467113+00:00',
      },
      order: {
        queueLevel: 2,
        timeUtc: '2020-03-20T01:31:12.467113+00:00',
      },
      payment: {
        queueLevel: 0,
        timeUtc: '2020-03-20T01:31:12.467113+00:00',
      },
      deliver: {
        queueLevel: 1,
        timeUtc: '2020-03-20T01:31:12.467113+00:00',
      },
      waitingBay: {
        queueLevel: 0,
        timeUtc: '2020-03-20T01:31:12.467113+00:00',
      },
      pullForward: {
        queueLevel: 0,
        timeUtc: '2020-03-20T01:31:12.467113+00:00',
      },
      exit: {
        queueLevel: 0,
        timeUtc: '2020-03-20T01:31:12.467113+00:00',
      },
    },
  },
};

const averagesState: AveragesState = {
  rolling60MinutesAverages: {
    boomGateAverage: 35,
    order1Average: 33,
    order2Average: 35,
    paymentAverage: 18,
    deliverAverage: 23,
    waitingAverage: 25,
    pullForwardAverage: 0,
    orderStartToDeliverEndAverage: 89,
    orderEndToDeliverEndAverage: 61,
    perfectCarCount: 45,
  },
  rolling15MinutesAverages: {
    boomGateAverage: 25,
    order1Average: 23,
    order2Average: 25,
    paymentAverage: 15,
    deliverAverage: 13,
    waitingAverage: 15,
    pullForwardAverage: 0,
    orderStartToDeliverEndAverage: 79,
    orderEndToDeliverEndAverage: 51,
    perfectCarCount: 80,
  },
};

const onPaceState = {
  data: {
    timestamp: new Date().toISOString(),
    records: {
      departures: {
        quarterly: [91, 142, 161, 170],
      },
    },
    predictions: {
      departures: {
        hourly: [131],
      },
    },
    version: 1,
  },
};

const notificationsState = {
  data: {
    notifications: [],
  },
};

const leaderboardState: LeaderboardState = {
  [LeaderboardMetric.PERFECT_CAR_COUNT]: leaderboardData,
  [LeaderboardMetric.OEPE]: [],
};

export default {
  departures: departuresState,
  arrivals: arrivalsState,
  rois: roisState,
  stores: configState,
  queues: queuesState,
  averages: averagesState,
  onPace: onPaceState,
  dashboardConfigs: dashboardState,
  generalSiteConfigs: generalSiteConfigState,
  targetConfigs: targetConfigState,
  notifications: notificationsState,
  leaderboard: leaderboardState,
};
