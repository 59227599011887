import { useAppSelector } from '@/common';
import { selectLogs } from '@/stores/logs';
import classNames from 'classnames';

const Debug = () => {
  const logs = useAppSelector(selectLogs);

  return (
    <div className='fixed top-0 left-0 w-full h-full p-8 text-base bg-black bg-opacity-90 text-left z-30 overflow-scroll'>
      {logs.map(({ level, message }, index) => (
        <div
          key={`debug-${index}`}
          className={classNames(level === 'info' ? 'text-success' : level === 'error' ? 'text-error' : 'text-warning')}
        >
          [{level}]: {message}
        </div>
      ))}
    </div>
  );
};

export default Debug;
