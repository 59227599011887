import { Gauge } from '@/widgets/components/gauge';
import {
  formatDurationToMinutesAndSeconds,
  getThisMomentTargetOrLastAvailable,
  getThisMomentTimeZone,
  useAppSelector,
} from '@/common';
import { selectActiveDepartureRate } from '@/stores/departures';
import { WidgetWrapper } from '../components/wrapper';
import { selectSiteTimeZone, selectTargetIntervals } from '@/stores/siteConfigs';
import { FeatureFlags, StandardWidgetProps } from '@/types';
import { selectClientTargets, selectDurationFormat } from '@/stores/sites/generalConfigs';
import { Timed } from 'react-time-sync';
import TimeSync from 'time-sync';
import { getThisMomentTarget } from '@/stores/sites/utils';
import { TargetType } from '@/stores/sites/types';
import { selectSiteTargets } from '@/stores/sites/targetConfigs';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

export const widgetMetadata: WidgetMetadata = {
  title: 'activeDepartureRate',
  displayName: 'Departure Rate (Active)',
  description: 'Placeholder Description',
};

export interface ActiveDepartureRateProps extends StandardWidgetProps {}

interface ActiveDepartureRateGaugeProps {
  target: number;
}

const ActiveDepartureRate = (props: ActiveDepartureRateProps & { children?: JSX.Element }): JSX.Element => {
  const timezone = useAppSelector(selectSiteTimeZone);
  const targetIntervals = useAppSelector(selectTargetIntervals);
  const siteTarget = useAppSelector(selectSiteTargets);
  const clientTargets = useAppSelector(selectClientTargets);
  const { interval } = useAppSelector(selectActiveDepartureRate);
  const { [FeatureFlags.eyecueDashboardUseAdrDefaultTitle]: eyecueDashboardUseAdrDefaultTitle = false } = useFlags();

  const title = useMemo(() => {
    // Only apply to TET widgets & when the flag is enabled
    if (eyecueDashboardUseAdrDefaultTitle) {
      const rollingPeriod = interval === 0 ? '' : `${interval} min `;
      return `${rollingPeriod}departure rate`;
    }
    return props.title;
  }, [eyecueDashboardUseAdrDefaultTitle, interval, props.title]);

  return (
    <WidgetWrapper {...props} title={title} className='pt-5 pb-[15px] px-[30px]'>
      <Timed interval={TimeSync.MINUTES} unit={1}>
        {() => {
          window.logger.debug(`Get next target rate.`);

          const thisMoment = getThisMomentTimeZone(timezone);

          // Use fallback approach to get target
          // TODO: The 'getThisMomentTargetOrLastAvailable' can be removed after migrating all site to the new 'store' table
          const target =
            (siteTarget !== undefined
              ? getThisMomentTarget(thisMoment, TargetType.DepartureRate, siteTarget.targets, clientTargets)
              : getThisMomentTargetOrLastAvailable(
                  { ...thisMoment },
                  targetIntervals?.targetRates[thisMoment.dayOfWeek.toLowerCase()] ?? {},
                )) ?? 0;

          return <ActiveDepartureRateGauge target={target} />;
        }}
      </Timed>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

const ActiveDepartureRateGauge = (props: ActiveDepartureRateGaugeProps) => {
  // get total active departure rate
  const {
    total: { isActive, activeDepartureRate },
  } = useAppSelector(selectActiveDepartureRate);
  const durationFormat = useAppSelector(selectDurationFormat);

  const { target } = props;

  const seconds = Math.round(Math.max(activeDepartureRate, 0));
  const displayValue = formatDurationToMinutesAndSeconds(seconds, durationFormat);
  const targetDisplayValue = formatDurationToMinutesAndSeconds(target, durationFormat);

  return (
    <Gauge
      maxValue={target * 2}
      metricValue={seconds}
      displayValue={displayValue}
      targetValue={target}
      invert={true}
      secondaryText={targetDisplayValue}
      isActive={isActive}
    />
  );
};

export default ActiveDepartureRate;
