import DashboardBrowser from '@/bootstrap/DashboardBrowser';
import BrowserDev from '@/bootstrap/BrowserDev';
import Kiosk from '@/bootstrap/Kiosk';
import Mosaic from '@/bootstrap/Mosaic';
import SwapWidget from '@/modules/swapWidget';
import WidgetConfiguration from '@/modules/widgetConfiguration';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import TemplateBrowser from '@/bootstrap/TemplateBrowser';
import Live from '@/pages/Live';
import Preview from '@/pages/Preview';

const AppRouter = (): JSX.Element => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='admin/:clientId/:siteId/dashboards' element={<Outlet />}>
          <Route path='all/:dashboardId' element={<DashboardBrowser />}>
            <Route path='live' element={<Live />} />
            <Route path='preview' element={<Preview />}>
              <Route path='configuration' element={<WidgetConfiguration />} />
              <Route path='swap' element={<SwapWidget />} />
              <Route path='swap/configuration' element={<WidgetConfiguration />} />
            </Route>
          </Route>
          <Route path='templates/:templateId' element={<TemplateBrowser />}>
            <Route path='preview' element={<Preview />}>
              <Route path='configuration' element={<WidgetConfiguration />} />
            </Route>
          </Route>
        </Route>
        <Route path='kiosk/' element={<Kiosk />}>
          <Route path=':clientId/:siteId/:serialNumber' element={<Live />} />
        </Route>
        <Route path='mosaic/' element={<Mosaic />}>
          <Route path=':clientId/:siteId' element={<Live />} />
        </Route>
        <Route path='/' element={<BrowserDev />}>
          <Route path='dashboard' element={<Live />} />
        </Route>
      </>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
