import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from './index';
import { CONNECTION_STATE } from '@/types';
import { isEmpty } from '@/common';

export interface IoTConnectionsState {
  connectionId?: string;
  connectionState: CONNECTION_STATE;
}

const initialState: IoTConnectionsState = {
  connectionId: undefined,
  connectionState: CONNECTION_STATE.DISCONNECTED,
};

export const ioTconnections = createSlice({
  name: 'ioTconnections',
  initialState,
  reducers: {
    setConnectionState: (state, { payload }: PayloadAction<IoTConnectionsState>) => {
      const { connectionId, connectionState } = payload;
      if (!isEmpty(connectionId) && connectionId === state.connectionId) {
        state.connectionState = connectionState;
      }
    },
    setConnectionId: (state, { payload }: PayloadAction<string>) => {
      state.connectionId = payload;
    },
  },
});

export default ioTconnections.reducer;

export const { setConnectionState, setConnectionId } = ioTconnections.actions;
export const selectConnectionState = (state: RootState): CONNECTION_STATE => state.ioTconnections.connectionState;
