import { Gauge } from '@/widgets/components/gauge';
import { getThisMomentTargetOrLastAvailable, getThisMomentTimeZone, isEmpty, useAppSelector } from '@/common';
import { type CurrentDepartureCarCount, selectDepartureCarCountCurrent } from '@/stores/departures';
import reduce from 'lodash/reduce';
import sum from 'lodash/sum';
import { useMemo } from 'react';
import { WidgetWrapper } from '../components/wrapper';
import { selectSiteTimeZone, selectTargetIntervals } from '@/stores/siteConfigs';
import { StandardWidgetProps } from '@/types';
import { TargetType } from '@/stores/sites/types';
import { selectSiteTargets } from '@/stores/sites/targetConfigs';
import { getThisMomentTarget } from '@/stores/sites/utils';
import { Timed } from 'react-time-sync';
import TimeSync from 'time-sync';
import Validation from '@/widgets/departureCount/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';
import { selectClientTargets } from '@/stores/sites/generalConfigs';

export const widgetMetadata: WidgetMetadata = {
  title: 'departureCount',
  displayName: 'Departure Count',
  description: 'Placeholder description',
};

export interface DepartureCountProps extends StandardWidgetProps {
  roiType: string;
}

const DepartureCount = (props: DepartureCountProps): JSX.Element => {
  const { roiType } = props;
  const timezone = useAppSelector(selectSiteTimeZone);
  const targetIntervals = useAppSelector(selectTargetIntervals);
  const siteTarget = useAppSelector(selectSiteTargets);
  const clientTargets = useAppSelector(selectClientTargets);

  // Get current departure car count
  const currentDepartureCarCount = useAppSelector(selectDepartureCarCountCurrent);

  const zoneDepartureCount = useMemo(
    () =>
      reduce(
        currentDepartureCarCount,
        (result: Record<string, number[]>, value: CurrentDepartureCarCount) => {
          (result[value.roiType] ?? (result[value.roiType] = [])).push(value.departCars);
          return result;
        },
        {},
      ),
    [currentDepartureCarCount],
  );

  const value = useMemo(() => {
    return zoneDepartureCount[roiType] === undefined || zoneDepartureCount[roiType].length === 0
      ? 0
      : sum(zoneDepartureCount[roiType]);
  }, [roiType, zoneDepartureCount]);

  return (
    <Timed interval={TimeSync.MINUTES} unit={1}>
      {() => {
        window.logger.debug(`Get next target count.`);

        const thisMoment = getThisMomentTimeZone(timezone);

        // Use fallback approach to get target
        // TODO: The 'getThisMomentTargetOrLastAvailable' can be removed after migrating all site to the new 'store' table
        const target =
          (siteTarget !== undefined
            ? getThisMomentTarget(thisMoment, TargetType.DepartureCount, siteTarget.targets, clientTargets)
            : getThisMomentTargetOrLastAvailable(
                { ...thisMoment },
                targetIntervals?.targetCounts[thisMoment.dayOfWeek.toLowerCase()] ?? {},
              )) ?? 0;

        return <Gauge maxValue={target * 2} metricValue={value} targetValue={target} secondaryText='15min' />;
      }}
    </Timed>
  );
};

export default function (props: DepartureCountProps & { children?: JSX.Element }): JSX.Element {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Validation {...props}>
        <DepartureCount {...props} />
      </Validation>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
}
