import { WidgetWrapper } from '../components/wrapper';
import { useMemo } from 'react';
import { formatDurationToMinutesAndSeconds, isEmpty, useAppSelector } from '@/common';
import { selectLeadCarResetTimes, selectOldestVehicleEntryTime, selectVehicleCount } from '@/stores/rois';
import { Timed } from 'react-time-sync';
import { parseISO, differenceInSeconds } from 'date-fns';
import { selectDurationFormat } from '@/stores/sites/generalConfigs';
import { Box } from '../components/box';
import { StandardWidgetProps } from '@/types';
import { Card } from '@/widgets/components/card';
import Validation from '@/widgets/leadCarTimerV2/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';
import classNames from 'classnames';
import MetricTextSVG from '../components/text/MetricTextSVG';

export const widgetMetadata: WidgetMetadata = {
  title: 'leadCarTimerV2',
  displayName: 'Lead Car Timer (Version 2)',
  description: 'Placeholder description',
};

export interface RoiLabel {
  roiId: string;
  label: string;
  redThreshold?: number;
}

export interface LeadCarTimerProps extends StandardWidgetProps {
  roiLabels: RoiLabel[];
}

const InlineWrapper = (props: { metric: string; label?: string; inline?: boolean }): JSX.Element => {
  const { metric, label, inline = false } = props;

  return (
    <div
      className={classNames(
        'flex items-center justify-center h-full w-full',
        inline ? 'flex-row-reverse px-2' : 'flex-col',
      )}
    >
      <MetricTextSVG className='tex-9xl font-semibold' preserveAspectRatio={inline ? 'xMaxYMid meet' : 'xMidYMid meet'}>
        {metric}
      </MetricTextSVG>
      {label !== undefined && <span className='text-xl font-semibold min-w-fit'>{label}</span>}
    </div>
  );
};

const LeadCarTimer = (props: LeadCarTimerProps & { children?: JSX.Element }): JSX.Element => {
  const { roiLabels } = props;
  const durationFormat = useAppSelector(selectDurationFormat);
  const roiIds = useMemo(() => roiLabels.map((roiLabel) => roiLabel.roiId), [roiLabels]);
  const roisLeadCarResetTimes: Record<string, string> = useAppSelector(selectLeadCarResetTimes(roiIds));
  const roisOldestVehicle: Record<string, string> = useAppSelector(selectOldestVehicleEntryTime(roiIds));
  const roisVehicleCount: Record<string, number> = useAppSelector(selectVehicleCount(roiIds));

  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <ul role='list' className='grid grid-cols-1 gap-5 h-full'>
        {roiLabels.map((roiLabel: RoiLabel, index: number) => {
          const { label, roiId, redThreshold } = roiLabel;
          const timestamp = roisLeadCarResetTimes[roiId] ?? roisOldestVehicle[roiId] ?? undefined;
          const vehicleCount = roisVehicleCount[roiId] ?? 0;

          return (
            <li key={index}>
              <Validation {...roiLabel}>
                <Timed>
                  {() => {
                    let elapsedTime = 0;
                    if (vehicleCount > 0 && timestamp !== undefined) {
                      const now = new Date();
                      const dateTime = parseISO(timestamp);
                      elapsedTime = differenceInSeconds(now, dateTime);
                    }

                    const displayValue =
                      elapsedTime === 0 ? '-' : formatDurationToMinutesAndSeconds(elapsedTime, durationFormat);

                    const inline = roiLabels.length > 3;

                    return !isEmpty(redThreshold) ? (
                      <Card
                        className={classNames(
                          elapsedTime === 0
                            ? 'bg-indicator-empty'
                            : elapsedTime <= (redThreshold as number)
                              ? 'bg-indicator-okay'
                              : 'bg-indicator-alert',
                        )}
                      >
                        <InlineWrapper metric={displayValue} label={label} inline={inline} />
                      </Card>
                    ) : (
                      <Box>
                        <InlineWrapper metric={displayValue} label={label} inline={inline} />
                      </Box>
                    );
                  }}
                </Timed>
              </Validation>
            </li>
          );
        })}
      </ul>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

export default LeadCarTimer;
