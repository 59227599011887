import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from './index';
import { addSeconds, isBefore } from 'date-fns';

const MAX_NOTIFICATIONS_DISPLAYED = 3;

export interface NotificationsState {
  data: {
    notifications: Notification[];
  };
}

export interface Notification extends NotificationEvent {}

export interface Destination {
  type: DestinationType;
  clientId: string;
  siteId: string;
  dashboardId: string;
}

export enum DestinationType {
  DASHBOARD = 'DASHBOARD',
}

export interface Alert {
  ttl: number;
  title: string;
  description: string;
  level: AlertLevel;
}

export enum AlertLevel {
  INFO = 'INFO',
  WARN = 'WARN',
  ALERT = 'ALERT',
  SUCCESS = 'SUCCESS', // TODO: Add to eyecue-notification-service
}

export interface NotificationEvent {
  id: string;
  timestamp: string;
  version: string;
  destination: Destination;
  alert: Alert;
}

const initialState: NotificationsState = {
  data: {
    notifications: [],
  },
};

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification: (state, { payload }: PayloadAction<NotificationEvent>) => {
      if (payload.alert === undefined) {
        window.logger.warn(`No alert field on notification with id: ${payload.id}. Not adding to notifications store`);
      }

      // When receive a new notification, clean up any that are past their ttl
      const now = new Date();

      const currentNotifications = state.data.notifications.filter((notification) => {
        const notificationDate = new Date(notification.timestamp);
        const expiryDate = addSeconds(notificationDate, notification.alert.ttl);

        return isBefore(now, expiryDate);
      });

      // When receive a new notification, only keep the newest (MAX_NOTIFICATIONS_DISPLAYED - 1).
      // That way when we add the new one, we will have at most MAX_NOTIFICATIONS_DISPLAYED
      const cappedNotifications = currentNotifications.slice(-(MAX_NOTIFICATIONS_DISPLAYED - 1));

      // Then set the new one at the end of the array
      state.data.notifications = [...cappedNotifications, payload];
    },
    removeNotification: (state, { payload }: PayloadAction<string>) => {
      const retainedNotifications = state.data.notifications.filter((notification) => {
        return notification.id !== payload;
      });

      state.data.notifications = retainedNotifications;
    },
    removeExpiredNotifications: (state) => {
      const now = new Date();

      const currentNotifications = state.data.notifications.filter((notifaction) => {
        const notificationDate = new Date(notifaction.timestamp);
        const expiryDate = addSeconds(notificationDate, notifaction.alert.ttl);

        return isBefore(now, expiryDate);
      });

      if (currentNotifications.length < state.data.notifications.length) {
        state.data.notifications = currentNotifications;
      }
    },
  },
});

export const selectNotifications = (state: RootState): Notification[] => state.notifications.data.notifications;

export const { setNotification, removeNotification, removeExpiredNotifications } = notifications.actions;
export default notifications.reducer;
