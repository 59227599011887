import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '.';

export interface CalendarState {
  date: string;
  time: string;
}

const initialState: CalendarState = {
  date: '',
  time: '',
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setDatetime: (state, { payload }: PayloadAction<CalendarState>) => {
      state.date = payload.date;
      state.time = payload.time;
    },
  },
});

export default calendarSlice.reducer;

export const { setDatetime } = calendarSlice.actions;

export const selectDate = (state: RootState): string => state.calendar.date;
export const selectTime = (state: RootState): string => state.calendar.time;
