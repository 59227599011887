import { scaleLinear } from '@visx/scale';
import { area } from '@visx/shape';
import { type DistributionDatum } from './utils';
import React, { useEffect, useMemo, useState } from 'react';
import { type NumberValue } from 'd3';
import { Group } from '@visx/group';
import { curveCardinalOpen } from '@visx/curve';
import { animated, useSpring } from '@react-spring/web';
import { Text } from '@visx/text';

const PULSE_RESET_TIME = 800;
const PULSE_HEIGHT = 0.3;
const margin = 4;

interface CurveProps {
  data: DistributionDatum[];
  width: number;
  height: number;
}

interface Pulse {
  timestamp: Date | null;
  delta: number;
}

const zeroedRange = (data: any[]): number => {
  return data.length * 2;
};

const Curve = (props: CurveProps): JSX.Element => {
  const { data, width, height, ...restProps } = props;
  // Detect when a piece of data has changed, so that we can set a pulse on that index
  const prevData = React.useRef(data);
  const [pulseArray, setPulseArray] = useState(
    data.map((): Pulse => {
      return { delta: 0, timestamp: null };
    }),
  );

  // useEffect hook to detect changes in the data array.
  // This is used to puplated the 'pulseArray', which adds temporary 'pulses' when adjacent data points change.
  useEffect(() => {
    const pulses = data.map((datum, index) => {
      if (
        prevData.current?.[index] !== undefined && // Getting an intermittent error ("Cannot read properties of undefined. Reading 'value'.") Guard agianst undefined checks to all 'value' accessors in CarDistribution
        prevData.current[index].value !== datum.value &&
        datum !== undefined
      ) {
        const delta = datum.value - prevData.current[index].value;
        const timestamp = new Date();
        return { index, delta, timestamp };
      } else {
        return pulseArray[index];
      }
    });
    setPulseArray(pulses);
    prevData.current = data;
    // Check pulse array and reset any that have expired
    setTimeout(() => {
      const now = new Date().getTime();
      const clearedPulses = pulseArray.map((pulse) => {
        if (pulse.timestamp === null) {
          return pulse;
        }
        const pulseAge = now - pulse.timestamp.getTime();
        if (pulseAge > PULSE_RESET_TIME) {
          return { delta: 0, timestamp: null };
        }
        return pulse;
      });
      setPulseArray(clearedPulses);
    }, PULSE_RESET_TIME);
  }, [data]);

  const domainMax = zeroedRange(data);
  const xMax = width;
  const spineHeight = 8;
  const centreHeight = height / 2;

  const { xScale, yScale } = useMemo(() => {
    const xScale = scaleLinear({
      domain: [-margin, 1, domainMax + 1, domainMax + 2, xMax + margin],
      range: [-margin, 1, xMax - 1, xMax, xMax + margin],
    });

    const yScale = scaleLinear({
      domain: [-margin, 0, 3 + margin],
      range: [centreHeight + margin, 0, centreHeight - margin],
    });

    return { xScale, yScale };
  }, [domainMax, xMax, height]);

  const spineThicknessY = yScale.invert(spineHeight / 2);
  const path = useMemo(() => {
    const path = area<NumberValue>({
      x: (_, i) => xScale(i),
      y0: (d) => {
        return d.valueOf() > 0 ? -yScale(d) : -spineThicknessY;
      },
      y1: (d) => {
        return d.valueOf() > 0 ? yScale(d) : spineThicknessY;
      },
      curve: curveCardinalOpen.tension(0.5),
    });
    return path;
  }, [xScale, yScale]);

  // When a value shrinks, temporarily add a 'pulse' to the next data point, to imply flow to the next value.
  // Or when a value grows, temporarily subtract a pulse from the previous data point.
  const pulsedData = useMemo(
    () =>
      data.map((datum, index) => {
        const { value } = datum;
        // Look ahead to see if we need a down pulse because the next point has grown
        const nextPointGrown =
          index + 1 < data.length &&
          pulseArray[index + 1] !== undefined &&
          pulseArray[index + 1].timestamp !== null &&
          pulseArray[index + 1].delta > 0;
        // Look back to see if we need an up pulse because the previous point has shrunk
        const previousPointShrunk =
          index - 1 >= 0 &&
          pulseArray[index - 1] !== undefined &&
          pulseArray[index - 1].timestamp !== null &&
          pulseArray[index - 1].delta < 0;
        let direction = 0;
        if (nextPointGrown) direction -= 1;
        if (previousPointShrunk) direction += 1;
        return { ...datum, value: value + direction * PULSE_HEIGHT };
      }),
    [data, pulseArray],
  );

  const interlacedData = [0, spineThicknessY, ...pulsedData.flatMap(({ value }) => [value, spineThicknessY]), 0];

  const animatedValues = useSpring({
    from: { values: interlacedData.map(() => 0) },
    to: { values: interlacedData },
    config: { mass: 5, friction: 20, tension: 100 },
  });

  const animatedPath = useMemo(() => {
    return animatedValues.values.to((...interpolatedValues: NumberValue[]) => path(interpolatedValues) ?? '');
  }, [animatedValues, path]);

  return (
    <svg
      {...restProps}
      viewBox={`0 0 ${width} ${height}`}
      stroke='none'
      overflow='visible'
      className='fill-indicator-neutral'
    >
      {/* Black outline border around the blue area */}
      <Group top={height / 2}>
        <animated.path
          d={animatedPath}
          fill='none'
          strokeWidth={13} // Adjust the width of the black border as needed
          className={'stroke-divider-muted'}
        />
      </Group>
      {/* White stroke around the blue area */}
      <Group top={height / 2}>
        <animated.path
          d={animatedPath}
          stroke='white'
          fill='none'
          strokeWidth={12} // Adjust the width of the white stroke as needed
        />
      </Group>
      {/* Original blue area */}
      <Group top={height / 2}>
        {data.map(({ label }, index) => (
          <g key={index}>
            <Text
              x={margin + xScale(2) + xScale(index + 1) * 2}
              y={yScale(6)}
              dx={-10}
              dy={5}
              textAnchor='middle'
              fontSize='25'
              className='fill-emphasis-muted font-normal roboto capitalize'
            >
              {label}
            </Text>
          </g>
        ))}
        <animated.path d={animatedPath} className='fill-indicator-neutral stroke-indicator-neutral' strokeWidth={3} />
      </Group>
    </svg>
  );
};
export default Curve;
