import { useCallback, useMemo } from 'react';
import { Line } from '@visx/shape';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Group } from '@visx/group';
import { AxisLeft, AxisBottom } from '@visx/axis';
import {
  type TimeSeriesBarChartModel,
  type TimeSeriesBarChartRenderModel,
  type AggregationMethod,
  getTimeSeries,
  getAggregation,
} from './utils';
import { Text } from '@visx/text';
import TimeSeriesBar from './TimeSeriesBar';
import TargetLine from './TargetLine';

export enum ChartSize {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export interface TimeSeriesBarChartProps {
  width: number;
  height: number;
  data: TimeSeriesBarChartModel[];
  chartSize: ChartSize;
  aggregationMethod: AggregationMethod;
  invert?: boolean; // default higher value is better
  showTicks?: boolean; // for debug/storybook
  aggregationMeasureUnit?: string;
  hideTargetLine?: boolean;
}

interface Point {
  x: number;
  y: number;
}

const HourBlockDivider = (props: { from: Point; to: Point; left: number; hour: string }): JSX.Element => {
  const { from, to, left, hour } = props;

  return (
    <Group left={left}>
      <Line from={from} to={to} strokeWidth={1} shapeRendering='geometricPrecision' className='stroke-divider-muted' />
      <Text x={0} y={0} verticalAnchor='start' textAnchor='start' className='fill-emphasis-muted font-medium text-sm'>
        {hour}
      </Text>
    </Group>
  );
};

const TimeSeriesBarChart = (props: TimeSeriesBarChartProps): JSX.Element => {
  const {
    width,
    height,
    data,
    chartSize,
    invert = false,
    showTicks = false,
    aggregationMeasureUnit,
    hideTargetLine,
  } = props;

  // bounds
  const verticalMargin = 50;
  const xMax = width;
  const yMax = height - verticalMargin;

  const renderData: TimeSeriesBarChartRenderModel[] = useMemo(() => getTimeSeries(data), [data]);

  const aggregatedData: Record<string, number> = useMemo(
    () => getAggregation(renderData, props.aggregationMethod),
    [renderData, props.aggregationMethod],
  );

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        round: true,
        domain: [renderData[0].date, renderData[renderData.length - 1].date],
      }),
    [xMax, renderData[0].date, renderData[renderData.length - 1].date],
  );

  const yMaxValue = Math.max(...renderData.map((d) => d.target ?? 0), ...renderData.map((d) => d.value));
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, 1.2 * yMaxValue], // Multiply by 1.2 to make sure the chart is not too close to the top
      }),
    [yMax, yMaxValue],
  );

  const { barStep, barWidth } = useMemo(() => {
    const barStep = xMax / data.length;
    const barWidth = barStep * (chartSize === ChartSize.LARGE ? 0.6 : 0.5);
    const barGap = barStep - barWidth;

    return { barStep, barWidth, barGap };
  }, [xMax, data.length, chartSize]);

  const lineXGetter = useCallback((d: TimeSeriesBarChartRenderModel) => xScale(d.date) ?? 0, [xScale]);

  const lineYGetter = useCallback((d: TimeSeriesBarChartRenderModel) => yScale(d.target ?? 0) ?? 0, [yScale]);

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <Group>
        {showTicks && (
          <>
            <AxisBottom top={yMax} scale={xScale} numTicks={width > 520 ? renderData.length : 5} />
            <AxisLeft left={1} scale={yScale} />
          </>
        )}

        {/* Target line */}
        <TargetLine data={renderData} x={lineXGetter} y={lineYGetter} hide={hideTargetLine} />

        {/* Metric bars */}
        {renderData.map((d, index) => {
          // Skip the first and last index as they're placeholder to render hour block divider
          if (index === 0 || index === renderData.length - 1) {
            return null;
          }

          const doesMetricMeetTarget =
            d.target === undefined ? true : invert ? d.value <= d.target : d.value >= d.target;
          const indicator = d.isCurrentData
            ? 'fill-indicator-empty'
            : doesMetricMeetTarget
              ? 'fill-indicator-okay'
              : 'fill-indicator-alert';
          const barHeight = d.value === 0 ? 0 : yMax - (yScale(d.value) ?? 0);
          const barX = xScale(d.date) ?? 0;
          const barY = yMax - barHeight;
          const hourBlockDividerX = barX - barStep / 2 <= 0 ? 1 : barX - barStep / 2;

          return (
            <g key={`bar-${index}`}>
              {/* Bar */}
              <TimeSeriesBar
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                className={indicator}
                label={barHeight > 0 && chartSize === ChartSize.LARGE ? d.value : undefined}
              />

              {/* Hour block divider */}
              {d.minute === 0 && (
                <HourBlockDivider
                  from={{ x: 0, y: yMax + 50 }}
                  to={{ x: 0, y: 20 }}
                  left={hourBlockDividerX}
                  hour={`${d.hour}`}
                />
              )}

              {/* Aggregation */}
              {d.minute === 15 && aggregatedData[d.hour] !== undefined && (
                <Text
                  x={barX + barStep / 2}
                  y={yMax}
                  dy={20}
                  verticalAnchor='start'
                  textAnchor='middle'
                  className='fill-emphasis-base font-semibold text-lg'
                >
                  {`${aggregatedData[d.hour]}${aggregationMeasureUnit ?? ''}`}
                </Text>
              )}
            </g>
          );
        })}
      </Group>
    </svg>
  );
};

export default TimeSeriesBarChart;
