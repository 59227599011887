import { useContext, useMemo } from 'react';
import { WidgetContentContext } from '../wrapper/widgetContentContext';
import WidgetMetricWrapper, { StandardMetricWrapperProps } from '../wrapper/WidgetMetricWrapper';

interface BubbleProps extends Omit<StandardMetricWrapperProps, 'children'> {
  primaryTextClassName?: string;
  strokeScaling?: number;
  circleClass?: string;
  textWidthPercentage?: string;
}

export const Bubble = ({
  strokeScaling = 1,
  circleClass = 'stroke-indicator-neutral',
  primaryTextClassName = 'fill-indicator-neutral font-semibold',
  textHeightPercentage = '40%',
  textWidthPercentage = '60%',
  ...props
}: BubbleProps) => {
  const widgetContentContextValue = useContext(WidgetContentContext);

  const bubbleSize = useMemo(
    () => ({
      width:
        widgetContentContextValue.size.width -
        widgetContentContextValue.padding.left -
        widgetContentContextValue.padding.right,
      height:
        widgetContentContextValue.size.height -
        widgetContentContextValue.padding.top -
        widgetContentContextValue.padding.bottom,
    }),
    [
      widgetContentContextValue.size.width,
      widgetContentContextValue.size.height,
      widgetContentContextValue.padding.left,
      widgetContentContextValue.padding.right,
      widgetContentContextValue.padding.top,
      widgetContentContextValue.padding.bottom,
    ],
  );

  const { cx, cy, radius, viewBox, strokeWidth } = useMemo(() => {
    const cx = bubbleSize.width / 2;
    const cy = bubbleSize.height / 2;
    const bubbleDiameter = Math.min(bubbleSize.width, bubbleSize.height);
    const radius = bubbleDiameter * 0.5 * 0.98;
    const strokeWidth = radius * 0.02 * strokeScaling;
    const viewBox = `0 0 ${bubbleSize.width} ${bubbleSize.height}`;

    return { cx, cy, radius, viewBox, strokeWidth };
  }, [bubbleSize.width, bubbleSize.height]);

  return (
    <WidgetMetricWrapper
      primaryTextClassName={primaryTextClassName}
      secondaryTextClassName='text-emphasis-overlay font-semibold'
      {...props}
      textHeightPercentage={textHeightPercentage}
      textWidthPercentage={textWidthPercentage}
    >
      <svg width='100%' height='100%' viewBox={viewBox}>
        <circle className={circleClass} cx={cx} cy={cy} r={radius} strokeWidth={strokeWidth} fill='none' />
      </svg>
    </WidgetMetricWrapper>
  );
};

export const EmptyBubble = (props: Omit<BubbleProps, 'metricValue'>) => {
  return (
    <Bubble
      strokeScaling={0.5}
      circleClass='stroke-indicator-empty'
      primaryTextClassName='fill-indicator-empty font-normal roboto'
      textWidthPercentage='40%'
      {...props}
      metricValue={0}
    />
  );
};
