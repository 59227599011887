import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Spinner from './spinner';
import ComingSoon from './comingSoon';

const ErrorFallback = ({ error }: any): JSX.Element => {
  return (
    <div>
      <p>Something went wrong:</p>
      <pre className='text-error'>{error.message}</pre>
    </div>
  );
};

interface LazyModuleProps {
  component: string;
  moduleProps: any;
  children?: JSX.Element | JSX.Element[];
}

const LazyModule = (props: LazyModuleProps): JSX.Element => {
  const { component, children, moduleProps } = props;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Spinner />}>
        {React.createElement(
          React.lazy(
            async () =>
              await import(`./${component}`).catch((error) => {
                return {
                  default: () => {
                    if (error.message === `Cannot find module './${component}'`) {
                      window.logger.warn(error.message);
                      return <ComingSoon {...moduleProps} />;
                    } else {
                      window.logger.error(error.message);
                      throw error;
                    }
                  },
                };
              }),
          ),
          moduleProps,
          children,
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default LazyModule;
