import { LinePath } from '@visx/shape';
import { slopedStep } from './slopedStep';
import { type TimeSeriesBarChartRenderModel } from './utils';

interface TargetLineProps {
  data: TimeSeriesBarChartRenderModel[];
  x: (d: TimeSeriesBarChartRenderModel) => number;
  y: (d: TimeSeriesBarChartRenderModel) => number;
  hide?: boolean;
}

const TargetLine = (props: TargetLineProps): JSX.Element => {
  const { data, x, y, hide = false } = props;

  return hide ? (
    <></>
  ) : (
    <LinePath
      curve={slopedStep}
      data={data}
      x={x}
      y={y}
      strokeWidth={2}
      shapeRendering='geometricPrecision'
      className='stroke-divider-base'
    />
  );
};

export default TargetLine;
