import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { postHeartbeatMetrics } from './HeartbeatContainer';

const Heartbeat = (): JSX.Element => {
  const location = useLocation();
  const { siteId, serialNumber } = useParams();
  useEffect(() => {
    if (location.pathname.includes('/kiosk')) {
      const intervalId = setInterval(
        () => {
          postHeartbeatMetrics(serialNumber, siteId).catch((error) => {
            console.error('Error sending heartbeat', error);
          });
        },
        60 * 10 * 1000,
      ); // 10 minutes interval

      return () => clearInterval(intervalId); // Cleanup function to clear the interval when the component unmounts
    }
  }, [location.pathname, serialNumber, siteId]);
  return <></>;
};

export default Heartbeat;
