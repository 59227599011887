import { Bar } from '@visx/shape';
import { Text } from '@visx/text';
import { type ScaleLinear } from 'd3';

export interface Legend {
  isAboveDivider: boolean;
  label: string;
}

interface TimelineLegendProps {
  width: number;
  height: number;
  xStart: number;
  yScale: ScaleLinear<number, number, never>;
  legends: Legend[];
}

const TimelineLegend = (props: TimelineLegendProps): JSX.Element => {
  const { xStart, width, yScale, legends } = props;

  const barWidth = (width / 4) * 3;
  const barHeight = 20;

  return (
    <>
      {legends.map((legend, index) => {
        const { label, isAboveDivider } = legend;

        const x = xStart + width / 2;
        const y = isAboveDivider ? (1 / 6) * 3 : (1 / 6) * 1;

        return (
          <g key={`${label}-timeline-legend-bar-${index}`}>
            <Bar
              x={x - barWidth / 2}
              y={yScale(y) - barHeight / 2}
              width={barWidth}
              height={barHeight}
              className='fill-legend-background'
              rx={8}
            />
            <Text
              x={xStart + width / 2}
              y={yScale(y)}
              textAnchor='middle'
              dominantBaseline='middle'
              className='fill-emphasis-overlay'
            >
              {label}
            </Text>
          </g>
        );
      })}
    </>
  );
};

export default TimelineLegend;
