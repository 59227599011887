import { Bar } from '@visx/shape';
import classNames from 'classnames';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import React, { useMemo } from 'react';
import { animated, easings, useSpring } from '@react-spring/web';

const AnimatedBar = animated(Bar);
const AnimatedText = animated(Text);
const AnimatedForeignObject = animated('foreignObject');
const animationConfig = { duration: 1500, easing: easings.easeOutCirc };

// There're edge cases when width is to small, so we need to set a minimum width
const MIN_WIDTH = 200;

interface RankingBarProps {
  siteName: string;
  value: number;
  rank: number;
  x: number;
  y: number;
  width: number;
  height: number;
  highlight: boolean;
  group: number;
}

const RankingBar = (props: RankingBarProps): JSX.Element => {
  const { siteName, value, rank, x, y, width, height, highlight, group } = props;

  const calculatedWidth = useMemo(() => (width < 0 ? 0 : width < MIN_WIDTH ? MIN_WIDTH : width), [width]);

  const animationBarProps = useSpring({
    from: { width: 0 },
    to: { width: calculatedWidth },
    config: animationConfig,
  });

  const animationTextProps = useSpring({
    from: { x },
    to: { x: x + calculatedWidth },
    config: animationConfig,
  });

  const animationForeignObjectProps = useSpring({
    from: { width: 0 },
    to: { width: (calculatedWidth * 2) / 3 },
    config: animationConfig,
  });

  return (
    <>
      <AnimatedBar
        x={x}
        y={y}
        width={animationBarProps.width}
        height={height}
        rx={10}
        className={classNames(highlight ? 'fill-indicator-muted' : 'fill-indicator-neutral', {
          'opacity-20': group === 1 && !highlight,
        })}
      />
      <Group
        className={classNames(
          highlight ? 'fill-emphasis-inverted' : group === 0 ? 'fill-emphasis-overlay' : 'fill-emphasis-neutral',
        )}
      >
        <Text
          x={x}
          y={y + height / 2}
          dx='2em'
          verticalAnchor='middle'
          textAnchor='middle'
          className={classNames('font-bold', group === 0 ? 'text-3xl' : 'text-xl')}
        >
          {rank}
        </Text>
        <AnimatedForeignObject x={x} y={y} width={animationForeignObjectProps.width} height={height}>
          <div className='flex h-full'>
            <span
              className={classNames(
                'mt-auto mb-auto pl-16 font-medium text-xl truncate',
                highlight ? 'text-emphasis-inverted' : group === 0 ? 'text-emphasis-overlay' : 'text-emphasis-neutral',
              )}
            >
              {siteName}
            </span>
          </div>
        </AnimatedForeignObject>
        <AnimatedText
          x={animationTextProps.x}
          y={y + height / 2}
          dx='-1.5em'
          verticalAnchor='middle'
          textAnchor='end'
          className='font-bold text-[25px]'
        >
          {Math.floor(value)}
        </AnimatedText>
      </Group>
    </>
  );
};

export default RankingBar;
