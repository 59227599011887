interface CurrentCountAxisProps {
  counts: number[];
}

export const CurrentCountAxis = (props: CurrentCountAxisProps): JSX.Element => {
  const { counts } = props;

  const labels = [];
  // The first point will always have a value and time of 0. Don't need a label for that, so start at 1.
  for (let i = 1; i < 5; i++) {
    let label = '-';
    if (i < counts.length) {
      const count = counts[i];
      if (count !== undefined && count > 0) {
        label = `${count}`;
      }
    }

    labels.push({ label });
  }

  return (
    <div className='h-full grid grid-cols-4'>
      {labels.map((label, i) => (
        <div key={i} className='text-xl bg-gray-000 flex items-center justify-center text-indicator-neutral'>
          <span className='font-semibold roboto-fm-v2'>{label.label}</span>
        </div>
      ))}
    </div>
  );
};
