import { scaleLinear } from '@visx/scale';

interface SegmentDividerProps {
  width: number;
  height: number;
}

export const SegmentDividers = (props: SegmentDividerProps): JSX.Element => {
  const { width, height } = props;

  const xScale = scaleLinear({
    range: [0, width],
    domain: [0, 4],
  });

  const lines = Array.from(Array(4).keys());
  const linePositions = lines.map((line) => xScale(line)).slice(1);

  return (
    <g>
      {linePositions.map((linePosition, i) => {
        return (
          <line
            key={i}
            x1={linePosition}
            y1={0}
            x2={linePosition}
            y2={height}
            className='stroke-dashboard-background'
            strokeDasharray='2,2'
          />
        );
      })}
    </g>
  );
};
