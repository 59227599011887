import { EmptyBubble } from '../components/bubble';
import { WidgetWrapper } from '../components/wrapper';

interface ComingSoonProps {}

const ComingSoon = (props: ComingSoonProps): JSX.Element => {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <EmptyBubble placeholder={`coming soon`} />
    </WidgetWrapper>
  );
};

export default ComingSoon;
