import { type DailyRollingTarget } from '@/types';
import reduce from 'lodash/reduce';
import { type SiteConfig } from './types';
import { isEmpty } from '@/common';

const toTargetRates = (targetCounts: Record<string, number>): Record<string, number> => {
  return reduce(
    targetCounts,
    (result, value, key) => {
      if (value === 0) {
        result[key] = 0;
      } else {
        result[key] = Math.round(900 / value);
      }

      return result;
    },
    {} as Record<string, number>,
  );
};

export const processTargetInterval = (
  dailyTargetCounts: DailyRollingTarget,
): { targetCounts: DailyRollingTarget; targetRates: DailyRollingTarget } => {
  return reduce(
    dailyTargetCounts,
    (result, value, key) => {
      (result['targetRates'] ?? (result['targetRates'] = {}))[key] = toTargetRates(value);
      return result;
    },
    { targetCounts: { ...dailyTargetCounts } as DailyRollingTarget, targetRates: {} as DailyRollingTarget },
  );
};

export const processSiteData = (siteData: any): SiteConfig | undefined => {
  if (isEmpty(siteData)) {
    window.logger.error(`No site config found.`);
    return undefined;
  }

  return {
    name: siteData.storeName,
    siteId: siteData.siteId,
    timezone: siteData.timezone,
    targetIntervals: processTargetInterval(siteData.targetIntervals?.targetCounts ?? {}),
    targetTimes: siteData.targetTimes,
  };
};
