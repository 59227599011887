import classNames from 'classnames';
import WidgetMetricWrapper from '../wrapper/WidgetMetricWrapper';
import React from 'react';
import { useCardPaddingEffect } from '../hook';

interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
}

export const Box = (props: BoxProps): JSX.Element => {
  const { children, className, ...rest } = props;
  const divRef = useCardPaddingEffect();

  return (
    <WidgetMetricWrapper
      component={
        <div className={classNames('rounded-[14px] h-full border-indicator-neutral border-2', className)} {...rest} />
      }
    >
      <div
        ref={divRef}
        className='flex flex-col items-center justify-center h-full w-full z-10 text-emphasis-neutral fill-emphasis-neutral'
      >
        {children}
      </div>
    </WidgetMetricWrapper>
  );
};

export const EmptyBox = (props: BoxProps): JSX.Element => {
  const { children } = props;

  return (
    <WidgetMetricWrapper component={<div className='rounded-[14px] h-full w-full border-indicator-empty border-2' />}>
      <div className='flex h-full w-full items-center justify-center z-10'>{children}</div>
    </WidgetMetricWrapper>
  );
};

export const NotConfiguredBox = (): JSX.Element => (
  <EmptyBox>
    <span className='text-5xl text-divider-muted font-normal'>Not configured</span>
  </EmptyBox>
);
