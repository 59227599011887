// https://www.fullstacklabs.co/blog/creating-an-svg-gauge-component-from-scratch

import { useMemo } from 'react';
import { type Size } from '@/types';
import { useSpring, animated, easings } from '@react-spring/web';

const Circle = (props: any): JSX.Element => {
  const { animation = false, circumference, strokeDasharray, ...circleProps } = props;

  const animationProps = useSpring({
    from: { strokeDasharray: `0 ${circumference}` },
    to: { strokeDasharray },
    config: { duration: 2000, easing: easings.easeOutBack },
  });

  return (
    <animated.circle
      fill='transparent'
      strokeLinecap='round'
      shapeRendering='geometricPrecision'
      strokeDasharray={strokeDasharray}
      style={(animation as boolean) ? animationProps : {}}
      {...circleProps}
    />
  );
};

interface DonutProps {
  metricValueFraction: number;
  targetValueFraction: number;
  radius: number;
  targetClassName: string;
  metricClassName: string;
  valueStrokeWidth: number;
  baseLineStrokeWidth: number;
  size: Size;
}

const Donut = (props: DonutProps): JSX.Element => {
  const {
    metricValueFraction,
    targetValueFraction,
    radius,
    targetClassName,
    metricClassName,
    valueStrokeWidth,
    baseLineStrokeWidth,
    size,
  } = props;

  const innerRadius = radius - valueStrokeWidth / 2;
  const circumference = innerRadius * 2 * Math.PI;
  const metricValueTransform = `rotate(-90, ${size.width / 2}, ${size.height / 2})`;
  const targetValueTransform = `rotate(${-90 + targetValueFraction * 360}, ${size.width / 2}, ${size.height / 2})`;
  const targetValueDashArray = `0 ${circumference}`;

  const metricValueDashArray = useMemo(() => {
    const metricValueArc = circumference * metricValueFraction;
    return `${metricValueArc} ${circumference}`;
  }, [circumference, metricValueFraction]);

  const animation = useMemo(() => metricValueFraction > 0, [metricValueFraction]);

  return (
    <>
      {/* Base line */}
      <Circle cx='50%' cy='50%' r={innerRadius} strokeWidth={baseLineStrokeWidth} className='stroke-metric-baseline' />

      {/* Current metric */}
      <Circle
        circumference={circumference}
        cx='50%'
        cy='50%'
        r={innerRadius}
        strokeDasharray={metricValueDashArray}
        strokeWidth={valueStrokeWidth}
        transform={metricValueTransform}
        className={metricClassName}
        animation={animation}
      />

      {/* Target */}
      <Circle
        cx='50%'
        cy='50%'
        r={innerRadius}
        strokeDasharray={targetValueDashArray}
        strokeWidth={valueStrokeWidth}
        transform={targetValueTransform}
        className={targetClassName}
      />
    </>
  );
};

export interface GaugeSVGProps {
  size: Size;
  maxValue: number;
  metricValue: number;
  targetValue: number;
  isActive?: boolean;
  invert?: boolean; // default higher value is better
}

export const useGaugeSVGColors = (props: {
  metricValue: number;
  targetValue: number;
  invert?: boolean;
}): { targetClassName: string; metricClassName: string } => {
  const { metricValue, targetValue, invert = false } = props;

  return useMemo(() => {
    const targetClassName =
      metricValue >= targetValue ? 'stroke-white' : invert ? 'stroke-indicator-alert' : 'stroke-indicator-okay';
    const doesMetricMeetTarget = invert ? metricValue <= targetValue : metricValue >= targetValue;
    const metricClassName = doesMetricMeetTarget ? 'stroke-indicator-okay' : 'stroke-indicator-alert';

    return { targetClassName, metricClassName };
  }, [metricValue, targetValue, invert]);
};

export const GaugeSVG = (props: GaugeSVGProps): JSX.Element => {
  const { maxValue, metricValue, targetValue, size, isActive = true } = props;

  const greyscalePercent = useMemo(() => {
    return isActive ? { greyscale: 0, opacity: 100 } : { greyscale: 100, opacity: 50 };
  }, [isActive]);

  if (maxValue < 0 || metricValue < 0 || targetValue < 0 || maxValue < targetValue) {
    window.logger.error(
      'Invalid gauge values: maxValue <= 0 || metricValue < 0 || targetValue < 0 || maxValue < targetValue',
    );
  }

  const animationProps = useSpring({
    from: { filter: `grayscale(0%) opacity(100%)` },
    to: { filter: `grayscale(${greyscalePercent.greyscale}%) opacity(${greyscalePercent.opacity}%)` },
    config: { duration: 2000, easing: easings.easeOutBack },
  });

  const metricValueFraction = useMemo(() => (maxValue > 0 ? metricValue / maxValue : 0), [metricValue, maxValue]);

  const targetValueFraction = useMemo(() => (maxValue > 0 ? targetValue / maxValue : 0), [targetValue, maxValue]);

  const { radius, viewBox, valueStrokeWidth, baseLineStrokeWidth } = useMemo(() => {
    const gaugeDiameter = Math.min(size.width, size.height);
    const radius = gaugeDiameter / 2;
    const valueStrokeWidth = radius * 0.15;
    const baseLineStrokeWidth = radius * 0.015;
    const viewBox = `0 0 ${size.width} ${size.height}`;

    return { radius, viewBox, valueStrokeWidth, baseLineStrokeWidth };
  }, [size.width, size.height]);

  const { targetClassName, metricClassName } = useGaugeSVGColors(props);

  return (
    <animated.svg width='100%' height='100%' viewBox={viewBox} style={animationProps}>
      <Donut
        metricValueFraction={metricValueFraction}
        targetValueFraction={targetValueFraction}
        radius={radius}
        targetClassName={targetClassName}
        metricClassName={metricClassName}
        valueStrokeWidth={valueStrokeWidth}
        baseLineStrokeWidth={baseLineStrokeWidth}
        size={size}
      />
    </animated.svg>
  );
};
