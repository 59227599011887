import { WidgetWrapper } from '../components/wrapper';
import { useAppSelector, formatDurationToMinutesAndSeconds, getThisMomentTimeZone } from '@/common';
import { Timed } from 'react-time-sync';
import { parseISO, differenceInSeconds } from 'date-fns';
import { selectClientTargets, selectDurationFormat } from '@/stores/sites/generalConfigs';
import { StandardWidgetProps } from '@/types';
import { Gauge } from '../components/gauge';
import { useTimeAtWindows } from '../commonData';
import eventBus, { CustomEventType } from '@/services/eventBus';
import { selectSiteTargets } from '@/stores/sites/targetConfigs';
import { selectSiteTimeZone, selectTargetTimes } from '@/stores/siteConfigs';
import { getThisMomentTarget } from '@/stores/sites/utils';
import { type TargetType } from '@/stores/sites/types';
import Validation from '@/widgets/timeAtWindowGauge/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'timeAtWindowGauge',
  displayName: 'Time At Window Gauge',
  description: 'Placeholder description',
};

interface AudioOption {
  src: string;
  delayInSecond?: number;
}

export interface TimeAtWindowGaugeProps extends StandardWidgetProps {
  roiId: string;
  targetId: string;
  targetType: TargetType;
  audio?: {
    arrival: AudioOption;
  };
}

const TimeAtWindowGauge = (props: TimeAtWindowGaugeProps): JSX.Element => {
  const { roiId, targetId, audio, targetType } = props;
  const durationFormat = useAppSelector(selectDurationFormat);
  const timeAtWindow = useTimeAtWindows([{ roiId, targetId, targetType }]);
  const targetTimes: Record<string, number> | undefined = useAppSelector(selectTargetTimes);
  const siteTarget = useAppSelector(selectSiteTargets);
  const clientTargets = useAppSelector(selectClientTargets);
  const timezone = useAppSelector(selectSiteTimeZone);

  return (
    <Timed>
      {() => {
        const { timestamp, vehicleCount, targetId, targetType } = timeAtWindow[0];
        let elapsedTime = 0;
        if (vehicleCount > 0 && timestamp !== undefined) {
          const now = new Date();
          const dateTime = parseISO(timestamp);
          elapsedTime = differenceInSeconds(now, dateTime);

          // Play arrival sound if it's configured and the vehicle has arrived for <delayInSecond> seconds
          if (audio?.arrival !== undefined && (elapsedTime === audio?.arrival.delayInSecond ?? 0)) {
            eventBus.emit(
              new CustomEvent(CustomEventType.AudioEvent, {
                detail: { src: `${process.env.REACT_APP_EYECUE_DASHBOARD}/audio/${audio.arrival.src}` },
              }),
            );
          }
        }

        // Get target value
        const thisMoment = getThisMomentTimeZone(timezone);
        // Use fallback approach to get target
        // TODO: The 'targetTimes' can be removed after migrating all site to the new 'store' table
        const target =
          (siteTarget !== undefined
            ? getThisMomentTarget(thisMoment, targetType, siteTarget.targets, clientTargets)
            : targetTimes?.[targetId]) ?? 25;

        return (
          <Gauge
            maxValue={target * 2}
            metricValue={elapsedTime}
            displayValue={formatDurationToMinutesAndSeconds(elapsedTime, durationFormat)}
            targetValue={target}
            secondaryText={formatDurationToMinutesAndSeconds(target, durationFormat)}
            invert
          />
        );
      }}
    </Timed>
  );
};

export default function (props: TimeAtWindowGaugeProps & { children?: JSX.Element }): JSX.Element {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Validation {...props}>
        <TimeAtWindowGauge {...props} />
      </Validation>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
}
