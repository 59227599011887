import type { DepartureCountProps } from '@/widgets/departureCount';
import { useEffect, useState } from 'react';
import { isEmpty } from '@/common';
import { NotConfiguredBubble } from '@/widgets/components/bubble';

const Validation = (props: DepartureCountProps & { children: JSX.Element }) => {
  const { roiType, children } = props;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(isEmpty(roiType));
  }, [roiType]);

  return hasError ? <NotConfiguredBubble /> : children;
};

export default Validation;
