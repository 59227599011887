import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DialogWrapper, { ModalHeader, ModalTitle, ModalContent, CloseButton } from '../components/dialogWrapper';
import LazyModule from '@/widgets/Lazy';
import { isEmpty, useAppSelector } from '@/common';
import { selectWidgetConfig } from '@/stores/sites/dashboardConfigs';

const WidgetConfiguration = (): JSX.Element => {
  const navigate = useNavigate();
  const { siteId, dashboardId, templateId } = useParams();
  const [searchParams] = useSearchParams();
  const widget = searchParams.get('widget') ?? '';
  const slot = searchParams.get('slot') ?? '';
  const config = useAppSelector(selectWidgetConfig(widget, slot));

  return (
    <DialogWrapper open={true}>
      <ModalHeader>
        <ModalTitle>Configure widget</ModalTitle>
        <div className='flex flex-1 justify-end'>
          <CloseButton
            onClick={() => {
              navigate('..', { replace: true });
            }}
          />
        </div>
      </ModalHeader>
      <ModalContent>
        {isEmpty(widget) || isEmpty(slot) ? (
          <>Widget or slot is missing in the URL query parameters.</>
        ) : (
          <LazyModule
            component={`${widget}/configuration`}
            moduleProps={{ ...config, widgetIdentifier: { siteId, dashboardId, slot, name: widget, templateId } }}
          />
        )}
      </ModalContent>
    </DialogWrapper>
  );
};

export default WidgetConfiguration;
