import classNames from 'classnames';
import WidgetMetricWrapper from '../wrapper/WidgetMetricWrapper';
import React from 'react';
import { useCardPaddingEffect } from '../hook';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
}

export const Card = (props: CardProps): JSX.Element => {
  const { children, className, ...rest } = props;
  const divRef = useCardPaddingEffect();

  return (
    <WidgetMetricWrapper
      component={<div className={classNames('h-full w-full rounded-[14px]', className)} {...rest} />}
    >
      <div
        ref={divRef}
        className='flex flex-col items-center justify-center h-full w-full z-10 text-emphasis-overlay fill-emphasis-overlay'
      >
        {children}
      </div>
    </WidgetMetricWrapper>
  );
};
