import React, { useEffect, useState } from 'react';
import { isEmpty } from '@/common';
import { type TimeAtWindowGaugeProps } from '@/widgets/timeAtWindowGauge';
import { NotConfiguredBubble } from '@/widgets/components/bubble';

const Validation = (props: TimeAtWindowGaugeProps & { children: JSX.Element }) => {
  const { roiId, children } = props;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(isEmpty(roiId));
  }, [roiId]);

  return hasError ? <NotConfiguredBubble /> : children;
};

export default Validation;
