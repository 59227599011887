export default [
  {
    siteId: 'fm-tst-nzl-7565',
    siteName: 'Heathcote, Kshlerin and Huels',
    metric: 100,
  },
  { siteId: 'fm-tst-nzl-8902', siteName: "Dicki-O'Keefe", metric: 92 },
  {
    siteId: 'fm-tst-nzl-3795',
    siteName: 'Gerhold, Hartmann and Schulist',
    metric: 89,
  },
  { siteId: 'fm-tst-nzl-7435', siteName: 'Wisoky-Hamill', metric: 88 },
  { siteId: 'fm-tst-nzl-2367', siteName: 'Lebsack LLC', metric: 86 },
  { siteId: 'fm-tst-nzl-4129', siteName: 'Gulgowski LLC', metric: 86 },
  {
    siteId: 'fm-tst-nzl-0120',
    siteName: 'Dickinson Group',
    metric: 79,
  },
  {
    siteId: 'fm-tst-nzl-6799',
    siteName: 'Schowalter-Walsh',
    metric: 75,
  },
  { siteId: 'fm-tst-nzl-2602', siteName: 'Muller-Ratke', metric: 75 },
  {
    siteId: 'fm-tst-nzl-0407',
    siteName: 'Gerhold-MacGyver',
    metric: 73,
  },
  {
    siteId: 'fm-tst-nzl-4017',
    siteName: 'Bailey and Sons',
    metric: 68,
  },
  { siteId: 'fm-tst-nzl-6650', siteName: 'Krajcik-Bruen', metric: 67 },
  {
    siteId: 'fm-tst-nzl-1795',
    siteName: 'Schumm, Connelly and Mayert',
    metric: 64,
  },
  {
    siteId: 'fm-tst-nzl-9783',
    siteName: 'Becker, Rolfson and Crooks',
    metric: 63,
  },
  {
    siteId: 'fm-tst-nzl-1497',
    siteName: 'Bernhard and Sons',
    metric: 58,
  },
  { siteId: 'fm-tst-nzl-0613', siteName: 'Purdy Inc', metric: 57 },
  { siteId: 'fm-tst-nzl-3054', siteName: 'Thiel-Grimes', metric: 56 },
  {
    siteId: 'fm-tst-nzl-3733',
    siteName: 'Braun, Carroll and Pollich',
    metric: 55,
  },
  { siteId: 'fm-tst-nzl-9763', siteName: 'Franecki LLC', metric: 45 },
  {
    siteId: 'fm-tst-nzl-1289',
    siteName: 'Gleichner-Kilback',
    metric: 44,
  },
  {
    siteId: 'fm-tst-nzl-0001',
    siteName: "O'Hara and Sons",
    metric: 39,
  },
  { siteId: 'fm-tst-nzl-5661', siteName: 'Ferry LLC', metric: 36 },
  { siteId: 'fm-tst-nzl-8003', siteName: 'Keeling Group', metric: 32 },
  { siteId: 'fm-tst-nzl-5111', siteName: 'Dicki-Conroy', metric: 30 },
  {
    siteId: 'fm-tst-nzl-2950',
    siteName: 'Romaguera, Effertz and Stanton',
    metric: 27,
  },
  {
    siteId: 'fm-tst-nzl-7211',
    siteName: 'Boehm Hoeger',
    metric: 21,
  },
  {
    siteId: 'fm-tst-nzl-2923',
    siteName: 'Stracke St',
    metric: 20,
  },
  { siteId: 'fm-tst-nzl-1653', siteName: 'Kessler LLC', metric: 20 },
  {
    siteId: 'fm-tst-nzl-6347',
    siteName: "99 O'Keefe",
    metric: 18,
  },
  { siteId: 'fm-tst-nzl-1547', siteName: 'Frami', metric: 14 },
];
