import { ParentSize } from '@visx/responsive';
import TimelineChart, { type TimelineChartProps } from './TimelineChart';
import React from 'react';

export interface WrappedTimelineChartProps extends Omit<TimelineChartProps, 'width' | 'height'> {}

const WrappedTimelineChart = (props: WrappedTimelineChartProps): JSX.Element => {
  return (
    <ParentSize>
      {({ width, height }) => {
        return <TimelineChart {...props} width={width} height={height} />;
      }}
    </ParentSize>
  );
};

export default WrappedTimelineChart;
export { type TimelineChartProps } from './TimelineChart';
export { type TimelineChartModel } from './utils';
