import axios, { type AxiosInstance } from 'axios';
import { camelcaseKeys } from '../common';

const createHttp = (baseUrl?: string, useAccessToken: boolean = true): AxiosInstance => {
  const http = axios.create({
    baseURL: baseUrl ?? '',
    timeout: 60000,
  });

  // Add request interceptor
  http.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('accessToken');
      if (useAccessToken && accessToken !== null && accessToken !== undefined && accessToken !== '') {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    async (error) => {
      window.logger.error(`createHttp request interceptor error: ${JSON.stringify(error)}`);
      return await Promise.reject(error);
    },
  );

  // Add a response interceptor
  http.interceptors.response.use(
    (response) => {
      if (response.data !== null && response.data !== undefined) {
        response.data = camelcaseKeys(response.data, { deep: true });
      }
      return response;
    },
    async (error) => {
      window.logger.error(`createHttp response interceptor error: ${JSON.stringify(error)}`);
      return await Promise.reject(error);
    },
  );

  return http;
};

export default createHttp;
