import { useMemo } from 'react';

enum BatteryLevel {
  '_100',
  '_80',
  '_60',
  '_40',
  '_20',
  'low',
  'charging',
}

interface BatteryLevelIndicator {
  y: number[];
  fill: string;
  stroke: string;
  text: string;
}

const BATTERY_LEVEL: Record<BatteryLevel, BatteryLevelIndicator> = {
  [BatteryLevel._100]: {
    y: [5.05713, 8.13525, 11.2129, 14.2905, 17.3682],
    fill: '#13CA9E',
    stroke: '#13CA9E',
    text: '100%',
  },
  [BatteryLevel._80]: {
    y: [8.10254, 11.1802, 14.2578, 17.3354],
    fill: '#13CA9E',
    stroke: '#13CA9E',
    text: '80%',
  },
  [BatteryLevel._60]: {
    y: [11.1475, 14.2251, 17.3032],
    fill: '#FF8600',
    stroke: '#FF8600',
    text: '60%',
  },
  [BatteryLevel._40]: {
    y: [17.2695, 14.1919],
    fill: '#FF8600',
    stroke: '#FF8600',
    text: '40%',
  },
  [BatteryLevel._20]: {
    y: [17.2368],
    fill: '#F0028C',
    stroke: '#F0028C',
    text: '20%',
  },
  [BatteryLevel.low]: {
    y: [],
    fill: '#F0028C',
    stroke: '#F0028C',
    text: 'Low',
  },
  [BatteryLevel.charging]: {
    y: [],
    fill: '#13CA9E',
    stroke: '#13CA9E',
    text: 'Charging',
  },
};

const BatteryLow = (): JSX.Element => (
  <svg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='4.84717'
      y='14.1016'
      width='7.5301'
      height='2.06872'
      rx='0.578662'
      transform='rotate(-90 4.84717 14.1016)'
      fill='#F0028C'
    />
    <rect x='0.983887' y='3.36621' width='9.79567' height='17.3761' rx='1.42586' stroke='#F0028C' />
    <path
      d='M3.16211 0.956592C3.16211 0.637006 3.42119 0.37793 3.74077 0.37793H8.02287C8.34246 0.37793 8.60154 0.637006 8.60154 0.956592V1.70884H3.16211V0.956592Z'
      fill='#F0028C'
    />
    <circle cx='5.88153' cy='16.9875' r='1.03436' fill='#F0028C' />
  </svg>
);

const BatteryCharging = (): JSX.Element => (
  <svg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.3993 11.966L5.82426 17.6915C5.59279 18.2068 4.91769 18.0121 4.91769 17.4167V13.4088H3.7893C3.42281 13.4088 3.19134 12.9508 3.36494 12.5614L5.93999 6.83595C6.17145 6.32065 6.84656 6.51532 6.84656 7.11077V11.1186H7.97495C8.33179 11.1186 8.5729 11.5767 8.3993 11.966Z'
      fill='#13CA9E'
    />
    <rect x='0.983887' y='3.3335' width='9.79567' height='17.3761' rx='1.42586' stroke='#13CA9E' />
    <path
      d='M3.16211 0.923877C3.16211 0.604291 3.42119 0.345215 3.74077 0.345215H8.02287C8.34246 0.345215 8.60154 0.604291 8.60154 0.923877V1.67613H3.16211V0.923877Z'
      fill='#13CA9E'
    />
  </svg>
);

const BatteryIndicator = (props: { battery?: number; charging: boolean }): JSX.Element => {
  const { battery, charging } = props;

  const level = useMemo(() => {
    if (battery === undefined) {
      return undefined;
    }

    if (charging) {
      return BatteryLevel.charging;
    }

    if (battery >= 100) {
      return BatteryLevel._100;
    } else if (battery >= 80) {
      return BatteryLevel._80;
    } else if (battery >= 60) {
      return BatteryLevel._60;
    } else if (battery >= 40) {
      return BatteryLevel._40;
    } else if (battery >= 20) {
      return BatteryLevel._20;
    } else {
      return BatteryLevel.low;
    }
  }, [battery]);

  if (level === undefined) {
    return <></>;
  }

  const batterySvgProps = BATTERY_LEVEL[level];

  return (
    <div className='flex items-center justify-between'>
      <span className='pr-2 text-emphasis-loud text-base font-normal'>{batterySvgProps.text}</span>
      {charging ? (
        <BatteryCharging />
      ) : level === BatteryLevel.low ? (
        <BatteryLow />
      ) : (
        <svg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect
            x='0.983887'
            y='3.53027'
            width='9.79567'
            height='17.3761'
            rx='1.42586'
            stroke={batterySvgProps.stroke}
          />
          {batterySvgProps.y.map((y, index) => (
            <rect
              key={index}
              x='2.5835'
              y={y}
              width='6.59675'
              height='2.02532'
              rx='0.347197'
              fill={batterySvgProps.fill}
            />
          ))}
          <path
            d='M3.16211 1.12065C3.16211 0.801068 3.42119 0.541992 3.74077 0.541992H8.02287C8.34246 0.541992 8.60154 0.801068 8.60154 1.12065V1.87291H3.16211V1.12065Z'
            fill={batterySvgProps.fill}
          />
        </svg>
      )}
    </div>
  );
};

export default BatteryIndicator;
