import { useAppSelector } from '@/common';

import { useMemo } from 'react';
import { WidgetWrapper } from '../components/wrapper';
import { selectFilteredFunctionalZones } from '@/stores/queues';
import WrappedDistributionChart from '../components/charts/distributionChart';
import Spinner from '../spinner';
import { StandardWidgetProps } from '@/types';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'carDistribution',
  displayName: 'Car Distribution',
  description: 'Placeholder description',
};

const ORDERED_ZONES_TO_DISPLAY = ['ENTRY', 'ORDER', 'DELIVER', 'EXIT'];

export interface CarDistributionChartProps extends StandardWidgetProps {}

const CarDistributionChart = (props: CarDistributionChartProps & { children?: JSX.Element }): JSX.Element => {
  const allFunctionalZones = useAppSelector(selectFilteredFunctionalZones(ORDERED_ZONES_TO_DISPLAY));

  // Transform the functional zones into a list that is filtered and ordered according to the zones we want to display
  const orderedZones = useMemo(() => {
    const entries = Object.entries(allFunctionalZones)
      .map(([key, value]) => ({ label: key, value: value.queueLevel }))
      .filter((item) => ORDERED_ZONES_TO_DISPLAY.includes(String(item.label).toUpperCase()))
      .sort(
        (a, b) =>
          ORDERED_ZONES_TO_DISPLAY.indexOf(String(a.label).toUpperCase()) -
          ORDERED_ZONES_TO_DISPLAY.indexOf(String(b.label).toUpperCase()),
      );

    return entries;
  }, [allFunctionalZones]);

  return (
    <WidgetWrapper {...props}>
      {orderedZones.length > 0 ? <WrappedDistributionChart data={orderedZones} /> : <Spinner />}
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

export default CarDistributionChart;
