export interface AudioPlayer {
  play: (source: string) => void;
  stop: () => void;
}

const createAudioPlayer = (): AudioPlayer => {
  const audio = new Audio();

  const play = (source: string): void => {
    audio.src = source;
    audio.load();
    void audio.play();
  };

  const stop = (): void => {
    audio.pause();
  };

  return {
    play,
    stop,
  };
};

export default createAudioPlayer;
