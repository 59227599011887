import axios from 'axios';

let heartbeatCounter = 0;

async function callHeartbeatService(apiUrl: string, request: any): Promise<any> {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.post(apiUrl, request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Error returning data from the heartbeat service', response);
    }
  } catch (error) {
    console.error('Error calling the heartbeat service', error);
  }
}

export async function postHeartbeatMetrics(serialNumber: string | undefined, siteId: string | undefined): Promise<any> {
  const apiUrl = process.env.REACT_APP_POSTHEARTBEATMETRIC_URI;
  if (apiUrl === undefined) {
    throw new Error('No Post Heartbeat Metric URL defined');
  }

  if (serialNumber === undefined) {
    console.error('Serial number is undefined', { serialNumber });
    throw new Error('Serial number is undefined');
  }

  if (siteId === undefined) {
    console.error('Site ID is undefined', { siteId });
    throw new Error('Site ID is undefined');
  }

  const request = {
    serialNumber,
    count: heartbeatCounter,
    siteId,
  };

  heartbeatCounter++;

  return await callHeartbeatService(apiUrl, request);
}
