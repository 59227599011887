import uniq from 'lodash/uniq';

export interface SiteMetricModel {
  siteId: string;
  siteName: string;
  metric: number;
  rank?: number;
}

export interface RankingBarChartRenderModel {
  data: Record<string, number>;
  metadata: Record<string, SiteMetricModel>;
}

const toBarGroup = (data: SiteMetricModel[], group: number): RankingBarChartRenderModel => {
  return data.reduce(
    (acc: RankingBarChartRenderModel, item, index) => {
      acc.data[`bar${index}`] = item.metric;
      acc.data['group'] = group;
      acc.metadata[`bar${group}${index}`] = item;
      return acc;
    },
    { data: {}, metadata: {} },
  );
};

export const rankAndGroupData = (siteId: string, data: SiteMetricModel[]): RankingBarChartRenderModel[] => {
  const clonedData = [...data];
  const sortedData = clonedData.map((d, index) => ({ ...d, rank: index }));

  // Less than 10 items, return all
  if (sortedData.length <= 10) {
    return [toBarGroup(sortedData, 0)];
  }

  const siteIndex = sortedData.findIndex((item) => item.siteId === siteId);

  // If the siteId in the top 10 position, return the top 10 items
  if (siteIndex <= 9) {
    return [toBarGroup(sortedData.slice(0, 10), 0)];
  }

  // If the siteId is not in the bottom 5 position, return top 5 and bottom 5
  if (siteIndex >= sortedData.length - 5) {
    return [toBarGroup(sortedData.slice(0, 5), 0), toBarGroup(sortedData.slice(-5), 1)];
  }

  // Return top 5 and the closest 5 items that contain the siteId in the middle
  return [toBarGroup(sortedData.slice(0, 5), 0), toBarGroup(sortedData.slice(siteIndex - 2, siteIndex + 3), 1)];
};

export const getKeys = (data: Array<Record<string, number>>): string[] => {
  return uniq(
    data.reduce((acc: string[], item) => {
      return acc.concat(Object.keys(item).filter((key) => key !== 'group'));
    }, []),
  );
};
