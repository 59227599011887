import WidgetMetricWrapper, { type StandardMetricWrapperProps } from '../wrapper/WidgetMetricWrapper';

interface BoxProps extends Omit<StandardMetricWrapperProps, 'children'> {
  cardWrapperClassName?: string;
}

export const Box = (props: BoxProps): JSX.Element => {
  const { cardWrapperClassName } = props;

  return (
    <WidgetMetricWrapper
      primaryTextClassName='fill-indicator-neutral font-semibold'
      secondaryTextClassName='text-emphasis-neutral font-semibold'
      tertiaryTextClassName='text-emphasis-neutral font-normal text-[15px]'
      {...props}
    >
      <div className={cardWrapperClassName ?? 'rounded-[14px] h-full w-full border-indicator-neutral border-2'} />
    </WidgetMetricWrapper>
  );
};

export const EmptyBox = (props: Omit<BoxProps, 'metricValue'>): JSX.Element => {
  return (
    <Box
      primaryTextClassName='fill-divider-muted font-normal roboto'
      cardWrapperClassName='rounded-[14px] h-full w-full border-indicator-empty border-2'
      textWidthPercentage='40%'
      {...props}
      metricValue={0}
    />
  );
};
