import React, { useEffect, useState } from 'react';
import { EmptyBubble } from '@/widgets/components/bubble';
import { type ZoneAverageGaugeProps } from '@/widgets/zoneAverageGauge';
import { isEmpty } from '@/common';

const Validation = (props: ZoneAverageGaugeProps & { children: JSX.Element }) => {
  const { averageId, children } = props;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(isEmpty(averageId));
  }, [averageId]);

  return hasError ? <EmptyBubble placeholder='Not configured' /> : children;
};

export default Validation;
