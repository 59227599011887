import Curve from './curve';
import { type DistributionDatum } from './utils';

export interface DistributionChartProps {
  width: number;
  height: number;
  data: DistributionDatum[];
}

const DistributionChart = (props: DistributionChartProps): JSX.Element => {
  const { width, height, data } = props;

  return <Curve data={data} width={width} height={height} />;
};

export default DistributionChart;
