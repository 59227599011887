interface WidgetMetricWrapperProps {
  component: JSX.Element;
  children: JSX.Element | JSX.Element[];
}

const ComponentWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => (
  <div className='absolute w-full h-full items-center justify-center'>{children}</div>
);

const WidgetMetricWrapper = (props: WidgetMetricWrapperProps): JSX.Element => {
  const { component, children } = props;

  return (
    <div className='relative h-full'>
      {/* Metric indicator (background) */}
      <ComponentWrapper>{component}</ComponentWrapper>

      {/* Main metric text */}
      <ComponentWrapper>{children}</ComponentWrapper>
    </div>
  );
};

export default WidgetMetricWrapper;
