import { RecordLine } from './RecordLine';
import { Chronology, type OnPaceSummaryDatum, type DepartureDatum } from './types';
import { OnPaceAreaChart } from './areaChart/OnPaceAreaChart';
import { CurrentCountAxis } from './CurrentCountAxis';
import { ParentSize } from '@visx/responsive';
import { OnPaceSummary } from './OnPaceSummary';
import Spinner from '@/widgets/spinner';
import { useMemo } from 'react';
import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface OnPaceChartModel {
  recordsCumulative: DepartureDatum[];
  departuresCumulative: DepartureDatum[];
  prediction: DepartureDatum[];
}

export interface OnPaceChartProps {
  width: number;
  height: number;
  data: OnPaceChartModel;
}

// The y domain of the graph needs to be a little greater than the largest of the departures, records, and prediction
const getMaxDeparture = (data: OnPaceChartModel, isProjectionEnabled: boolean = false): number => {
  const predictions = isProjectionEnabled ? data.prediction : [];
  const allDepartures = [...predictions, ...data.recordsCumulative, ...data.departuresCumulative];

  const allValues = allDepartures.map((departure) => departure.value);
  return Math.max(...allValues);
};

const OnPaceChart = (props: OnPaceChartProps): JSX.Element => {
  const {
    width,
    height,
    data: { prediction, recordsCumulative: records, departuresCumulative: departures },
    data,
  } = props;

  const { [FeatureFlags.eyecueDashboardUseOnPaceProjection]: eyecueDashboardUseOnPaceProjection = true } = useFlags();

  // Set the domain max to a bit above the highest of the data
  const departureDomainMax = useMemo(() => {
    const max = getMaxDeparture(data);
    return Math.ceil(max / 50) * 50;
  }, [data]);

  // Data for Axis Labels
  const departuresLabels = useMemo(() => {
    const valuesCumulative = data.departuresCumulative.map((departure) => departure.value);

    const valuesPerInterval = valuesCumulative.map((value, index) =>
      index === 0 ? value : value - valuesCumulative[index - 1],
    );
    return valuesPerInterval;
  }, [data]);

  // Data for OnPaceSummary
  // Default to 0 if currentDepartures not available, records not available, or prediction value not available.
  const summaryData = useMemo(() => {
    const currentDepartures = departures.reduce((max, obj) => (obj.value > max ? obj.value : max), 0);
    const hourRecord = records.length === 5 ? records[4].value : 0; // Records are cumulative. So the record for the 4th 15 minute period (at index 4, because index 0 is 0) is also the record for the entire hour.
    const predictionValue = prediction.length > 0 ? prediction[0].value : 0;

    const recordSummary = {
      label: 'Record',
      chronology: Chronology.PAST,
      value: hourRecord > 0 ? `${hourRecord}` : '-',
    };

    const projectedSummary = {
      label: 'Projected',
      chronology: Chronology.PROJECTED,
      value: predictionValue > 0 ? `${predictionValue}` : '-',
    };

    const currentSummary = {
      label: 'Current',
      chronology: Chronology.CURRENT,
      value: `${currentDepartures}`,
    };

    const summary: OnPaceSummaryDatum[] =
      eyecueDashboardUseOnPaceProjection === true
        ? [recordSummary, projectedSummary, currentSummary]
        : [recordSummary, currentSummary];

    return summary;
  }, [departures, records, prediction]);

  const recordsReady = records.length > 1;
  const departuresReady = departures.length > 1;

  if (!recordsReady && !departuresReady) {
    return <Spinner />;
  }

  return (
    <div>
      <div style={{ height, width }} className={`flex`}>
        {/* Left Div (takes up 3/4 of the width) */}
        <div className='w-3/4 flex flex-col'>
          {/* Top 9/10 */}
          <div className='bg-gray-000' style={{ flex: 9 }}>
            <ParentSize>
              {(parent) => (
                <svg
                  width={parent.width}
                  height={parent.height}
                  viewBox={`0 0 ${parent.width} ${parent.height}`}
                  overflow='hidden'
                >
                  <OnPaceAreaChart
                    width={parent.width}
                    height={parent.height}
                    departureData={departures}
                    prediction={data.prediction}
                    departureDomainMax={departureDomainMax}
                  />
                  <RecordLine
                    width={parent.width}
                    height={parent.height}
                    data={data.recordsCumulative}
                    departureDomainMax={departureDomainMax}
                  />
                </svg>
              )}
            </ParentSize>
          </div>
          {/* Bot 1/10 */}
          <div style={{ flex: 1 }}>
            <CurrentCountAxis counts={departuresLabels} />
          </div>
        </div>

        {/* Right Div (takes up 1/4 of the width) */}
        <div className='w-1/4 bg-gray-000 flex flex-col'>
          <OnPaceSummary summaries={summaryData} />
        </div>
      </div>
    </div>
  );
};

export default OnPaceChart;
