import WidgetMetricWrapper from '../components/wrapper/WidgetMetricWrapper';

const Placeholder = (props: { message: string }): JSX.Element => {
  return (
    <WidgetMetricWrapper component={<div />}>
      <div className='flex w-full h-full'>
        <span className='m-auto text-8xl text-emphasis-muted font-semibold'>{props.message}</span>
      </div>
    </WidgetMetricWrapper>
  );
};

export default Placeholder;
