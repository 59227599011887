import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { useSpring, animated, easings } from '@react-spring/web';
import { useMemo } from 'react';

const AnimatedBar = animated(Bar);

const TimeSeriesBar = (props: {
  x: number;
  y: number;
  width: number;
  height: number;
  className: string;
  label?: number;
}): JSX.Element => {
  const { x, y, width, height, className, label } = props;

  const barH = useMemo(() => (height < 0 ? 0 : height), [height]);

  const animationProps = useSpring({
    from: { height: 0 },
    to: { height: barH },
    config: { duration: 1000, easing: easings.easeOutCirc },
  });

  return (
    <Group left={width / -2}>
      {/* The point of origin of SVG coordinate system is at top left corner, thus the animation will have top down direction.
          To make the animation bottom up, we need to rotate the bar 180 degree around its center point: transform={`rotate(180, ${x + width/2}, ${y + height/2})`}
      */}
      <AnimatedBar
        x={x}
        y={y}
        width={width}
        height={height < 0 ? 0 : animationProps.height}
        className={className}
        rx={7}
        transform={`rotate(180, ${x + width / 2}, ${y + barH / 2})`}
      />

      {/* Bar label */}
      {label !== undefined && (
        <Text
          x={x + width / 2}
          y={y}
          dy={10}
          verticalAnchor='start'
          textAnchor='middle'
          className='fill-white font-semibold'
        >
          {Math.round(label)}
        </Text>
      )}
    </Group>
  );
};

export default TimeSeriesBar;
