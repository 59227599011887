import { useEffect, useRef } from "react";
import { type Size } from "recharts/types/util/types";

export const useGaugePaddingEffect = (gaugeContainerSize: Size, diameter: number): React.RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current !== null && diameter > 0) {
      // Calculate size of the inner rectangle with height is 2/3 of the diameter
      const height = (2 / 3) * diameter;
      const width = Math.sqrt(Math.pow(diameter, 2) - Math.pow(height, 2));
      const px = (gaugeContainerSize.width - width) / 2;
      const py = (gaugeContainerSize.height - height) / 2;

      ref.current.style.paddingTop = `${py}px`;
      ref.current.style.paddingBottom = `${py}px`;
      ref.current.style.paddingLeft = `${px}px`;
      ref.current.style.paddingRight = `${px}px`;
    }
  }, [gaugeContainerSize.width, gaugeContainerSize.height, diameter, ref?.current]);

  return ref;
}

export const useCardPaddingEffect = (): React.RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current !== null) {
      const { width, height } = getComputedStyle(ref.current);
      const padding = Math.min(parseFloat(width), parseFloat(height)) * 0.1;
      ref.current.style.padding = `${padding}px`;
    }
  }, [ref?.current]);

  return ref;
}