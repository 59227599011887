import {
  type ActionReducerMapBuilder,
  createAsyncThunk,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import createHttp from '@/services/http';
import get from 'lodash/get';
import { isEmpty } from '@/common';
import { REQUEST_STATUS, type State } from '@/stores/sites/types';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '@/stores/sites/utils';
import type { RootState, Selector } from '@/stores';

// This slice could hold either configured dashboard or dashboard template
// The 'id' is undefined when holding dashboard template

export interface WidgetConfig {
  name: string;
  config: any;
}

export interface Template {
  grid: string;
  slots: Record<string, WidgetConfig>;
}

export interface Dashboard {
  id?: string;
  name: string;
  siteId: string;
  template: Template;
  templateId?: string;
}

const getDashboardConfigQuery = `
  query getDashboardConfig($siteId: String, $dashboardId: String) {
    getDashboards(site_id: $siteId, id: $dashboardId){
      id,
      siteId,
      name,
      template {
        grid,
        slots
      }
    }
  }`;

export const getDashboardConfig = createAsyncThunk<Dashboard | undefined, { siteId: string; dashboardId?: string }>(
  'dashboardConfig/getDashboardConfig',
  async ({ siteId, dashboardId }): Promise<Dashboard | undefined> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getDashboardConfigQuery,
      variables: {
        siteId,
        dashboardId,
      },
    });

    const dashboardData = get(response, 'data.data.getDashboards', undefined);

    if (isEmpty(dashboardData) || dashboardData.length === 0) {
      window.logger.error(`No dashboard found.`);
      return undefined;
    }

    if (dashboardData.length > 1) {
      window.logger.warn(`More than one dashboard found. Using first dashboard.`);
    }

    return dashboardData[0];
  },
);

const updateDashboardQuery = `
  mutation updateDashboard($siteId: String, $id: String, $payload: JSON) {
    updateDashboard(siteId: $siteId, id: $id, payload: $payload) {
      id,
      siteId
      name,
      template {
        grid,
        slots
      }
    }
  }`;

export const updateDashboard = createAsyncThunk<Dashboard | undefined, { siteId: string; id: string; payload: any }>(
  'dashboardConfig/updateDashboard',
  async ({ siteId, id, payload }): Promise<Dashboard | undefined> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: updateDashboardQuery,
      variables: {
        siteId,
        id,
        payload,
      },
    });

    return get(response, 'data.data.updateDashboard', undefined);
  },
);

const getDashboardTemplatesQuery = `
  query getDashboardTemplatesV2($clientId: String!, $templateId: String) {
    getDashboardTemplatesV2(clientId: $clientId, templateId: $templateId) {
      id,
      template {
        grid,
        slots
      }
    }
  }`;

export const getDashboardTemplate = createAsyncThunk<
  Dashboard | undefined,
  { clientId: string; siteId: string; templateId: string }
>('dashboardConfig/getDashboardTemplate', async ({ clientId, siteId, templateId }): Promise<Dashboard | undefined> => {
  const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
    query: getDashboardTemplatesQuery,
    variables: {
      clientId,
      templateId,
    },
  });

  const dashboardTemplates = get(response, 'data.data.getDashboardTemplatesV2', null);

  if (isEmpty(dashboardTemplates) || dashboardTemplates.length === 0) {
    window.logger.warn(`No dashboard templates found for client id ${clientId}`);
    return undefined;
  }

  return {
    siteId,
    name: dashboardTemplates[0].name,
    templateId: dashboardTemplates[0].id,
    template: dashboardTemplates[0].template,
  };
});

export interface DashboardConfigState extends State<Dashboard | undefined> {}

const initialState: DashboardConfigState = {
  data: undefined,
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const dashboardConfigSlice = createSlice({
  name: 'dashboardConfigs',
  initialState,
  reducers: {
    setDashboardConfig: (state, { payload }: PayloadAction<Dashboard>) => {
      // Ensure that the dashboard config is only updated if the siteId and id match
      if (state.data?.siteId === payload.siteId && state.data?.id === payload.id) {
        state.data = payload;
      }
    },
    updateDashboardTemplateConfig: (
      state,
      {
        payload,
      }: PayloadAction<{ siteId: string; id: string; slot: { name: string; widget: string; config: WidgetConfig } }>,
    ) => {
      const {
        siteId,
        id,
        slot: { name: slotName, widget, config: slotConfig },
      } = payload;

      if (state.data !== undefined) {
        state.data.template.slots[slotName].config = slotConfig;

        // Update local storage
        const key = `template|${siteId}|${id}`;
        const slots = JSON.parse(localStorage.getItem(key) ?? '{}');
        localStorage.setItem(
          key,
          JSON.stringify({
            ...slots,
            [slotName]: {
              name: widget,
              config: slotConfig,
            },
          }),
        );
      }
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DashboardConfigState>) => {
    builder
      .addCase(getDashboardConfig.pending, pendingReducer)
      .addCase(
        getDashboardConfig.fulfilled,
        fulfilledReducer((state, action) => {
          state.data = action.payload as Dashboard;
        }),
      )
      .addCase(getDashboardConfig.rejected, rejectedReducer);

    builder
      .addCase(updateDashboard.pending, pendingReducer)
      .addCase(
        updateDashboard.fulfilled,
        fulfilledReducer((state, action) => {
          state.data = action.payload as Dashboard;
        }),
      )
      .addCase(updateDashboard.rejected, rejectedReducer);

    builder
      .addCase(getDashboardTemplate.pending, pendingReducer)
      .addCase(
        getDashboardTemplate.fulfilled,
        fulfilledReducer((state, action) => {
          state.data = action.payload as Dashboard;
        }),
      )
      .addCase(getDashboardTemplate.rejected, rejectedReducer);
  },
});

export default dashboardConfigSlice.reducer;

export const { setDashboardConfig, updateDashboardTemplateConfig } = dashboardConfigSlice.actions;

export const selectDashboard = (state: RootState): Dashboard | undefined => state.dashboardConfigs.data;

export const selectWidgetConfig = (widgetName: string, slotName: string): Selector<any> =>
  createSelector(selectDashboard, (dashboard) => {
    if (dashboard === undefined) {
      return undefined;
    }

    const slot = dashboard.template.slots[slotName];

    if (slot === undefined) {
      window.logger.error(`Slot ${slotName} not found in dashboard.`);
      return undefined;
    }

    if (slot.name !== widgetName) {
      window.logger.error(`Widget ${widgetName} doesn't match with widget defined in slot ${slotName}.`);
      return undefined;
    }

    return slot.config;
  });
