import { AlertLevel, type NotificationEvent } from '@/stores/notifications';
import { animated, easings, useTransition } from '@react-spring/web';
import { ParentSize } from '@visx/responsive';
import { useMemo } from 'react';

interface CollapsingListProps {
  notifications: NotificationEvent[];
  height: number;
  width: number;
  onNotificationDismissed: (notificationId: string) => void;
}

const CloseIconSvg = (style: Record<string, any>): JSX.Element => (
  <svg style={style} width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask
      id='mask0_3251_40323'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='6'
      y='5'
      width='14'
      height='15'
    >
      <path
        d='M19.2841 6.20996C19.0973 6.0227 18.8436 5.91747 18.5791 5.91747C18.3146 5.91747 18.0609 6.0227 17.8741 6.20996L12.9841 11.09L8.0941 6.19996C7.90727 6.0127 7.65362 5.90747 7.3891 5.90747C7.12458 5.90747 6.87093 6.0127 6.6841 6.19996C6.2941 6.58996 6.2941 7.21996 6.6841 7.60996L11.5741 12.5L6.6841 17.39C6.2941 17.78 6.2941 18.41 6.6841 18.8C7.0741 19.19 7.7041 19.19 8.0941 18.8L12.9841 13.91L17.8741 18.8C18.2641 19.19 18.8941 19.19 19.2841 18.8C19.6741 18.41 19.6741 17.78 19.2841 17.39L14.3941 12.5L19.2841 7.60996C19.6641 7.22996 19.6641 6.58996 19.2841 6.20996Z'
        fill='black'
      />
    </mask>
    <g mask='url(#mask0_3251_40323)'>
      <rect x='0.984375' y='0.5' width='24' height='24' fill='white' />
    </g>
  </svg>
);

const mapAlertLevelToColor = (alertLevel: AlertLevel): string => {
  switch (alertLevel) {
    case AlertLevel.SUCCESS:
      return 'bg-indicator-okay';
    case AlertLevel.INFO:
      return 'bg-indicator-neutral';
    case AlertLevel.WARN:
      return 'bg-indicator-warn';
    case AlertLevel.ALERT:
      return 'bg-indicator-alert';
    default: {
      window.logger.error(`Invalid alert level: ${alertLevel}`);
      return 'bg-indicator-alert';
    }
  }
};

export const ITEM_HEIGHT_PERCENT = 0.27;
export const GAP_HEIGHT_PERCENT = 0.03; // Gap between elements, and between the first element and the header, and the last element and the bottom of the dashboard
const ITEM_WIDTH_PERCENT = 0.44;
const RADIUS_PERCENT = 0.01;
const TITLE_FONT_PERCENT = 0.075;
const DESCRIPTION_FONT_PERCENT = 0.035;

const CollapsingList = (props: CollapsingListProps): JSX.Element => {
  const { notifications, height, width, onNotificationDismissed } = props;

  const { itemHeight, margin, radius, titleFontSizePixels, descriptionFontSizePixels } = useMemo(() => {
    const itemHeight = height * ITEM_HEIGHT_PERCENT;
    const gap = height * GAP_HEIGHT_PERCENT;
    const margin = gap / 2;
    const radius = height * RADIUS_PERCENT;

    const titleFontSizePixels = height * TITLE_FONT_PERCENT;
    const descriptionFontSizePixels = height * DESCRIPTION_FONT_PERCENT;

    return {
      itemHeight,
      margin,
      radius,
      titleFontSizePixels,
      descriptionFontSizePixels,
    };
  }, [height]);

  const { itemWidth } = useMemo(() => {
    return { itemWidth: width * ITEM_WIDTH_PERCENT };
  }, [width]);

  const transitions = useTransition(notifications, {
    from: { opacity: 0, transform: 'scale(1) translateY(100px)', height: itemHeight },
    enter: { opacity: 1, transform: 'scale(1) translateY(0px)', height: itemHeight },
    leave: { opacity: 0, transform: 'scale(0) translateY(100px)', height: 0 },
    config: { duration: 1000, easing: easings.easeOutCubic },
  });

  // The outer bounds of the element to render. Positions the element vertically and horizontally
  const containingDivStyles: Record<string, any> = {
    width: itemWidth,
    position: 'relative',
  };

  // Inner bounds of the element to render. Positions within the div and adds margin
  const enclosedElementStyles: Record<string, any> = {
    position: 'absolute',
    top: margin,
    left: margin,
    right: margin,
    bottom: margin,
  };

  return (
    <ParentSize>
      {({ width, height }) => {
        return (
          <ul id='collapsing_list'>
            {transitions((styles, item) => {
              const color = mapAlertLevelToColor(item.alert.level);

              return (
                <animated.li
                  key={item.id}
                  notification-id={item.id}
                  className='flex flex-around justify-center'
                  style={{ ...styles }}
                >
                  <div style={{ ...containingDivStyles }}>
                    <div style={{ ...enclosedElementStyles }}>
                      <div
                        className={`flex flex-col justify-evenly ${color}`}
                        style={{ height: itemHeight - margin * 2, borderRadius: radius, position: 'relative' }}
                        onClick={() => {
                          onNotificationDismissed(item.id);
                        }}
                      >
                        <span style={{ top: 5, right: 5, position: 'absolute' }}>
                          <CloseIconSvg />
                        </span>
                        <h1
                          style={{ fontSize: `${titleFontSizePixels}px` }}
                          className='text-center font-bold text-white roboto-fm-v2 -mt-[1%]'
                        >
                          {item.alert.title}
                        </h1>
                        <h2
                          style={{ fontSize: `${descriptionFontSizePixels}px` }}
                          className='text-center font-bold text-white -mt-[2.5%]'
                        >
                          {item.alert.description}
                        </h2>
                      </div>
                    </div>
                  </div>
                </animated.li>
              );
            })}
          </ul>
        );
      }}
    </ParentSize>
  );
};

export default CollapsingList;
