import { REQUEST_STATUS, type State } from '@/stores/sites/types';
import { type ActionReducerMapBuilder, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { getSiteData, type SiteData } from '@/stores/sites/actions';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '@/stores/sites/utils';
import type { RootState } from '@/stores';

interface AwsIot {
  region: string;
  host: string;
}

export interface ClientConfig {
  id: string;
  name: string;
  topics: string[];
  awsIot: AwsIot;
  displayFormat?: {
    duration?: string;
  };
}

export interface SiteConfig {
  clientId: string;
  siteId: string;
  details: {
    name: string;
    timezone: string;
  };
}

export interface GeneralSiteConfig {
  clientConfig?: ClientConfig;
  siteConfig?: SiteConfig;
}

export interface GeneralSiteConfigState extends State<GeneralSiteConfig> {}

const initialState: GeneralSiteConfigState = {
  data: {
    clientConfig: undefined,
    siteConfig: undefined,
  },
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const generalSiteConfigSlice = createSlice({
  name: 'generalSiteConfigs',
  initialState,
  reducers: {
    setGeneralSiteConfig: (state, { payload }: PayloadAction<SiteConfig>) => {
      // Ensure that the site config is only updated if the siteId matches
      if (state.data?.siteConfig?.siteId === payload.siteId) {
        state.data.siteConfig = {
          clientId: payload.clientId,
          siteId: payload.siteId,
          details: {
            name: payload.details.name,
            timezone: payload.details.timezone,
          },
        };
      }
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<GeneralSiteConfigState>) => {
    builder
      .addCase(getSiteData.pending, pendingReducer)
      .addCase(
        getSiteData.fulfilled,
        fulfilledReducer((state, action) => {
          const { clientConfig, siteConfig } = action.payload as SiteData;

          if (clientConfig !== undefined) {
            state.data.clientConfig = {
              id: clientConfig.clientId,
              name: clientConfig.clientName,
              topics: [] as string[], // not supported yet
              awsIot: {
                region: clientConfig.region,
                host: clientConfig.iotHost,
              },
              displayFormat: undefined, // not supported yet
            };
          }

          if (siteConfig !== undefined) {
            state.data.siteConfig = {
              clientId: siteConfig.clientId,
              siteId: siteConfig.siteId,
              details: {
                name: siteConfig.details.name,
                timezone: siteConfig.details.timezone,
              },
            };
          }
        }),
      )
      .addCase(getSiteData.rejected, rejectedReducer);
  },
});

export default generalSiteConfigSlice.reducer;

export const { setGeneralSiteConfig } = generalSiteConfigSlice.actions;

export const selectClientConfig = (state: RootState): ClientConfig | undefined =>
  state.generalSiteConfigs.data?.clientConfig;

export const selectDurationFormat = (state: RootState): string | undefined =>
  state.generalSiteConfigs.data?.clientConfig?.displayFormat?.duration;

export const selectSiteIdV2 = (state: RootState): string | undefined =>
  state.generalSiteConfigs.data?.siteConfig?.siteId;

export const selectSiteNameV2 = (state: RootState): string | undefined =>
  state.generalSiteConfigs.data?.siteConfig?.details.name;
