import { getSiteIdDigits, useAppSelector } from '@/common';
import { selectDate } from '@/stores/calendar';
import { selectConnectionState } from '@/stores/ioTconnections';
import { selectSiteId, selectSiteName } from '@/stores/siteConfigs';
import { CONNECTION_STATE } from '@/types';
import classNames from 'classnames';
import { useMemo } from 'react';
import { selectDeviceConfig } from '@/stores/sites/deviceConfigs';
import { selectSiteIdV2, selectSiteNameV2 } from '@/stores/sites/generalConfigs';

const DashboardFooter = (): JSX.Element => {
  const date = useAppSelector(selectDate);
  const siteName = useAppSelector(selectSiteName);
  const siteNameV2 = useAppSelector(selectSiteNameV2);
  const siteId = useAppSelector(selectSiteId);
  const siteIdV2 = useAppSelector(selectSiteIdV2);

  // Use fallback approach to get siteId
  // TODO: The 'siteId' can be removed after migrating all site to the new 'store' table
  const siteIdDigits = useMemo(() => getSiteIdDigits(siteIdV2 ?? siteId ?? '').siteIdDigits, [siteId, siteIdV2]);

  const version = process.env.REACT_APP_VERSION;
  const device = useAppSelector(selectDeviceConfig);
  const connectionState = useAppSelector(selectConnectionState);

  const connectionStatus = useMemo((): { text: string; color: string } => {
    switch (connectionState) {
      case CONNECTION_STATE.CONNECTED:
        return { text: 'Connected', color: 'text-success' };
      case CONNECTION_STATE.DISCONNECTED:
        return { text: 'Disconnected', color: 'text-error' };
      case CONNECTION_STATE.CONNECTING:
        return { text: 'Connecting...', color: 'text-warning' };
      case CONNECTION_STATE.DISCONNECTING:
        return { text: 'Disconnecting...', color: 'text-warning' };
      case CONNECTION_STATE.ERROR:
        return { text: 'Error', color: 'text-error' };
      case CONNECTION_STATE.INTERRUPTED:
        return { text: 'Interrupted', color: 'text-error' };
      case CONNECTION_STATE.RESUMED:
        return { text: 'Resumed', color: 'text-warning' };
      default:
        return { text: 'Unknown', color: 'text-indicator-empty' };
    }
  }, [connectionState]);

  return (
    <div className='divide-x text-emphasis-loud text-sm font-normal'>
      <span className='text-center px-2'>
        {/*Use fallback approach to get siteName*/}
        {/*TODO: The 'siteName' can be removed after migrating all site to the new 'store' table*/}
        {siteNameV2 ?? siteName} {siteIdDigits}
      </span>
      {device !== undefined && <span className='text-center px-2'>{device.serialNumber}</span>}
      <span className='text-center px-2'>v{version}</span>
      <span className='text-center px-2'>{date}</span>
      {connectionState !== CONNECTION_STATE.CONNECTED && (
        <span className={classNames('text-center px-2', connectionStatus.color)}>{connectionStatus.text}</span>
      )}
    </div>
  );
};

export default DashboardFooter;
