export enum CustomEventType {
  AudioEvent = 'audio-event',
}

export interface AudioEvent {
  src: string;
}

interface CustomEventMap extends Record<CustomEventType, CustomEvent> {
  [CustomEventType.AudioEvent]: CustomEvent<AudioEvent>;
}

interface EventBus {
  on: <K extends CustomEventType>(type: K, listener: (event: CustomEventMap[K]) => void) => void;
  off: <K extends CustomEventType>(type: K, listener: (event: CustomEventMap[K]) => void) => void;
  emit: <K extends CustomEventType>(event: CustomEventMap[K]) => void;
}

const eventBus: EventBus = {
  on: <K extends CustomEventType>(type: K, listener: (event: CustomEventMap[K]) => void): void => {
    window.logger.debug(`Adding event listener: ${type}`);
    document.addEventListener(type, listener as EventListener);
  },
  off: <K extends CustomEventType>(type: K, listener: (event: CustomEventMap[K]) => void): void => {
    window.logger.debug(`Removing event listener: ${type}`);
    document.removeEventListener(type, listener as EventListener);
  },
  emit: <K extends CustomEventType>(event: CustomEventMap[K]): void => {
    window.logger.debug(`Emitting event: ${event.type} with payload: ${JSON.stringify(event.detail)}`);
    document.dispatchEvent(event);
  },
};

export default eventBus;
