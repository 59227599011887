import { Gauge } from '@/widgets/components/gauge';
import { WidgetWrapper } from '../components/wrapper';
import { useAppSelector } from '@/common';
import { RollingType, selectRollingAverages } from '@/stores/averages';
import { StandardWidgetProps } from '@/types';
import Validation from '@/widgets/perfectCarCount/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'perfectCarCount',
  displayName: 'Perfect Car Count',
  description: 'Placeholder description',
};

export interface PerfectCarCountProps extends StandardWidgetProps {
  averageIds: string[];
  target?: number;
  rollingType?: RollingType;
}

const PerfectCarCount = (props: PerfectCarCountProps): JSX.Element => {
  const { target = 90, rollingType = RollingType.ROLLING_60_MINUTES_AVERAGES } = props;
  const averages = useAppSelector(selectRollingAverages(rollingType));

  return (
    <Gauge
      maxValue={target * 2}
      metricValue={averages.perfectCarCount}
      targetValue={target}
      secondaryText={`${target}`}
    />
  );
};

export default function (props: PerfectCarCountProps & { children?: JSX.Element }): JSX.Element {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Validation {...props}>
        <PerfectCarCount {...props} />
      </Validation>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
}
