import { type TypedUseSelectorHook, useSelector } from 'react-redux';
import { type RootState } from '@/stores';
import { RoiType, type ThisMoment } from '@/types';
import { utcToZonedTime, format } from 'date-fns-tz';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import formatISO9075 from 'date-fns/formatISO9075';
import keys from 'lodash/keys';
import map from 'lodash/map';
import head from 'lodash/head';
import last from 'lodash/last';
import { useEffect, useRef } from 'react';
import lowerCase from 'lodash/lowerCase';
import capitalize from 'lodash/capitalize';
import { reduce } from 'lodash';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const getComputedStyle = (element: HTMLElement): CSSStyleDeclaration => window.getComputedStyle(element);

export const getMomentTimeZone = (argument: string, timeZone?: string): ThisMoment => {
  const tz = timeZone !== undefined ? timeZone : 'UTC';
  const result = utcToZonedTime(argument, tz);

  return extractDate(result);
};

export const getThisMomentTimeZone = (timeZone: string): ThisMoment =>
  getMomentTimeZone(new Date().toISOString(), timeZone);

export const extractDate = (date: Date): ThisMoment => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dateOfMonth = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const day = date.getDay();
  const durationFromMidnightInMins = differenceInMinutes(date, new Date(year, month, dateOfMonth, 0, 0, 0));
  const twelveHourTime = format(date, 'hh:mma').toLowerCase();
  const twentyFourHourTime = format(date, 'HH:mm');
  const shortDate = format(date, 'dd MMM yy');

  return {
    year,
    month,
    dateOfMonth,
    hour,
    minute,
    day,
    dayOfWeek: date.toLocaleDateString('default', { weekday: 'long' }),
    durationFromMidnightInMins,
    dateTimeISO9075: formatISO9075(date),
    twelveHourTime,
    twentyFourHourTime,
    timeZone: 'UTC',
    shortDate,
  };
};

export const getThisMomentTargetOrLastAvailable = (
  { durationFromMidnightInMins }: ThisMoment,
  targetInterval: Record<string, number>,
): number => {
  const target: number | undefined = targetInterval[durationFromMidnightInMins];

  // Found targets for this moment
  if (target !== undefined) {
    return target;
  }

  // Find the last available time
  const times = map(keys(targetInterval), Number).sort(function (a, b) {
    return a - b;
  });

  if (times.length === 0) {
    return 0;
  }

  const definedIntervalTimeStart = head(times) ?? 0;
  const definedIntervalTimeEnd = last(times) ?? 0;

  if (durationFromMidnightInMins < definedIntervalTimeStart || durationFromMidnightInMins > definedIntervalTimeEnd) {
    // outside defined hour
    return 0;
  }

  let lastAvailableTime: number = times[0];
  for (let idx = 1; idx < times.length; idx++) {
    const time = times[idx];

    if (time > durationFromMidnightInMins) {
      break;
    }

    if (time > lastAvailableTime) {
      lastAvailableTime = time;
    }
  }

  return targetInterval[lastAvailableTime];
};

export const useIntervalEffect = (callback: () => void, delay: number): void => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current !== undefined) {
        savedCallback.current();
      }
    };

    const id = setInterval(tick, delay);

    return (): void => {
      clearInterval(id);
    };
  }, [delay]);
};

export const getSiteIdDigits = (siteId: string): { siteIdDigits: string } => {
  const parts = siteId.split('-');

  if (parts.length < 4) {
    window.logger.warn(`Invalid siteId ${siteId}`);
    return { siteIdDigits: '' };
  }

  return { siteIdDigits: parts[3] }; // Return the fourth part (index 3)
};

// This will return true for following cases: undefined, null, "", {}
export const isEmpty = (value: any): boolean => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value.trim() === '') ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
};

// Format number to n-digit string
export const formatNumberToNDigitString = (number: number, digit: number = 0): string => {
  return number.toString().padStart(digit, '0');
};

// Format duration in seconds to minutes and seconds
// Supported format: 's' or 'ss' or 'm:ss' or 'mm:ss'
// Example: (5, 's') => '5'
// Example: (5, 'ss') => '05'
// Example: (5, 'm:ss/mm:ss') => '5'
// Example: (65, 'm:ss') => '1:05'
// Example: (65, 'mm:ss') => '01:05'
export const formatDurationToMinutesAndSeconds = (duration: number, format?: string): string => {
  if (duration < 0) {
    window.logger.warn('Duration cannot be negative. Default to 0');
    duration = 0;
  }

  // Pattern to match 's' or 'ss' or 'm:ss' or 'mm:ss'
  const pattern = /^(m{1,2})?:?(s{1,2})$/;

  // Pattern to match 's' or 'ss'
  const secondPattern = /^(s{1,2})$/;

  const defaultFormat = 'm:ss';

  // Default format to use is 'm:ss'
  let formatToUse = format ?? defaultFormat;

  // Validate format to be either 's' or 'ss' or 'm:ss' or 'mm:ss' pattern
  if (!pattern.test(formatToUse)) {
    window.logger.warn(
      'Invalid format input. Please use either "s", "ss", "m:ss", or "mm:ss" pattern. Default to "m:ss"',
    );
    formatToUse = defaultFormat;
  }

  const match = formatToUse.match(pattern);
  const minuteFormat = match?.[1] ?? '';
  const secondFormat = match?.[2] ?? '';

  // If format is 's' or 'ss', return seconds
  if (secondPattern.test(formatToUse)) {
    // If format doesn't match 's' or 'ss', use 's' format
    return formatNumberToNDigitString(duration, secondPattern.test(formatToUse) ? secondFormat.length : 1);
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${formatNumberToNDigitString(minutes, minuteFormat.length)}:${formatNumberToNDigitString(
    seconds,
    secondFormat.length,
  )}`;
};

export const getRoiTypeOptions = (): Array<{ value: string; label: string }> => {
  const values: string[] = Object.values(RoiType);
  return values.map((value) => {
    return { value, label: capitalize(lowerCase(value)) };
  });
};

export const getDirtyFormData = <
  TData extends Record<keyof TDirtyItems, unknown>,
  TDirtyItems extends Record<string, unknown>,
>(
  data: TData,
  dirtyFields: TDirtyItems,
): Partial<TData> => {
  return reduce(
    dirtyFields,
    (dirtyData, value, name) => {
      if (value === true) {
        return { ...dirtyData, [name]: data[name] };
      }

      if (Array.isArray(value)) {
        // If any field in the array is dirty, mark the whole array as dirty
        const isArrayDirty = value.some(
          (item) =>
            item === true || (typeof item === 'object' && item !== null && Object.values(item).some((v) => v === true)),
        );

        if (isArrayDirty) {
          return { ...dirtyData, [name]: data[name] };
        }
        return dirtyData;
      }

      if (typeof value === 'object' && value !== null) {
        // If any field in the object is dirty, mark the whole object as dirty
        if (Object.values(value).some((v) => v === true || (typeof v === 'object' && v !== null))) {
          return { ...dirtyData, [name]: data[name] };
        }
        return dirtyData;
      }

      return dirtyData;
    },
    {},
  );
};

export const generateRoiGroupOptions = (
  data: Record<string, Array<{ id: string; type: string }>>,
): Record<string, Array<{ value: string; label: string }>> => {
  return reduce(
    data,
    (result, value, key) => {
      result[key] = value.map((item: { id: string; type: string }) => ({ value: item.id, label: item.id }));
      return result;
    },
    {} as Record<string, Array<{ value: string; label: string }>>,
  );
};

// Flatten nested object to single level object
// Example: { a: { b: { c: 1 } } } => { 'a.b.c': 1 }
// With array: { a: { b: { c: [1, 2, 3] } } } => { 'a.b.c.0': 1, 'a.b.c.1': 2, 'a.b.c.2': 3 }
export const flattenObject = (object: any): Record<string, string | number | boolean> => {
  return reduce(
    object,
    (result, value, key) => {
      if (typeof value === 'object') {
        const nested = flattenObject(value);
        for (const nestedKey of Object.keys(nested)) {
          result[`${key}.${nestedKey}`] = nested[nestedKey];
        }
      } else {
        result[key] = value;
      }

      return result;
    },
    {} as Record<string, string | number | boolean>,
  );
};
