import { scaleTime, scaleLinear } from '@visx/scale';
import { startOfHour, endOfHour } from 'date-fns';
import { HistoricalDeparturesArea } from './historicalDeparturesArea/HistoricalDeparturesArea';
import { Chronology, type DepartureDatum } from '../types';
import { DeparturesSegment } from '../areaChart/DeparturesSegment';
import { SegmentDividers } from './SegmentDividers';
import { FeatureFlags } from '@/types';
import Feature from '@/common/feature';

interface OnPaceAreaChartProps {
  width: number;
  height: number;
  departureData: DepartureDatum[];
  prediction: DepartureDatum[];
  departureDomainMax: number;
}
export const OnPaceAreaChart = (props: OnPaceAreaChartProps): JSX.Element => {
  const { width, height, departureData, prediction, departureDomainMax } = props;

  if (departureData.length <= 1) {
    // Return early if we don't have any actual departures
    return <></>;
  }

  // Get data model for Historical departures (i.e, everything except the most recent point)
  const historicalDeparturesData = departureData.length > 2 ? departureData.slice(0, -1) : [];

  // Get data model for current departures (i.e, the last 2 data points)
  const currentDeparturesData = departureData.slice(-2);

  // Get data model for predicted departures (i.e, last current, + prediction)
  const predictedDeparturesData =
    departureData.length > 0 && prediction.length > 0 ? [departureData[departureData.length - 1], prediction[0]] : []; // Empty array if no predictions or departures available

  // Configure Scales
  const now = new Date();
  const hourStart = startOfHour(now);
  const hourEnd = endOfHour(now);

  const xScale = scaleTime({
    range: [0, width],
    domain: [hourStart, hourEnd],
  });

  const yScale = scaleLinear({
    range: [height, 0],
    domain: [0, departureDomainMax],
  });

  return (
    <g>
      {historicalDeparturesData.length > 0 && (
        <HistoricalDeparturesArea data={historicalDeparturesData} xScale={xScale} yScale={yScale} height={height} />
      )}
      <DeparturesSegment
        departures={currentDeparturesData}
        xScale={xScale}
        yScale={yScale}
        height={height}
        segmentType={Chronology.CURRENT}
      />
      <Feature flag={FeatureFlags.eyecueDashboardUseOnPaceProjection}>
        <DeparturesSegment
          departures={predictedDeparturesData}
          xScale={xScale}
          yScale={yScale}
          height={height}
          segmentType={Chronology.PROJECTED}
        />
      </Feature>

      <SegmentDividers width={width} height={height} />
    </g>
  );
};
