import { Chronology, type OnPaceSummaryDatum } from './types';

const chronologyToColorMap: Record<Chronology, string> = {
  [Chronology.PAST]: 'text-indicator-okay',
  [Chronology.CURRENT]: 'text-indicator-neutral',
  [Chronology.PROJECTED]: 'text-emphasis-solid',
};
interface OnPaceSummaryProps {
  summaries: OnPaceSummaryDatum[];
}

export const OnPaceSummary = (props: OnPaceSummaryProps): JSX.Element => {
  const { summaries } = props;

  return (
    <div className='h-full grid grid-rows-3'>
      {summaries.map((summary, i) => {
        const textColorClass = chronologyToColorMap[summary.chronology];
        return (
          <div key={summary.chronology} className={`pl-[20%] flex flex-col justify-center `}>
            <div className='text-xl text-left text-emphasis-muted'>{summary.label}</div>
            <div className={`text-6xl font-semibold text-middle roboto-fm-v2 ${textColorClass}`}>{summary.value}</div>
          </div>
        );
      })}
    </div>
  );
};
