import { WidgetWrapper } from '../components/wrapper';
import { useAppSelector, formatDurationToMinutesAndSeconds, getThisMomentTimeZone } from '@/common';
import { Timed } from 'react-time-sync';
import { parseISO, differenceInSeconds } from 'date-fns';
import { Card } from '@/widgets/components/card';
import { selectClientTargets, selectDurationFormat } from '@/stores/sites/generalConfigs';
import { StandardWidgetProps } from '@/types';
import { TimeAtWindowsData, useTimeAtWindows } from '../commonData';
import { selectSiteTargets } from '@/stores/sites/targetConfigs';
import { selectSiteTimeZone, selectTargetTimes } from '@/stores/siteConfigs';
import { getThisMomentTarget } from '@/stores/sites/utils';
import { TargetType } from '@/stores/sites/types';
import Validation from '@/widgets/timeAtWindow/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';
import MetricTextSVG from '../components/text/MetricTextSVG';
import classNames from 'classnames';

export const widgetMetadata: WidgetMetadata = {
  title: 'timeAtWindow',
  displayName: 'Time At Window',
  description: 'Placeholder description',
};

interface RoiLabel {
  roiId: string;
  label: string;
  targetId: string;
  targetType: TargetType;
}

export interface TimeAtWindowProps extends StandardWidgetProps {
  roiLabels: RoiLabel[];
}

const TimeAtWindow = (props: TimeAtWindowProps & { children?: JSX.Element }): JSX.Element => {
  const { roiLabels } = props;
  const durationFormat = useAppSelector(selectDurationFormat);
  const timeAtWindows = useTimeAtWindows(roiLabels);
  const targetTimes: Record<string, number> | undefined = useAppSelector(selectTargetTimes);
  const siteTarget = useAppSelector(selectSiteTargets);
  const clientTargets = useAppSelector(selectClientTargets);
  const timezone = useAppSelector(selectSiteTimeZone);

  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <ul role='list' className='grid grid-flow-col auto-cols-fr gap-5 h-full'>
        {timeAtWindows.map((data: TimeAtWindowsData, index: number) => {
          const { timestamp, label, targetId, targetType, vehicleCount } = data;

          return (
            <li key={index}>
              <Validation {...data}>
                <Timed>
                  {() => {
                    let elapsedTime = 0;
                    if (vehicleCount > 0 && timestamp !== undefined) {
                      const now = new Date();
                      const dateTime = parseISO(timestamp);
                      elapsedTime = differenceInSeconds(now, dateTime);
                    }

                    // Get target value
                    const thisMoment = getThisMomentTimeZone(timezone);
                    // Use fallback approach to get target
                    // TODO: The 'targetTimes' can be removed after migrating all site to the new 'store' table
                    const target =
                      (siteTarget !== undefined
                        ? getThisMomentTarget(thisMoment, targetType, siteTarget.targets, clientTargets)
                        : targetTimes?.[targetId]) ?? 25;

                    return (
                      <Card
                        className={classNames(
                          elapsedTime === 0
                            ? 'bg-indicator-empty'
                            : elapsedTime <= target
                              ? 'bg-indicator-okay'
                              : 'bg-indicator-alert',
                        )}
                      >
                        <MetricTextSVG className='text-9xl font-semibold'>
                          {elapsedTime === 0 ? '-' : formatDurationToMinutesAndSeconds(elapsedTime, durationFormat)}
                        </MetricTextSVG>
                        <>{label !== undefined && <span className='text-xl font-semibold'>{label}</span>}</>
                      </Card>
                    );
                  }}
                </Timed>
              </Validation>
            </li>
          );
        })}
      </ul>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

export default TimeAtWindow;
