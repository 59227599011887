import { useAppSelector, getThisMomentTimeZone, isEmpty } from '@/common';
import { selectSiteTimeZone, selectTargetIntervals } from '@/stores/siteConfigs';
import { selectDepartureRateHistorical15Min } from '@/stores/departures';
import WrappedTimeSeriesBarChart, {
  AggregationMethod,
  ChartSize,
} from '@/widgets/components/charts/timeSeriesBarChart';
import { useMemo } from 'react';
import { WidgetWrapper } from '../components/wrapper';
import { filterAndDecorateData } from '../components/charts/timeSeriesBarChart/utils';
import parseISO from 'date-fns/parseISO';
import { StandardWidgetProps } from '@/types';
import { TargetType } from '@/stores/sites/types';
import { selectSiteTargets } from '@/stores/sites/targetConfigs';
import Validation from '@/widgets/departureRateBarChart/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'departureRateBarChart',
  displayName: 'Departure Rate Bar Chart',
  description: 'Placeholder description',
};

export interface DepartureRateBarChartProps extends StandardWidgetProps {
  roiType: string;
  chartSize?: ChartSize;
  hoursToDisplay?: number;
}

const DepartureRateBarChart = (props: DepartureRateBarChartProps): JSX.Element => {
  const { roiType, chartSize = ChartSize.LARGE, hoursToDisplay = 4 } = props;
  const timeZone = useAppSelector(selectSiteTimeZone);
  const targetIntervals = useAppSelector(selectTargetIntervals);
  const departRateHistoricalData = useAppSelector(selectDepartureRateHistorical15Min(roiType));
  const siteTarget = useAppSelector(selectSiteTargets);

  const barChartData = useMemo(() => {
    const graphData = departRateHistoricalData.map((item) => ({
      timestamp: parseISO(item.intervalStartTime).toISOString(),
      value: item.departRate !== null ? item.departRate : 0,
    }));
    const thisMoment = getThisMomentTimeZone(timeZone);
    return filterAndDecorateData(
      graphData,
      timeZone,
      hoursToDisplay,
      TargetType.DepartureRate,
      targetIntervals?.targetRates[thisMoment.dayOfWeek.toLowerCase()],
      siteTarget,
    );
  }, [departRateHistoricalData, hoursToDisplay, siteTarget, targetIntervals, timeZone]);

  return (
    <WrappedTimeSeriesBarChart
      data={barChartData}
      chartSize={chartSize}
      aggregationMethod={AggregationMethod.AVERAGE}
      aggregationMeasureUnit='s'
      invert
      hideTargetLine={isEmpty(targetIntervals) || isEmpty(targetIntervals?.targetRates)}
    />
  );
};

export default function (props: DepartureRateBarChartProps & { children?: JSX.Element }): JSX.Element {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Validation {...props}>
        <DepartureRateBarChart {...props} />
      </Validation>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
}
