import { WidgetWrapper } from '../components/wrapper';
import { useMemo } from 'react';
import { formatDurationToMinutesAndSeconds, useAppSelector } from '@/common';
import { selectLeadCarResetTimes, selectOldestVehicleEntryTime, selectVehicleCount } from '@/stores/rois';
import { Timed } from 'react-time-sync';
import { parseISO, differenceInSeconds } from 'date-fns';
import { Card } from '@/widgets/components/card';
import { StandardWidgetProps } from '@/types';
import { selectDurationFormat } from '@/stores/sites/generalConfigs';
import Validation from '@/widgets/leadCarTimer/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'leadCarTimer',
  displayName: 'Lead Car Timer',
  description: 'Placeholder description',
};

export interface RoiLabel {
  roiId: string;
  label: string;
  redThreshold: number;
}

export interface LeadCarTimerProps extends StandardWidgetProps {
  roiLabels: RoiLabel[];
}

const LeadCarTimer = (props: LeadCarTimerProps & { children?: JSX.Element }): JSX.Element => {
  const { roiLabels } = props;
  const durationFormat = useAppSelector(selectDurationFormat);
  const roiIds = useMemo(() => roiLabels.map((roiLabel) => roiLabel.roiId), [roiLabels]);
  const roisLeadCarResetTimes: Record<string, string> = useAppSelector(selectLeadCarResetTimes(roiIds));
  const roisOldestVehicle: Record<string, string> = useAppSelector(selectOldestVehicleEntryTime(roiIds));
  const roisVehicleCount: Record<string, number> = useAppSelector(selectVehicleCount(roiIds));

  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <ul role='list' className='grid grid-cols-1 gap-5 h-full'>
        {roiLabels.map((roiLabel: RoiLabel, index: number) => {
          const { label, roiId, redThreshold = 20 } = roiLabel;
          const timestamp = roisLeadCarResetTimes[roiId] ?? roisOldestVehicle[roiId] ?? undefined;
          const vehicleCount = roisVehicleCount[roiId] ?? 0;

          return (
            <li key={index}>
              <Validation {...roiLabel}>
                <Timed>
                  {() => {
                    let elapsedTime = 0;
                    if (vehicleCount > 0 && timestamp !== undefined) {
                      const now = new Date();
                      const dateTime = parseISO(timestamp);
                      elapsedTime = differenceInSeconds(now, dateTime);
                    }

                    return (
                      <Card
                        metricValue={elapsedTime}
                        displayValue={formatDurationToMinutesAndSeconds(elapsedTime, durationFormat)}
                        targetValue={redThreshold}
                        secondaryText={label}
                        cardWrapperClassName='rounded-[14px]'
                        invert
                      />
                    );
                  }}
                </Timed>
              </Validation>
            </li>
          );
        })}
      </ul>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

export default LeadCarTimer;
