import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DialogWrapper, { ModalHeader, ModalTitle, ModalContent, CloseButton } from '../components/dialogWrapper';
import { isEmpty, useAppSelector } from '@/common';
import { selectWidgetConfig } from '@/stores/sites/dashboardConfigs';
import SwapWidgetForm from './SwapWidgetForm';

const SwapWidget = (): JSX.Element => {
  const navigate = useNavigate();
  const { siteId, dashboardId } = useParams();
  const [searchParams] = useSearchParams();
  const widget = searchParams.get('widget') ?? '';
  const slot = searchParams.get('slot') ?? '';
  const config = useAppSelector(selectWidgetConfig(widget, slot));

  return (
    <DialogWrapper open={true}>
      <ModalHeader>
        <ModalTitle>Swap widget</ModalTitle>
        <div className='flex flex-1 justify-end'>
          <CloseButton
            onClick={() => {
              navigate('..', { replace: true });
            }}
          />
        </div>
      </ModalHeader>
      <ModalContent>
        {isEmpty(widget) || isEmpty(slot) ? (
          <>Widget or slot is missing in the URL query parameters.</>
        ) : (
          <SwapWidgetForm {...{ ...config, widgetIdentifier: { siteId, dashboardId, slot, name: widget } }} />
        )}
      </ModalContent>
    </DialogWrapper>
  );
};

export default SwapWidget;
