// This file contains share actions for sites

import { createAsyncThunk } from '@reduxjs/toolkit';
import createHttp from '@/services/http';
import get from 'lodash/get';
import { isEmpty } from '@/common';
import { type Target } from './types';

export interface SiteData {
  clientConfig?: {
    clientId: string;
    clientName: string;
    iotHost: string;
    region: string;
  };
  clientConfigV3?: {
    clientId: string;
    details: {
      name: string;
    };
    targets: Target[];
  };
  siteConfig?: {
    clientId: string;
    siteId: string;
    details: {
      name: string;
      timezone: string;
    };
    targets: Target[];
  };
}

const getSiteDataQuery = `
  query getSiteData($siteIds: [String], $clientId: String, $clientIds: [String]) {
    getClientConfig(clientId: $clientId){
      clientId,
      clientName,
      iotHost,
      region
    }
    getStoresV2(siteIds: $siteIds, clientId: $clientId) {
      clientId,
      siteId,
      details {
        name,
        timezone
      },
      targets {
        type,
        granularity,
        fixed,
        adjustedInterval
      }
    }
    getClientsV3(clientIds: $clientIds) {
		  clientId,
		  targets 
			{
				type,
				granularity,
				fixed
			}
	  }
}`;

export const getSiteData = createAsyncThunk<SiteData, { siteId: string; clientId: string }>(
  'site/getSiteData',
  async ({ siteId, clientId }): Promise<SiteData> => {
    const data: SiteData = {
      clientConfig: undefined,
      siteConfig: undefined,
    };

    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getSiteDataQuery,
      variables: {
        siteIds: [siteId],
        clientId,
        clientIds: [clientId],
      },
    });

    const clientData = get(response, 'data.data.getClientConfig', undefined);

    if (isEmpty(clientData)) {
      window.logger.error(`No client config found for site '${siteId}'.`);
    } else {
      data.clientConfig = { ...clientData };
    }

    const clientDataV3 = get(response, 'data.data.getClientsV3', undefined);
    if (isEmpty(clientDataV3)) {
      window.logger.warn(`No client config v3 found for site '${siteId}' and client ${clientId}.`);
    } else {
      data.clientConfigV3 = { ...clientDataV3[0] };
    }

    const siteData = get(response, 'data.data.getStoresV2', []).find(
      (site: { siteId: string }) => site.siteId === siteId,
    );

    if (isEmpty(siteData)) {
      window.logger.error(`No site config found for site '${siteId}'.`);
    } else {
      data.siteConfig = { ...siteData };
    }

    return data;
  },
);
