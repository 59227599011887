import { type SiteConfig } from '../siteConfigs';
import { type Dashboard } from '@/stores/sites/dashboardConfigs';
import { type ClientConfig } from '@/stores/sites/generalConfigs';
import { type SiteTarget, TargetGranularity, TargetType } from '@/stores/sites/types';

const targetRates: Record<string, number> = {
  '15': 12,
  '390': 17,
  '405': 17,
  '420': 14,
  '435': 14,
  '450': 14,
  '465': 14,
  '480': 12,
  '495': 12,
  '510': 12,
  '525': 12,
  '540': 12,
  '555': 12,
  '570': 12,
  '585': 12,
  '600': 10,
  '615': 10,
  '630': 12,
  '645': 12,
  '660': 12,
  '675': 12,
  '690': 12,
  '705': 12,
  '720': 7,
  '735': 7,
  '750': 7,
  '765': 7,
  '780': 11,
  '795': 11,
  '810': 11,
  '825': 11,
  '840': 15,
  '855': 15,
  '870': 15,
  '885': 15,
  '900': 16,
  '915': 16,
  '930': 16,
  '945': 16,
  '960': 16,
  '975': 16,
  '990': 16,
  '1005': 16,
  '1020': 15,
  '1035': 15,
  '1050': 15,
  '1065': 15,
  '1080': 14,
  '1095': 14,
  '1110': 14,
  '1125': 14,
  '1140': 16,
  '1155': 16,
  '1170': 16,
  '1185': 16,
  '1200': 17,
  '1215': 17,
  '1230': 17,
  '1245': 17,
  '1260': 18,
  '1275': 18,
  '1290': 18,
  '1305': 18,
  '1425': 18,
};

const targetCounts: Record<string, number> = {
  '15': 53,
  '390': 53,
  '405': 53,
  '420': 66,
  '435': 66,
  '450': 66,
  '465': 66,
  '480': 77,
  '495': 77,
  '510': 77,
  '525': 77,
  '540': 75,
  '555': 75,
  '570': 75,
  '585': 75,
  '600': 92,
  '615': 92,
  '630': 75,
  '645': 75,
  '660': 75,
  '675': 75,
  '690': 75,
  '705': 75,
  '720': 125,
  '735': 125,
  '750': 125,
  '765': 125,
  '780': 82,
  '795': 82,
  '810': 82,
  '825': 82,
  '840': 61,
  '855': 61,
  '870': 61,
  '885': 61,
  '900': 55,
  '915': 55,
  '930': 55,
  '945': 55,
  '960': 55,
  '975': 55,
  '990': 55,
  '1005': 55,
  '1020': 59,
  '1035': 59,
  '1050': 59,
  '1065': 59,
  '1080': 63,
  '1095': 63,
  '1110': 63,
  '1125': 63,
  '1140': 58,
  '1155': 58,
  '1170': 58,
  '1185': 58,
  '1200': 54,
  '1215': 54,
  '1230': 54,
  '1245': 54,
  '1260': 50,
  '1275': 50,
  '1290': 50,
  '1305': 50,
  '1425': 50,
};

export const siteConfig: SiteConfig = {
  name: 'Brier Creek',
  siteId: 'fm-cfa-usa-01573',
  timezone: 'America/Detroit',
  targetIntervals: {
    targetCounts: {
      monday: targetCounts,
      thursday: targetCounts,
      friday: targetCounts,
      sunday: targetCounts,
      wednesday: targetCounts,
      tuesday: targetCounts,
      saturday: targetCounts,
    },
    targetRates: {
      monday: targetRates,
      thursday: targetRates,
      friday: targetRates,
      sunday: targetRates,
      wednesday: targetRates,
      tuesday: targetRates,
      saturday: targetRates,
    },
  },
  targetTimes: {
    boomGate: 30,
    pay: 25,
    pickup: 25,
    pullForward: 25,
  },
};

export const clientConfig: ClientConfig = {
  id: 'tst',
  name: 'Burgermark',
  topics: [
    '/eyeq/roicount',
    '/eyeq/depart-rate-15min',
    '/eyeq/depart-count-15min',
    '/eyeq/best-60-minute-count',
    '/eyeq/bar-chart-depart-count-15min',
    '/eyeq/bar-chart-depart-rate-15min',
    '/eyeq/depart-timeline-60min',
    '/eyeq/departure',
    '/eyeq/best-60-minute-count',
    '/eyeq/danger-zones',
  ],
  awsIot: {
    region: 'ap-southeast-2',
    host: 'a1cw4yj8n407ec-ats.iot.ap-southeast-2.amazonaws.com',
  },
  displayFormat: {
    duration: 'm:ss',
  },
};

export const dashboard: Dashboard = {
  id: '74afc5d3-1945-4768-bb25-497d0b456b96',
  name: 'Fingermark Demo',
  siteId: 'fm-tst-nzd-0001',
  template: {
    grid: `
    <div style="display: grid; grid-template-columns: 1fr 2fr 1fr; flex: 1 1 auto; flex-direction: column; aspect-ratio: 16 / 9;">
      <div>
        <div style="height: 50%;"><slot name="leftTop"></slot></div>
        <div style="height: 50%;"><slot name="leftBottom"></slot></div>
      </div>
      <div>
        <div style="height: 66.666667%;"><slot name="middleTop"></slot></div>
        <div style="height: 33.333333%;"><slot name="middleBottom"></slot></div>
      </div>
      <div>
        <div style="height: 50%;"><slot name="rightTop"></slot></div>
        <div style="height: 50%;"><slot name="rightBottom"></slot></div>
      </div>
    </div>
    `,
    slots: {
      header: {
        name: 'dashboardHeader',
        config: {},
      },
      leftTop: {
        name: 'departureCount',
        config: { title: 'Departure count', roiType: 'DELIVER' },
      },
      leftBottom: {
        name: 'departureRateBarChart',
        config: { roiType: 'DELIVER', chartSize: 'SMALL' },
      },
      middleTop: {
        name: 'leadCarTimerV2',
        config: {
          roiLabels: [
            {
              roiId: 'roiId1',
              label: 'Lane 1',
            },
          ],
        },
      },
      middleBottom: {
        name: 'carDistribution',
        config: {
          title: 'Car distribution',
        },
      },
      rightTop: {
        name: 'onPace',
        config: { title: 'One hour pace' },
      },
      rightBottom: {
        name: 'totalExperienceTime',
        config: { title: '5 min TET average' },
      },
    },
  },
};

export const targetConfig: SiteTarget = {
  siteId: 'fm-tst-nzd-0001',
  targets: [
    {
      type: TargetType.DepartureCount,
      granularity: TargetGranularity.Fifteen,
      adjustedInterval: {
        wednesday: {
          '09:15': 17,
          '08:00': 9,
          '08:45': 15,
          '07:30': 11,
          '14:15': 25,
          '13:00': 25,
          '13:45': 23,
          '10:15': 13,
          '17:00': 19,
          '16:30': 18,
          '17:45': 19,
          '18:15': 18,
          '12:30': 28,
          '19:30': 19,
          '22:00': 1,
          '09:45': 15,
          '09:00': 13,
          '21:30': 18,
          '12:45': 27,
          '08:30': 12,
          '12:00': 28,
          '17:15': 18,
          '13:15': 26,
          '20:15': 16,
          '15:30': 23,
          '16:45': 20,
          '11:30': 25,
          '16:00': 19,
          '06:45': 9,
          '07:15': 11,
          '21:45': 9,
          '21:00': 20,
          '20:30': 19,
          '09:30': 17,
          '10:30': 17,
          '11:45': 21,
          '16:15': 18,
          '11:00': 25,
          '12:15': 26,
          '15:45': 21,
          '18:30': 22,
          '15:00': 21,
          '19:45': 17,
          '14:30': 22,
          '19:00': 19,
          '08:15': 10,
          '21:15': 22,
          '07:45': 12,
          '20:45': 20,
          '20:00': 20,
          '06:30': 7,
          '07:00': 7,
          '15:15': 21,
          '10:00': 14,
          '14:45': 20,
          '10:45': 19,
          '11:15': 23,
          '18:45': 16,
          '19:15': 21,
          '14:00': 21,
          '17:30': 17,
          '13:30': 23,
          '18:00': 18,
        },
        thursday: {
          '09:15': 16,
          '08:00': 11,
          '08:45': 13,
          '07:30': 12,
          '14:15': 20,
          '13:00': 24,
          '13:45': 21,
          '10:15': 14,
          '17:00': 19,
          '16:30': 21,
          '17:45': 23,
          '18:15': 20,
          '12:30': 20,
          '19:30': 19,
          '22:00': 1,
          '09:45': 19,
          '09:00': 13,
          '21:30': 20,
          '12:45': 24,
          '08:30': 14,
          '12:00': 19,
          '17:15': 17,
          '13:15': 22,
          '20:15': 17,
          '15:30': 22,
          '16:45': 22,
          '11:30': 23,
          '16:00': 19,
          '06:45': 8,
          '07:15': 12,
          '21:45': 16,
          '21:00': 18,
          '20:30': 16,
          '09:30': 12,
          '10:30': 18,
          '11:45': 21,
          '16:15': 20,
          '11:00': 19,
          '12:15': 20,
          '15:45': 20,
          '18:30': 17,
          '15:00': 20,
          '19:45': 16,
          '14:30': 22,
          '19:00': 18,
          '08:15': 11,
          '21:15': 19,
          '07:45': 13,
          '20:45': 19,
          '20:00': 16,
          '06:30': 6,
          '07:00': 7,
          '15:15': 20,
          '10:00': 16,
          '14:45': 24,
          '10:45': 18,
          '11:15': 20,
          '18:45': 20,
          '19:15': 16,
          '14:00': 22,
          '17:30': 20,
          '13:30': 16,
          '18:00': 20,
        },
        friday: {
          '09:15': 18,
          '08:00': 16,
          '08:45': 12,
          '07:30': 16,
          '14:15': 22,
          '13:00': 21,
          '13:45': 26,
          '10:15': 15,
          '17:00': 18,
          '16:30': 19,
          '17:45': 15,
          '18:15': 18,
          '12:30': 22,
          '19:30': 22,
          '22:00': 1,
          '09:45': 16,
          '09:00': 15,
          '21:30': 20,
          '12:45': 18,
          '08:30': 20,
          '12:00': 21,
          '17:15': 18,
          '13:15': 23,
          '20:15': 20,
          '15:30': 21,
          '16:45': 17,
          '11:30': 22,
          '16:00': 18,
          '06:45': 9,
          '07:15': 11,
          '21:45': 17,
          '21:00': 23,
          '20:30': 23,
          '09:30': 18,
          '10:30': 20,
          '11:45': 18,
          '16:15': 21,
          '11:00': 21,
          '12:15': 17,
          '15:45': 22,
          '18:30': 22,
          '15:00': 23,
          '19:45': 23,
          '14:30': 18,
          '19:00': 21,
          '08:15': 16,
          '21:15': 18,
          '07:45': 9,
          '20:45': 21,
          '20:00': 22,
          '06:30': 11,
          '07:00': 9,
          '15:15': 24,
          '10:00': 15,
          '14:45': 22,
          '10:45': 24,
          '11:15': 21,
          '18:45': 21,
          '19:15': 22,
          '14:00': 20,
          '17:30': 15,
          '13:30': 21,
          '18:00': 17,
        },
        saturday: {
          '09:15': 17,
          '08:00': 17,
          '08:45': 16,
          '07:30': 11,
          '14:15': 22,
          '13:00': 20,
          '13:45': 20,
          '10:15': 19,
          '17:00': 20,
          '16:30': 16,
          '17:45': 19,
          '18:15': 18,
          '12:30': 19,
          '19:30': 16,
          '22:00': 1,
          '09:45': 20,
          '09:00': 14,
          '21:30': 21,
          '12:45': 17,
          '08:30': 15,
          '12:00': 19,
          '17:15': 17,
          '13:15': 20,
          '20:15': 19,
          '15:30': 17,
          '16:45': 19,
          '11:30': 25,
          '16:00': 19,
          '06:45': 8,
          '07:15': 10,
          '21:45': 15,
          '21:00': 21,
          '20:30': 15,
          '09:30': 16,
          '10:30': 18,
          '11:45': 19,
          '16:15': 20,
          '11:00': 20,
          '12:15': 20,
          '15:45': 18,
          '18:30': 19,
          '15:00': 18,
          '19:45': 17,
          '14:30': 19,
          '19:00': 16,
          '08:15': 15,
          '21:15': 21,
          '07:45': 15,
          '20:45': 15,
          '20:00': 16,
          '06:30': 8,
          '07:00': 6,
          '15:15': 17,
          '10:00': 18,
          '14:45': 17,
          '10:45': 15,
          '11:15': 19,
          '18:45': 18,
          '19:15': 18,
          '14:00': 19,
          '17:30': 19,
          '13:30': 23,
          '18:00': 16,
        },
        tuesday: {
          '09:15': 15,
          '08:00': 11,
          '08:45': 11,
          '07:30': 9,
          '14:15': 18,
          '13:00': 17,
          '13:45': 20,
          '10:15': 12,
          '17:00': 20,
          '16:30': 17,
          '17:45': 17,
          '18:15': 16,
          '12:30': 20,
          '19:30': 17,
          '22:00': 4,
          '09:45': 17,
          '09:00': 15,
          '21:30': 13,
          '12:45': 22,
          '08:30': 15,
          '12:00': 17,
          '17:15': 16,
          '13:15': 23,
          '20:15': 16,
          '15:30': 19,
          '16:45': 19,
          '11:30': 22,
          '16:00': 16,
          '06:45': 10,
          '07:15': 9,
          '21:45': 8,
          '21:00': 18,
          '20:30': 17,
          '09:30': 15,
          '10:30': 20,
          '11:45': 18,
          '16:15': 16,
          '11:00': 20,
          '12:15': 19,
          '15:45': 21,
          '18:30': 13,
          '15:00': 20,
          '19:45': 15,
          '14:30': 18,
          '19:00': 14,
          '08:15': 11,
          '21:15': 17,
          '07:45': 10,
          '20:45': 17,
          '20:00': 20,
          '06:30': 8,
          '07:00': 6,
          '15:15': 21,
          '10:00': 13,
          '14:45': 19,
          '10:45': 19,
          '11:15': 22,
          '18:45': 15,
          '19:15': 15,
          '14:00': 17,
          '17:30': 19,
          '13:30': 19,
          '18:00': 17,
        },
        monday: {
          '09:15': 11,
          '08:00': 10,
          '08:45': 14,
          '07:30': 13,
          '14:15': 18,
          '13:00': 17,
          '13:45': 21,
          '10:15': 13,
          '17:00': 21,
          '16:30': 18,
          '17:45': 18,
          '18:15': 20,
          '12:30': 19,
          '19:30': 16,
          '22:00': 3,
          '09:45': 16,
          '09:00': 13,
          '21:30': 19,
          '12:45': 19,
          '08:30': 11,
          '12:00': 15,
          '17:15': 18,
          '13:15': 19,
          '20:15': 15,
          '15:30': 16,
          '16:45': 21,
          '11:30': 17,
          '16:00': 17,
          '06:45': 9,
          '07:15': 6,
          '21:45': 16,
          '21:00': 20,
          '20:30': 16,
          '09:30': 18,
          '10:30': 22,
          '11:45': 18,
          '16:15': 19,
          '11:00': 20,
          '12:15': 23,
          '15:45': 16,
          '18:30': 22,
          '15:00': 21,
          '19:45': 15,
          '14:30': 16,
          '19:00': 16,
          '08:15': 12,
          '21:15': 20,
          '07:45': 6,
          '20:45': 16,
          '20:00': 16,
          '06:30': 6,
          '07:00': 7,
          '15:15': 15,
          '10:00': 13,
          '14:45': 16,
          '10:45': 21,
          '11:15': 24,
          '18:45': 20,
          '19:15': 17,
          '14:00': 18,
          '17:30': 19,
          '13:30': 17,
          '18:00': 21,
        },
        sunday: {},
      },
    },
  ],
};
