// This file contains share actions for sites

import { createAsyncThunk } from '@reduxjs/toolkit';
import createHttp from '@/services/http';
import get from 'lodash/get';
import { isEmpty } from '@/common';

export interface SiteData {
  clientConfig?: {
    clientId: string;
    clientName: string;
    iotHost: string;
    region: string;
  };
  siteConfig?: {
    clientId: string;
    siteId: string;
    details: {
      name: string;
      timezone: string;
    };
    targets: Array<{
      type: string;
      granularity: string;
      fixed?: number;
      adjustedInterval?: {
        sunday?: Record<string, number>;
        monday?: Record<string, number>;
        tuesday?: Record<string, number>;
        wednesday?: Record<string, number>;
        thursday?: Record<string, number>;
        friday?: Record<string, number>;
        saturday?: Record<string, number>;
      };
    }>;
  };
}

const getSiteDataQuery = `
  query getSiteData($siteIds: [String], $clientId: String) {
    getClientConfig(clientId: $clientId){
      clientId,
      clientName,
      iotHost,
      region
    }
    getStoresV2(siteIds: $siteIds, clientId: $clientId) {
      clientId,
      siteId,
      details {
        name,
        timezone
      },
      targets {
        type,
        granularity,
        fixed,
        adjustedInterval
      }
    }
  }`;

export const getSiteData = createAsyncThunk<SiteData, { siteId: string; clientId: string }>(
  'site/getSiteData',
  async ({ siteId, clientId }): Promise<SiteData> => {
    const data: SiteData = {
      clientConfig: undefined,
      siteConfig: undefined,
    };

    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getSiteDataQuery,
      variables: {
        siteIds: [siteId],
        clientId,
      },
    });

    const clientData = get(response, 'data.data.getClientConfig', undefined);

    if (isEmpty(clientData)) {
      window.logger.error(`No client config found for site '${siteId}'.`);
    } else {
      data.clientConfig = { ...clientData };
    }

    const siteData = get(response, 'data.data.getStoresV2', []).find(
      (site: { siteId: string }) => site.siteId === siteId,
    );

    if (isEmpty(siteData)) {
      window.logger.error(`No site config found for site '${siteId}'.`);
    } else {
      data.siteConfig = { ...siteData };
    }

    return data;
  },
);
