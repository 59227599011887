export interface DepartureDatum {
  date: Date;
  value: number;
}

export interface OnPaceSummaryDatum {
  label: string;
  chronology: Chronology;
  value: string;
}

export enum Chronology {
  PAST = 'RECORD',
  CURRENT = 'CURRENT',
  PROJECTED = 'PROJECTED',
}
