import { useContext, useMemo } from 'react';
import { type GaugeSVGProps, GaugeSVG } from './svg/GaugeSVG';
import { WidgetContentContext } from '../wrapper/widgetContentContext';
import WidgetMetricWrapper from '../wrapper/WidgetMetricWrapper';
import MetricTextSVG from '../text/MetricTextSVG';
import { useGaugePaddingEffect } from '../hook';

interface GaugeProps extends Omit<GaugeSVGProps, 'size'> {
  displayValue?: string;
  secondaryText?: string;
  isActive?: boolean;
}

export const Gauge = (props: GaugeProps): JSX.Element => {
  const { maxValue, metricValue, targetValue, invert, isActive = true, displayValue, secondaryText } = props;
  const { renderSize: gaugeSize } = useContext(WidgetContentContext);
  const diameter = useMemo(
    () => Math.min(gaugeSize.width, gaugeSize.height) * 0.85,
    [gaugeSize.width, gaugeSize.height],
  );

  const metricRef = useGaugePaddingEffect(gaugeSize, diameter);

  return (
    <WidgetMetricWrapper
      component={
        <GaugeSVG
          size={gaugeSize}
          maxValue={maxValue}
          metricValue={metricValue}
          targetValue={targetValue}
          invert={invert}
          isActive={isActive}
        />
      }
    >
      <div ref={metricRef} className='flex flex-col items-center justify-center h-full w-full z-10'>
        <MetricTextSVG className='text-9xl fill-emphasis-solid font-semibold'>
          {metricValue === 0 ? '-' : displayValue !== undefined ? displayValue : `${metricValue}`}
        </MetricTextSVG>
        {secondaryText !== undefined && (
          <span className='text-xl text-emphasis-muted font-semibold'>{secondaryText}</span>
        )}
      </div>
    </WidgetMetricWrapper>
  );
};
