import { useAppSelector, formatDurationToMinutesAndSeconds, getThisMomentTimeZone, isEmpty } from '@/common';
import { selectSiteTimeZone, selectTargetTimes } from '@/stores/siteConfigs';
import { WidgetWrapper } from '../components/wrapper';
import { StandardWidgetProps } from '@/types';
import { useZoneAverages } from '../commonData';
import { Gauge } from '../components/gauge';
import { useMemo } from 'react';
import { selectDurationFormat } from '@/stores/sites/generalConfigs';
import { selectSiteTargets } from '@/stores/sites/targetConfigs';
import { getThisMomentTarget } from '@/stores/sites/utils';
import { Bubble } from '../components/bubble';
import { type TargetType } from '@/stores/sites/types';
import { RollingType } from '@/stores/averages';
import Validation from '@/widgets/zoneAverageGauge/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'zoneAverageGauge',
  displayName: 'Zone Average Gauge',
  description: 'Placeholder description',
};

export interface ZoneAverageGaugeProps extends StandardWidgetProps {
  averageId: string;
  targetId: string;
  targetType: TargetType;
  rollingType?: RollingType;
  timeDisplay?: string;
}

const ZoneAverageGauge = (props: ZoneAverageGaugeProps): JSX.Element => {
  const {
    averageId,
    targetId,
    targetType,
    rollingType = RollingType.ROLLING_60_MINUTES_AVERAGES,
    timeDisplay = '',
  } = props;
  const durationFormat = useAppSelector(selectDurationFormat);
  const timeDisplayToUse = useMemo(
    () => (isEmpty(timeDisplay) ? durationFormat : timeDisplay),
    [timeDisplay, durationFormat],
  );
  const timezone = useAppSelector(selectSiteTimeZone);
  const targetTimes: Record<string, number> | undefined = useAppSelector(selectTargetTimes);
  const siteTarget = useAppSelector(selectSiteTargets);

  const target = useMemo(() => {
    const thisMoment = getThisMomentTimeZone(timezone);
    // Use fallback approach to get target
    // TODO: The 'targetTimes' can be removed after migrating all site to the new 'store' table
    return siteTarget !== undefined
      ? getThisMomentTarget(thisMoment, targetType, siteTarget.targets)
      : targetTimes?.[targetId];
  }, [siteTarget, targetTimes, targetId, timezone, targetType]);

  const zoneAverages = useZoneAverages([{ averageId }], rollingType);

  if (zoneAverages.length === 0) {
    throw new Error('Invalid averageId provided to ZoneAverageGauge widget.');
  }

  return target === undefined ? (
    <Bubble
      metricValue={zoneAverages[0].average}
      displayValue={formatDurationToMinutesAndSeconds(zoneAverages[0].average, timeDisplayToUse)}
      primaryTextClassName='fill-emphasis-solid font-semibold'
    />
  ) : (
    <Gauge
      maxValue={target * 2}
      metricValue={zoneAverages[0].average}
      displayValue={formatDurationToMinutesAndSeconds(zoneAverages[0].average, timeDisplayToUse)}
      targetValue={target}
      secondaryText={formatDurationToMinutesAndSeconds(target, timeDisplayToUse)}
      invert
    />
  );
};

export default function (props: ZoneAverageGaugeProps & { children?: JSX.Element }): JSX.Element {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Validation {...props}>
        <ZoneAverageGauge {...props} />
      </Validation>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
}
