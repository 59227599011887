import { LinePath } from '@visx/shape';
import { type DepartureDatum } from './types';
import { scaleTime, scaleLinear } from '@visx/scale';
import { endOfHour, startOfHour } from 'date-fns';

export interface RecordLineProps {
  data: DepartureDatum[];
  departureDomainMax: number;
  width: number;
  height: number;
}

export const RecordLine = (props: RecordLineProps): JSX.Element => {
  const { data, height, width, departureDomainMax } = props;

  // Configure Scales
  const now = new Date();
  const hourStart = startOfHour(now);
  const hourEnd = endOfHour(now);

  const xScale = scaleTime({
    range: [0, width], // i.e, that screen space for our scale is 0 on the left, and the width of the widget on the right
    domain: [hourStart, hourEnd], // Use the 'date-fns' library to create these
  });

  const yScale = scaleLinear({
    range: [height, 0],
    domain: [0, departureDomainMax],
  });

  const x = (d: DepartureDatum): number => {
    return xScale(d.date);
  };
  const y = (d: DepartureDatum): number => {
    return yScale(d.value);
  };

  return (
    <LinePath
      data={data}
      x={x}
      y={y}
      strokeWidth={3}
      shapeRendering='geometricPrecision'
      className='stroke-indicator-okay'
    />
  );
};
