import React, { useCallback, useMemo } from 'react';
import { BarGroupHorizontal } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import {
  getKeys,
  rankAndGroupData,
  type SiteMetricModel,
} from '@/widgets/components/charts/horizontalRankingBarChart/utils';
import RankingBar from '@/widgets/components/charts/horizontalRankingBarChart/RankingBar';

export interface HorizontalBarChartProps {
  siteId: string;
  width: number;
  height: number;
  data: SiteMetricModel[];
  margin?: { top: number; right: number; bottom: number; left: number };
}

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };

const max = <D,>(arr: D[], fn: (d: D) => number): number => {
  return Math.max(...arr.map(fn));
};

// accessors
const getGroup = (d: Record<string, number>): number => d.group;

const HorizontalRankingBarChart = (props: HorizontalBarChartProps): JSX.Element => {
  const { siteId, width, height, data: rawData, margin = defaultMargin } = props;

  const renderModels = useMemo(() => rankAndGroupData(siteId, rawData), [siteId, rawData]);
  const data = useMemo(() => renderModels.map((model) => model.data), [renderModels]);
  const metadata = useMemo(() => renderModels.map((model) => model.metadata), [renderModels]);
  const keys = useMemo(() => getKeys(data), [data]);

  // scales
  const groupScale = useCallback(
    scaleBand({
      domain: data.map(getGroup),
      paddingOuter: 0,
    }),
    [data],
  );

  const siteScale = useCallback(
    scaleBand({
      domain: keys,
      padding: 0.15,
    }),
    [keys],
  );

  const metricScale = useCallback(
    scaleLinear<number>({
      domain: [0, max(data, (d) => max(keys, (key) => d[key] ?? 0))],
    }),
    [data, keys],
  );

  const colorScale = useCallback(
    scaleOrdinal<string, string>({
      domain: [],
      range: [],
    }),
    [],
  );

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // update scale output dimensions
  groupScale.rangeRound([0, yMax]);
  siteScale.rangeRound([0, groupScale.bandwidth()]);
  metricScale.rangeRound([0, xMax]);

  return width < 10 ? (
    <></>
  ) : (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <rect x={0} y={0} width={width} height={height} className='fill-dashboard-background' />
      <Group top={margin.top} left={margin.left}>
        <BarGroupHorizontal
          data={data}
          keys={keys}
          width={xMax}
          y0={getGroup}
          y0Scale={groupScale}
          y1Scale={siteScale}
          xScale={metricScale}
          color={colorScale}
        >
          {(barGroups) =>
            barGroups.map((barGroup) => (
              <Group key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`} top={barGroup.y0}>
                {barGroup.bars.map((bar) => {
                  const {
                    siteId: currentSiteId,
                    siteName,
                    rank,
                  } = metadata[barGroup.index][`bar${barGroup.index}${bar.index}`];

                  return (
                    <RankingBar
                      key={`ranking-bar-${barGroup.index}-${bar.index}`}
                      siteName={siteName}
                      value={bar.value}
                      rank={rank! + 1}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      highlight={siteId === currentSiteId}
                      group={barGroup.index}
                    />
                  );
                })}
              </Group>
            ))
          }
        </BarGroupHorizontal>
      </Group>
    </svg>
  );
};

export default HorizontalRankingBarChart;
