import { useEffect, useState } from 'react';
import { isEmpty } from '@/common';
import { type ZoneAverageData } from '@/widgets/commonData';
import { NotConfiguredBox } from '@/widgets/components/box';

const Validation = (props: ZoneAverageData & { children: JSX.Element }) => {
  const { averageId, children } = props;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(isEmpty(averageId));
  }, [averageId]);

  return hasError ? <NotConfiguredBox /> : children;
};

export default Validation;
