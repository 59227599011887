import { type Draft, type PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { type RootState } from '.';

export enum RollingType {
  ROLLING_60_MINUTES_AVERAGES = 'rolling60MinutesAverages',
  ROLLING_15_MINUTES_AVERAGES = 'rolling15MinutesAverages',
}

export const RollingTypeOptions = [
  { value: RollingType.ROLLING_15_MINUTES_AVERAGES, label: '15 Minutes' },
  { value: RollingType.ROLLING_60_MINUTES_AVERAGES, label: '60 Minutes' },
];

export const AverageOptions = [
  { value: 'boomGateAverage', label: 'Order' },
  { value: 'order1Average', label: 'Order lane 1' },
  { value: 'order2Average', label: 'Order lane 2' },
  { value: 'paymentAverage', label: 'Payment' },
  { value: 'deliverAverage', label: 'Deliver' },
  { value: 'pullForwardAverage', label: 'Pull forward' },
  { value: 'waitingAverage', label: 'Waiting' },
  { value: 'orderStartToDeliverEndAverage', label: 'Total experience time' },
  { value: 'orderEndToDeliverEndAverage', label: 'Order END to deliver end' },
];

export interface RollingEvent {
  order1?: number;
  order2?: number;
  payment?: number;
  deliver?: number;
  pullForward?: number;
  waiting?: number;
  perfectCarCount?: number;
  oepe?: {
    // order end to present end (total journey time minus time spent at order point)
    average: number;
    percentage: number; // percentage value not being used at this stage
  };
  tet?: {
    // total experience time (total journey time including time spent at order point)
    average: number;
    percentage: number; // percentage value not being used at this stage
    rollingSeconds: number;
  };
}

export interface Averages {
  boomGateAverage: number;
  order1Average: number;
  order2Average: number;
  paymentAverage: number;
  deliverAverage: number;
  waitingAverage: number;
  pullForwardAverage: number;
  orderStartToDeliverEndAverage: number;
  orderStartToDeliverEndAverageRollingSeconds: number;
  orderEndToDeliverEndAverage: number;
  perfectCarCount: number;
}

export type AveragesState = Record<RollingType, Averages>;

const defaultValue: Averages = {
  boomGateAverage: 0,
  order1Average: 0,
  order2Average: 0,
  paymentAverage: 0,
  deliverAverage: 0,
  waitingAverage: 0,
  pullForwardAverage: 0,
  orderStartToDeliverEndAverage: 0,
  orderStartToDeliverEndAverageRollingSeconds: 0,
  orderEndToDeliverEndAverage: 0,
  perfectCarCount: 0,
}

const initialState: AveragesState = {
  rolling60MinutesAverages: defaultValue,
  rolling15MinutesAverages: defaultValue,
};

export const averagesSlice = createSlice({
  name: 'averages',
  initialState,
  reducers: {
    setRollingAverages: (
      state: Draft<AveragesState>,
      { payload }: PayloadAction<{ rollingType: RollingType; data: RollingEvent }>,
    ) => {
      const {
        rollingType,
        data: {
          order1 = 0,
          order2 = 0,
          payment = 0,
          deliver = 0,
          pullForward = 0,
          waiting = 0,
          perfectCarCount = 0,
          tet = { average: 0, percentage: 0, rollingSeconds: 0 },
          oepe = { average: 0, percentage: 0 },
        },
      } = payload;

      state[rollingType] = {
        boomGateAverage: Math.floor(Math.max(order1, order2)),
        order1Average: Math.floor(order1),
        order2Average: Math.floor(order2),
        paymentAverage: Math.floor(payment),
        deliverAverage: Math.floor(deliver),
        waitingAverage: Math.floor(waiting),
        pullForwardAverage: Math.floor(pullForward),
        orderStartToDeliverEndAverage: Math.floor(tet.average),
        orderStartToDeliverEndAverageRollingSeconds: tet.rollingSeconds,
        orderEndToDeliverEndAverage: Math.floor(oepe.average),
        perfectCarCount: Math.floor(perfectCarCount),
      };
    },
  },
});

const selectAverages = (state: RootState): AveragesState => {
  return state.averages;
};

export const selectRollingAverages = (rollingType: RollingType): ((state: RootState) => Averages) =>
  createSelector([selectAverages], (averages) => averages[rollingType]);

export const { setRollingAverages } = averagesSlice.actions;
export default averagesSlice.reducer;
