import { createSlice, type PayloadAction, createSelector, type Selector } from '@reduxjs/toolkit';
import { type RootState } from './index';

export interface QueuesState {
  functionalZones: FunctionalZonesState;
}

export interface FunctionalZonesState {
  version: number;
  functionalZones: Record<string, FunctionalZone>;
}

interface FunctionalZone {
  queueLevel: number;
  timeUtc: string;
}

const initialState: QueuesState = {
  functionalZones: {
    version: 1,
    functionalZones: {},
  },
};

export const queues = createSlice({
  name: 'queues',
  initialState,
  reducers: {
    setFunctionalZones: (state, { payload }: PayloadAction<FunctionalZonesState>) => {
      state.functionalZones = payload;
    },
  },
});

const selectFunctionalZones = (state: RootState): Record<string, FunctionalZone> =>
  state.queues.functionalZones.functionalZones;

export const selectFilteredFunctionalZones = (
  zoneKeys: string[],
): Selector<RootState, Record<string, FunctionalZone>> =>
  createSelector([selectFunctionalZones], (functionalZones) => {
    const lowerCasedZoneKeys = zoneKeys.map((zone) => String(zone).toLowerCase());
    const selectedZones: Record<string, FunctionalZone> = {};
    lowerCasedZoneKeys.forEach((key) => {
      if (functionalZones[key] !== undefined) {
        selectedZones[key] = functionalZones[key];
      }
    });

    return selectedZones;
  });

export const { setFunctionalZones } = queues.actions;
export default queues.reducer;
