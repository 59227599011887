import { createSlice, type ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { type RootState } from '..';
import { type TargetInterval, type SiteConfigState } from './types';
import { getSiteConfig } from './actions';

export const TargetTimeOptions = [
  { value: 'boomGate', label: 'Boom gate' },
  { value: 'order', label: 'Order' },
  { value: 'pay', label: 'Payment' },
  { value: 'pickup', label: 'Deliver' },
  { value: 'parkedBay', label: 'Waiting' },
  { value: 'pullForward', label: 'Pull forward' },
  { value: 'tet', label: 'Total experience time' },
  { value: 'oefd', label: 'Order END to deliver end' },
];

const initialState: SiteConfigState = {
  siteConfig: undefined,
  isLoadingConifg: false,
};

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SiteConfigState>) => {
    builder
      .addCase(getSiteConfig.pending, (state): void => {
        state.isLoadingConifg = true;
      })
      .addCase(getSiteConfig.fulfilled, (state, action): void => {
        const { siteConfig } = action.payload;

        state.siteConfig = siteConfig;
        state.isLoadingConifg = false;
      })
      .addCase(getSiteConfig.rejected, (state, action) => {
        window.logger.error(action.error);
        state.isLoadingConifg = false;
      });
  },
});

export default storesSlice.reducer;

export const selectSiteTimeZone = (state: RootState): string => state.stores.siteConfig?.timezone ?? 'UTC';
export const selectTargetIntervals = (state: RootState): TargetInterval | undefined =>
  state.stores.siteConfig?.targetIntervals;
export const selectTargetTimes = (state: RootState): Record<string, number> | undefined =>
  state.stores.siteConfig?.targetTimes;
export const selectSiteId = (state: RootState): string | undefined => state.stores.siteConfig?.siteId;
export const selectSiteName = (state: RootState): string | undefined => state.stores.siteConfig?.name;
export const selectIsLoadingConfig = (state: RootState): boolean | undefined => state.stores.isLoadingConifg ?? false;

export * from './types';

export * from './actions';
