import { useEffect, useState } from 'react';
import BatteryIndicator from './BatteryIndicator';

const Battery = (): JSX.Element => {
  const [batteryCharging, setBatteryCharging] = useState(false);
  const [batteryLevel, setBatteryLevel] = useState<number | undefined>(undefined);

  useEffect(() => {
    const getBattery = async () => {
      if (navigator !== undefined && (navigator as any).getBattery !== undefined) {
        const battery = await (navigator as any).getBattery();
        setBatteryCharging(battery.charging);
        setBatteryLevel(Math.ceil((battery.level as number) * 100));
        battery.onchargingchange = () => {
          setBatteryCharging(battery.charging);
        };
        battery.onlevelchange = () => {
          setBatteryLevel(Math.ceil(battery.level * 100));
        };
      }
    };

    getBattery();
  }, []);

  return <BatteryIndicator battery={batteryLevel} charging={batteryCharging} />;
};

export default Battery;
