import { type WidgetIdentifier } from '@/stores/siteConfigs';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContainedButton, TextButton } from '@/widgets/components/form/Buttons';
import { availableWidgets } from './availableWidgets';

const SwapWidgetForm = <T,>(
  props: T & { widgetIdentifier: WidgetIdentifier } & { children: JSX.Element | JSX.Element[] },
): JSX.Element => {
  const { widgetIdentifier } = props;
  const [availableWidgetsList, setAvailableWidgetsList] = useState(availableWidgets);

  const navigate = useNavigate();
  const back = useCallback(() => {
    navigate('..', { replace: true });
  }, [navigate]);

  const getWidgetDisplayName = (widgetTitle: string): string | undefined => {
    const widget = availableWidgets.find((widget) => widget.title === widgetTitle);
    return widget?.displayName;
  };

  const openWidgetConfig = async (widgetSlot: string, replacementWidget: string): Promise<void> => {
    const url = `configuration?widget=${replacementWidget}&slot=${widgetSlot}&source=swapWidget`;
    navigate(url);
  };

  useEffect(() => {
    const filteredWidgets = availableWidgets.filter((widget) => widget.title !== widgetIdentifier.name);
    setAvailableWidgetsList(filteredWidgets);
  }, [widgetIdentifier]);

  return (
    <div>
      <div className='text-emphasis-primary font-medium'>{getWidgetDisplayName(widgetIdentifier.name)}</div>
      <ul role='list' className='grid grid-cols-1 gap-4'>
        {availableWidgetsList.map((widget) => (
          <li
            key={widget.title}
            className='relative flex items-center space-x-3 rounded-lg border border-divider-muted bg-dashboard-background shadow-sm hover:border-divider-loud group'
          >
            <div className='flex w-full items-center justify-between space-x-6 p-6'>
              <div className='focus:outline-none truncate'>
                <div className='flex items-center space-x-3'>
                  <p className='text-sm font-medium text-emphasis-loud'>{widget.displayName}</p>
                </div>
                <p className='mt-1 truncate text-sm text-emphasis-light'>{widget.description}</p>
              </div>
              <div className='invisible'>
                <ContainedButton
                  className='group-hover:visible'
                  onClick={async () => {
                    await openWidgetConfig(widgetIdentifier.slot, widget.title);
                  }}
                >
                  Swap
                </ContainedButton>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className='mt-6 flex items-center justify-end gap-x-6'>
        <TextButton onClick={back}>Cancel</TextButton>
      </div>
    </div>
  );
};

export default SwapWidgetForm;
