import React from 'react';
import { type Ref, useLayoutEffect, useRef, useState } from 'react';

export interface TextSVGProps {
  primaryText: string;
  primaryTextClassName?: string;
  textWidthPercentage?: string;
  textHeightPercentage?: string;
  textDominantBaseline?: string;
}

const TextSVG = (props: TextSVGProps, ref: Ref<SVGTextElement>): JSX.Element => {
  const {
    primaryText,
    textWidthPercentage = '100%',
    textHeightPercentage = '100%',
    primaryTextClassName,
    textDominantBaseline = 'central',
  } = props;
  const primaryTextRef = useRef(null);
  const secondaryTextRef = useRef(null);
  const [computedTextLength, setComputedTextLength] = useState<number>(0);
  const [computedTextHeight, setComputedTextHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (primaryTextRef?.current !== null) {
      const primaryTextLength = (primaryTextRef.current as any).getComputedTextLength();
      const lineHeight = window.getComputedStyle(primaryTextRef.current).lineHeight;
      setComputedTextHeight(parseFloat(lineHeight));
      setComputedTextLength(primaryTextLength);
    }
  }, [primaryText, primaryTextRef, secondaryTextRef]);

  return (
    <svg
      width={textWidthPercentage}
      height={textHeightPercentage}
      viewBox={`0 0 ${computedTextLength} ${computedTextHeight}`}
    >
      <text ref={ref} x='50%' y='50%' dominantBaseline={textDominantBaseline} textAnchor='middle'>
        <tspan ref={primaryTextRef} x='50%' fontSize='100%' className={primaryTextClassName}>
          {primaryText}
        </tspan>
      </text>
    </svg>
  );
};

export default React.forwardRef(TextSVG);
