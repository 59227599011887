import { useEffect } from 'react';
import App from '@/App';
import { store } from '@/stores';
import { getSiteConfig } from '@/stores/siteConfigs';
import { Provider as DataProvider } from 'react-redux';
import TopicProvider from './TopicProvider';
import { isEmpty } from '@/common';
import { useParams } from 'react-router-dom';
import { getDeviceConfig } from '@/stores/sites/deviceConfigs';
import { getDashboardConfig } from '@/stores/sites/dashboardConfigs';
import { getSiteData } from '@/stores/sites/actions';

const getDashboardBySerialNumber = async (siteId: string, serialNumber: string): Promise<void> => {
  window.logger.info(`getDeviceConfig ${siteId} | ${serialNumber}`);

  const deviceConfig = await store.dispatch(getDeviceConfig({ siteId, device: serialNumber })).unwrap();

  window.logger.info(`deviceConfig ${siteId} | ${serialNumber} | ${JSON.stringify(deviceConfig)}`);

  if (!isEmpty(deviceConfig) && !isEmpty(deviceConfig.dashboard)) {
    store.dispatch(getDashboardConfig({ siteId, dashboardId: deviceConfig.dashboard }));
  }
};

// Bootstrap the application
// The logic in this file is similar to the logic in src/bootstrap/DashboardBrowser.tsx except for the following:
// - Kiosk is used for the path 'kiosk/'
// - Kiosk requires a serialNumber instead of a dashboardId
// - Kiosk will get the dashboardId from the deviceConfig
const Kiosk = (): JSX.Element => {
  const { siteId, serialNumber, clientId } = useParams();
  // Reset state & load dashboard config from API
  useEffect(() => {
    window.logger.info(`Bootstrap ${siteId} | ${serialNumber}`);

    if (isEmpty(siteId) || isEmpty(serialNumber)) {
      window.logger.error('Invalid siteId, or serialNumber');
      return;
    }

    window.logger.info(`getSiteConfig ${siteId} ${clientId}`);

    // TODO: Remove this line after migrating all sites to the new 'store' table
    store.dispatch(getSiteConfig({ siteId: siteId! }));

    // Load site target data (from new store table)
    store.dispatch(getSiteData({ clientId: clientId ?? '', siteId: siteId! }));

    void getDashboardBySerialNumber(siteId!, serialNumber!);

    return () => {
      window.logger.debug(`State cleanup ${siteId}`);
      store.dispatch({ type: 'RESET_STATE', payload: undefined });
    };
  }, [siteId, serialNumber]);

  return (
    <DataProvider store={store}>
      <TopicProvider>
        <App />
      </TopicProvider>
    </DataProvider>
  );
};

export default Kiosk;
