import { Gauge } from '@/widgets/components/gauge';
import { getMomentTimeZone, useAppSelector } from '@/common';
import { selectMostDepartureCount } from '@/stores/departures';
import { WidgetWrapper } from '../components/wrapper';
import { useMemo } from 'react';
import { selectSiteTimeZone } from '@/stores/siteConfigs';
import { StandardWidgetProps } from '@/types';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'mostDeparturesTracker',
  displayName: 'Most Departures Tracker',
  description: 'Placeholder description',
};

export interface MostDepartureCountProps extends StandardWidgetProps {}

const MostDeparturesTracker = (props: MostDepartureCountProps & { children?: JSX.Element }): JSX.Element => {
  const { fromTime, toTime, departCars } = useAppSelector(selectMostDepartureCount);
  const timezone = useAppSelector(selectSiteTimeZone);
  const timestamp = useMemo(() => {
    if (!fromTime || !toTime) {
      return '';
    }
    const formatTime = (timeString: string) => {
      const time = getMomentTimeZone(timeString, timezone);
      const hours = time.hour;
      const minutes = time.minute;
      return `${hours}:${minutes}`;
    };

    const fromTimeString = formatTime(fromTime);
    const toTimeString = formatTime(toTime);

    return `${fromTimeString} - ${toTimeString}`;
  }, [fromTime, toTime]);

  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Gauge maxValue={600} metricValue={departCars} targetValue={500} secondaryText={timestamp} />
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

export default MostDeparturesTracker;
