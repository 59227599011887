import { useEffect, useState } from 'react';
import { isEmpty } from '@/common';
import { type RoiLabel } from '@/widgets/leadCarTimer';
import { NotConfiguredBox } from '@/widgets/components/box';

const Validation = (props: RoiLabel & { children: JSX.Element }) => {
  const { roiId, children } = props;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(isEmpty(roiId));
  }, [roiId]);

  return hasError ? <NotConfiguredBox /> : children;
};

export default Validation;
