// Widget wrapper is the top level wrapper for a widget.
// It provides the following: widget content, widget title, widget size, widget padding.

import { getComputedStyle } from '@/common';
import { useLayoutEffect, useRef, useState } from 'react';
import {
  WidgetContentContext,
  type WidgetContentContextValue,
  defaultWidgetContentContextValue,
} from './widgetContentContext';
import classNames from 'classnames';

export interface StandardWidgetWrapperProps {
  title?: string;
  className?: string;
  children: JSX.Element | JSX.Element[];
}

export const WidgetWrapper = (props: StandardWidgetWrapperProps): JSX.Element => {
  const { title, children, className } = props;
  const stageCanvasRef = useRef<HTMLDivElement>(null);
  const [widgetRenderContextValue, setWidgetRenderContextValue] = useState<WidgetContentContextValue>(
    defaultWidgetContentContextValue,
  );

  useLayoutEffect(() => {
    if (stageCanvasRef?.current !== null) {
      const { height, width, paddingTop, paddingRight, paddingBottom, paddingLeft } = getComputedStyle(
        stageCanvasRef.current,
      );

      setWidgetRenderContextValue({
        size: { width: parseFloat(width), height: parseFloat(height) },
        padding: {
          top: parseFloat(paddingTop),
          right: parseFloat(paddingRight),
          bottom: parseFloat(paddingBottom),
          left: parseFloat(paddingLeft),
        },
      });
    }
  }, [stageCanvasRef?.current]);

  return (
    <WidgetContentContext.Provider value={widgetRenderContextValue}>
      <div className={classNames('grid grid-cols-1 h-full', className ?? '')}>
        <div className='col-span-1 flex flex-col'>
          {/* Widget content */}
          <div ref={stageCanvasRef} className='flex relative flex-auto flex-col'>
            {children}
          </div>

          {/* Widget title */}
          {title !== undefined && (
            <div className='pt-5 flex items-center justify-center text-[2.3vh] text-emphasis-base font-medium'>
              {title ?? ''}
            </div>
          )}
        </div>
      </div>
    </WidgetContentContext.Provider>
  );
};
