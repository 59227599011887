import { useEffect, useState } from 'react';
import { type PerfectCarCountProps } from '@/widgets/perfectCarCount';
import { NotConfiguredBubble } from '@/widgets/components/bubble';

const Validation = (props: PerfectCarCountProps & { children: JSX.Element }) => {
  const { averageIds, children } = props;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(averageIds.length === 0);
  }, [averageIds]);

  return hasError ? <NotConfiguredBubble /> : children;
};

export default Validation;
