import React, { type ReactNode, useRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { addMilliseconds } from 'date-fns';

interface ScrollingViewportProps {
  children: ReactNode;
  width: number;
  height: number;
  xDomain: Date[];
  xScale: any;
  tickPeriod: number;
}

const ScrollingViewport = (props: ScrollingViewportProps): JSX.Element => {
  const { children, xDomain, tickPeriod, xScale, width, height } = props;

  const [springProps, api] = useSpring(() => ({ x: 0 }));

  const prev = useRef<Date | undefined>();
  if (prev.current !== xDomain[0]) {
    prev.current = xDomain[0];

    api.set({ x: 0 }); // Reset when xDomain changes
    api.start({
      x: xScale(addMilliseconds(xDomain[0], -tickPeriod)),
      config: { duration: tickPeriod },
    }); // Animate to the next position
  }

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} overflow='hidden'>
      <animated.g transform={springProps.x.to((value: number) => `translate(${value}, 0)`)}>{children}</animated.g>
    </svg>
  );
};

export default ScrollingViewport;
