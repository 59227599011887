import subMinutes from 'date-fns/subMinutes';
import addMinutes from 'date-fns/addMinutes';
import { extractDate, getMomentTimeZone, getThisMomentTargetOrLastAvailable } from '@/common';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import parse from 'date-fns/parse';
import addHours from 'date-fns/addHours';
import startOfHour from 'date-fns/startOfHour';
import subHours from 'date-fns/subHours';
import { type SiteTarget, type Target, TargetGranularity, type TargetType } from '@/stores/sites/types';
import { getThisMomentTarget } from '@/stores/sites/utils';

export enum AggregationMethod {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
}

export interface TimeSeriesBarChartModel {
  dateTime: string;
  value: number;
  target?: number;
  isCurrentData: boolean;
}

export interface TimeSeriesBarChartRenderModel extends TimeSeriesBarChartModel {
  date: Date;
  hour: number;
  minute: number;
}

const isBetween = (date: Date, start: Date, end: Date): boolean => date >= start && date < end;

export const filterAndDecorateData = (
  data: Array<{ timestamp: string; value: number }>,
  timeZone: string,
  hoursToDisplay: number,
  targetType: TargetType,
  targets?: Record<string, number>,
  siteTarget?: SiteTarget,
  clientTargets?: Target[],
): TimeSeriesBarChartModel[] => {
  const graphMoment = new Date();
  const lastDisplayMoment = startOfHour(graphMoment);
  const startOfGraphMoment = subHours(lastDisplayMoment, hoursToDisplay - 1);
  const endOfGraphMoment = addHours(lastDisplayMoment, 1);

  const result = [];
  let index = 0;
  let currentDate = startOfGraphMoment;

  while (currentDate <= endOfGraphMoment) {
    const currentMoment = getMomentTimeZone(currentDate.toISOString(), timeZone);
    const foundData = data.find((item) => item.timestamp === currentDate.toISOString()) ?? {
      timestamp: currentDate.toISOString(),
      value: 0,
    };

    // TODO: The 'getThisMomentTargetOrLastAvailable' can be removed after migrating all site to the new 'store' table
    const target =
      siteTarget !== undefined
        ? getThisMomentTarget(currentMoment, targetType, siteTarget.targets, clientTargets ?? [])
        : targets === undefined
          ? undefined
          : getThisMomentTargetOrLastAvailable(currentMoment, targets);

    const granularity =
      siteTarget?.targets.find((item) => item.type === targetType)?.granularity ?? TargetGranularity.Fifteen;

    if (granularity === TargetGranularity.Fixed) {
      window.logger.error('Target granularity is not supported');
    }

    const momentAtStartOfBar = addMinutes(startOfGraphMoment, index * Number(granularity));
    const momentAtEndOfBar = addMinutes(momentAtStartOfBar, Number(granularity));
    const isCurrentData = isBetween(graphMoment, momentAtStartOfBar, momentAtEndOfBar);

    result.push({ dateTime: currentMoment.dateTimeISO9075, value: foundData.value, target, isCurrentData });
    currentDate = addMinutes(currentDate, Number(granularity));
    index++;
  }

  return result;
};

export const getTimeSeries = (data: TimeSeriesBarChartModel[]): TimeSeriesBarChartRenderModel[] => {
  const renderData = data.map((item) => {
    const date = parse(item.dateTime, 'yyyy-MM-dd HH:mm:ss', new Date());
    const { hour, minute } = extractDate(date);

    return {
      ...item,
      date,
      hour,
      minute,
    };
  });

  // Add an empty object to the beginning of the array to draw the first reference line (far left)
  const firstSeries = renderData[0];
  const seriesBefore = subMinutes(firstSeries.date, 7.5); // 7.5 is haf of the interval (15min)
  const { hour, minute } = extractDate(seriesBefore);
  renderData.unshift({ ...firstSeries, date: seriesBefore, hour, minute, value: 0 });

  // Add an emtpy object to the end of the array to ensure target line cover the last bar
  const lastSeries = renderData[renderData.length - 1];
  const seriesAfter = addMinutes(lastSeries.date, 7.5); // 7.5 is haf of the interval (15min)
  const { hour: h, minute: m } = extractDate(seriesAfter);
  renderData.push({ ...lastSeries, date: seriesAfter, hour: h, minute: m, value: 0 });

  return renderData;
};

export const getAggregation = (
  data: TimeSeriesBarChartRenderModel[],
  aggregationMethod: AggregationMethod,
): Record<string, number> => {
  const timerBlockData = groupBy(data, (item: TimeSeriesBarChartRenderModel) => item.hour);
  const aggregatedData = reduce(
    timerBlockData,
    (result, value, key) => {
      const sum = value.reduce((acc, item) => acc + item.value, 0);

      const v = value.filter((item) => item.value > 0);

      const average = v.length > 0 ? Math.round(sum / v.length) : 0;

      result[key] = aggregationMethod === AggregationMethod.SUM ? sum : average;

      return result;
    },
    {} as Record<string, number>,
  );

  return aggregatedData;
};
