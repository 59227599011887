import { type DailyRollingTarget } from '@/types';

export interface WidgetIdentifier {
  siteId: string;
  dashboardId?: string;
  slot: string;
  name: string;
  templateId?: string;
}

export interface TargetInterval {
  targetCounts: DailyRollingTarget;
  targetRates: DailyRollingTarget;
}

export interface SiteConfig {
  name: string;
  siteId: string;
  timezone: string;
  targetIntervals: TargetInterval;
  targetTimes: Record<string, number>;
}

export interface SiteConfigState {
  siteConfig?: SiteConfig;
  isLoadingConifg?: boolean;
}
