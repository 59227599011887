import { area } from '@visx/shape';
import { type ScaleTime, type ScaleLinear, type NumberValue } from 'd3';
import { Chronology, type DepartureDatum } from '../types';
import { animated, useSpring } from '@react-spring/web';
import { useMemo } from 'react';
import classNames from 'classnames';

export interface DeparturesSegmentProps {
  departures: DepartureDatum[];
  xScale: ScaleTime<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  height: number;
  segmentType: Chronology;
}

export const DeparturesSegment = (props: DeparturesSegmentProps): JSX.Element => {
  const { departures, xScale, yScale, height, segmentType } = props;

  if (departures.length === 0) {
    return <></>;
  }

  // Convert data to d3 terminology
  const { xStart, width } = useMemo(() => {
    const xStart = xScale(departures[0].date);
    const xEnd = xScale(departures[1].date);
    const width = xEnd - xStart;

    return { xStart, xEnd, width };
  }, [xScale, departures]);

  const areaSpringProps = useSpring({
    from: { values: departures.map((d) => 0) },
    to: { values: departures.map((d) => d.value) },
    config: { mass: 3, friction: 50, tension: 200 },
  });

  const areaBuilder = useMemo(
    () =>
      area<NumberValue>({
        x: (d, i) => i,
        y1: (d) => height,
        y0: (d, i) => yScale(d),
      }),
    [height, yScale],
  );

  const animatedPath = areaSpringProps.values.to((...interpolatedValues: NumberValue[]) => {
    return areaBuilder(interpolatedValues) ?? '';
  });

  const groupWidthSpring = useSpring({
    to: { width },
    config: { mass: 1, friction: 50, tension: 400 },
  });

  const groupPosSpring = useSpring({
    to: { xStart },
    config: { mass: 1, friction: 50, tension: 400 },
  });

  return (
    <animated.g transform={groupPosSpring.xStart.to((value) => `translate(${value}, ${0})`)}>
      <animated.g transform={groupWidthSpring.width.to((value) => `scale(${value}, 1)`)}>
        <animated.path
          d={animatedPath}
          className={classNames('fill-indicator-neutral', { 'opacity-20': segmentType === Chronology.PROJECTED })}
        />
      </animated.g>
    </animated.g>
  );
};
