import { parseISO, isAfter } from 'date-fns';

type ObjectWithTimestamp<T> = {
  timestamp: string;
} & T;

export const filterTimestampedObjectsByDate = <T>(
  liveEvents: Array<ObjectWithTimestamp<T>>,
  earliestDate: Date,
): Array<ObjectWithTimestamp<T>> => {
  const currentLiveEvents = liveEvents.filter((liveEvent: ObjectWithTimestamp<T>) => {
    const departEventDate = parseISO(liveEvent.timestamp);

    return isAfter(departEventDate, earliestDate);
  });

  return currentLiveEvents;
};
