import { type Size } from '@/types';
import { createContext } from 'react';

export interface WidgetContentContextValue {
  size: Size;
  renderSize: Size;
  padding: { top: number; right: number; bottom: number; left: number };
}

export const defaultWidgetContentContextValue: WidgetContentContextValue = {
  size: { width: 0, height: 0 },
  renderSize: { width: 0, height: 0 },
  padding: { top: 0, right: 0, bottom: 0, left: 0 },
};

export const WidgetContentContext = createContext<WidgetContentContextValue>(defaultWidgetContentContextValue);
