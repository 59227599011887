// Custom curve to give rounded slopes to the step function. Based off the standard d3-shape curves step and bump
// https://github.com/d3/d3-shape/blob/main/src/curve/step.js
// https://github.com/d3/d3-shape/blob/main/src/curve/bump.js

import { type Path, type CurveFactory } from 'd3';

class SlopedStep {
  // Private properties
  private readonly _context: CanvasRenderingContext2D;
  private readonly _slopeFraction: number;
  private _t: number;
  private _line: number;
  private _x: number;
  private _y: number;
  private _point: number;

  constructor(context: any, slopeFraction: number) {
    this._context = context; // Canvas context, for line drawing
    this._t = 0.5; // Interpolation fraction
    this._slopeFraction = slopeFraction; // What fraction of the horizontal distance of a step is given to the slope? slopeFraction 0 makes this curve the same as 'step'. Slope fraction 0.5 makes this curve the same as 'bump'
    this._x = NaN; // The x value of the previous point
    this._y = NaN; // The y value of the previous point
    this._point = 0; // The point state
    this._line = NaN; // The line state
  }

  areaStart(): void {
    this._line = 0;
  }

  areaEnd(): void {
    this._line = NaN;
  }

  lineStart(): void {
    this._x = this._y = NaN;
    this._point = 0;
  }

  lineEnd(): void {
    // Check conditions for line rendering and closing the path
    if (this._t > 0 && this._t < 1 && this._point === 2) this._context.lineTo(this._x, this._y);
    if ((this._line !== 0 && !isNaN(this._line)) || (this._line !== 0 && this._point === 1)) this._context.closePath();
    if (this._line >= 0) {
      this._t = 1 - this._t;
      this._line = 1 - this._line;
    }
  }

  // Takes the x and y of the current point
  point(x: number, y: number): void {
    // Convert x and y to numbers
    x = +x;
    y = +y;

    switch (this._point) {
      case 0:
        // Move to the starting point for the step
        this._point = 1;
        this._line !== 0 && !isNaN(this._line) ? this._context.lineTo(x, y) : this._context.moveTo(x, y);
        break;
      case 1:
        this._point = 2;
        this.drawCurves(x, y);
        break;
      default: {
        this.drawCurves(x, y);
        break;
      }
    }
    // Update x and y
    this._x = x;
    this._y = y;
  }

  drawCurves(x: number, y: number): void {
    // x - _x (i.e, previous x) gives the width of one step.
    const xN = Math.abs(x - this._x) * this._slopeFraction; // Find the horizontal distance of one slope

    // Interpolate and render the step
    const x1 = this._x * (1 - this._t) + x * this._t;
    this._context.lineTo(x1 - xN, this._y); // Horizontal line. Note: Where this line ends is the first point in the bezier curve.

    const x0 = x1 - xN; // Starting x coord
    const y0 = this._y; // Starting y coord
    this._context.bezierCurveTo((x0 + x1 + xN) / 2, y0, (x0 + x1 + xN) / 2, y, x1 + xN, y); // Cubic bezier from x1 - xN to x1 + xN, and _y to y
  }
}

// Function to create a step with default slopeWidth value (0.125)
export const slopedStep: CurveFactory = (context: CanvasRenderingContext2D | Path): SlopedStep => {
  return new SlopedStep(context, 0.125);
};
