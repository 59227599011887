import { animated, useSpring } from '@react-spring/web';

interface TransparentOverlayProps {
  isShowing: boolean;
}
const TransparentOverlay = (props: TransparentOverlayProps): JSX.Element => {
  const { isShowing } = props;

  const spring = useSpring({
    opacity: isShowing ? 0.75 : 0,
    config: { duration: 600 },
  });

  return (
    <animated.div
      className='h-full w-full bg-dashboard-background'
      style={{ ...spring, position: 'absolute' }}
    ></animated.div>
  );
};

export default TransparentOverlay;
