import { type TopicToHandlersMap } from '@/services/iot';
import {
  type ActiveDepartureRateTopicMessage,
  addLiveDepartureEvent,
  type CurrentDepartureCarCount,
  type CurrentDepartureRate,
  type HistoricalDepartureCarCountState,
  type HistoricalDepartureRateState,
  type LiveDepartureEvent,
  type MostDepartureCount,
  setActiveDepartureRate,
  setCurrentDepartureCarCount,
  setCurrentDepartureRate,
  setHistoricalDepartureCarCount,
  setHistoricalDepartureRate,
  setMostDepartureCount,
} from '@/stores/departures';
import { store } from '@/stores';
import { type RoiModel, setRoi } from '@/stores/rois';
import { type FunctionalZonesState, setFunctionalZones } from '@/stores/queues';
import { type RollingEvent, RollingType, setRollingAverages } from '@/stores/averages';
import { type OnPaceEvent, setOnPace } from '@/stores/onPace';
import { type DeviceConfig, setDeviceConfig } from '@/stores/sites/deviceConfigs';
import { type Dashboard, setDashboardConfig } from '@/stores/sites/dashboardConfigs';
import { setGeneralSiteConfig, type SiteConfig } from '@/stores/sites/generalConfigs';
import { setTargetConfig } from '@/stores/sites/targetConfigs';
import { type NotificationEvent, setNotification } from '@/stores/notifications';
import { type SiteTarget } from '@/stores/sites/types';
import { LeaderboardMetric, type LeaderboardModel, setLeaderboardByMetric } from '@/stores/leaderboard';

const defaultTopicToHandlersMap: TopicToHandlersMap = {
  '/eyeq/device-updated/${siteId}': [
    (data: unknown) => {
      store.dispatch(setDeviceConfig(data as DeviceConfig));
    },
  ],
  '/eyeq/dashboard-updated/${siteId}': [
    (data: unknown) => {
      store.dispatch(setDashboardConfig(data as Dashboard));
    },
  ],
  '/eyeq/store-updated/${siteId}': [
    (data: unknown) => {
      store.dispatch(setGeneralSiteConfig(data as SiteConfig));
      store.dispatch(setTargetConfig(data as SiteTarget));
    },
  ],
  '/eyeq/roicount/${siteId}': [
    (data: unknown) => {
      store.dispatch(setRoi(data as RoiModel));
    },
  ],
  '/eyeq/depart-rate-15min/${siteId}': [
    (data: unknown) => {
      store.dispatch(setCurrentDepartureRate(data as CurrentDepartureRate));
    },
  ],
  '/eyeq/depart-count-15min/${siteId}': [
    (data: unknown) => {
      store.dispatch(setCurrentDepartureCarCount(data as CurrentDepartureCarCount));
    },
  ],
  '/eyeq/bar-chart-depart-count-15min/${siteId}': [
    (data: unknown) => {
      store.dispatch(setHistoricalDepartureCarCount(data as HistoricalDepartureCarCountState));
    },
  ],
  '/eyeq/bar-chart-depart-rate-15min/${siteId}': [
    (data: unknown) => {
      store.dispatch(setHistoricalDepartureRate(data as HistoricalDepartureRateState));
    },
  ],
  '/eyeq/depart-timeline-60min/${siteId}': [],
  '/eyeq/departure/${siteId}': [
    (data: unknown) => {
      store.dispatch(addLiveDepartureEvent(data as LiveDepartureEvent));
    },
  ],
  '/eyeq/best-60-minute-count/${siteId}': [
    (data: unknown) => {
      store.dispatch(setMostDepartureCount(data as MostDepartureCount));
    },
  ],
  '/eyeq/danger-zones/${siteId}': [
    (data: unknown) => {
      store.dispatch(setFunctionalZones(data as FunctionalZonesState));
    },
  ],
  '/eyeq/active-departure-rate/${siteId}': [
    (data: unknown) => {
      store.dispatch(setActiveDepartureRate(data as ActiveDepartureRateTopicMessage));
    },
  ],
  '/eyeq/rolling/${siteId}': [
    (data: unknown) => {
      store.dispatch(
        setRollingAverages({ data: data as RollingEvent, rollingType: RollingType.ROLLING_60_MINUTES_AVERAGES }),
      );
    },
  ],
  '/eyeq/rolling-15-mins/${siteId}': [
    (data: unknown) => {
      store.dispatch(
        setRollingAverages({ data: data as RollingEvent, rollingType: RollingType.ROLLING_15_MINUTES_AVERAGES }),
      );
    },
  ],
  '/eyeq/onpace/${siteId}': [
    (data: unknown) => {
      store.dispatch(setOnPace(data as OnPaceEvent));
    },
  ],
  '/eyeq/notification/${siteId}': [
    (data: unknown) => {
      store.dispatch(setNotification(data as NotificationEvent));
    },
  ],
  '/eyeq/leaderboard/perfect-car-count': [
    (data: unknown) => {
      store.dispatch(
        setLeaderboardByMetric({
          metric: LeaderboardMetric.PERFECT_CAR_COUNT,
          data: data as LeaderboardModel[],
        }),
      );
    },
  ],
  '/eyeq/leaderboard/oepe': [
    (data: unknown) => {
      store.dispatch(setLeaderboardByMetric({ metric: LeaderboardMetric.OEPE, data: data as LeaderboardModel[] }));
    },
  ],
};

export default defaultTopicToHandlersMap;
