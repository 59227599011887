import { useEffect } from 'react';
import App from '@/App';
import { store } from '@/stores';
import preloadedState from '../stores/preloadedState';
import { Provider as DataProvider } from 'react-redux';

// Bootstrap the application
const BrowserDev = (): JSX.Element => {
  // Reset state & use sample config
  useEffect(() => {
    store.dispatch({ type: 'RESET_STATE', payload: preloadedState });

    return () => {
      window.logger.debug(`State cleanup`);
      store.dispatch({ type: 'RESET_STATE', payload: undefined });
    };
  }, []);

  return (
    <DataProvider store={store}>
      <App />
    </DataProvider>
  );
};

export default BrowserDev;
