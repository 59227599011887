import {
  type ActionReducerMapBuilder,
  createAsyncThunk,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import createHttp from '@/services/http';
import get from 'lodash/get';
import { isEmpty } from '@/common';
import { REQUEST_STATUS, type State } from '@/stores/sites/types';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '@/stores/sites/utils';
import type { RootState, Selector } from '@/stores';

export interface DeviceConfig {
  serialNumber: string;
  siteId: string;
  name: string;
  theme: string;
  dashboard: string;
}

const getDeviceConfigQuery = `
  query getDeviceConfig($siteId: String, $device: String) {
    getDevices(siteId: $siteId, serialNumber: $device) {
      serialNumber,
      siteId,
      name,
      theme,
      dashboard
    }
  }`;

export const getDeviceConfig = createAsyncThunk<DeviceConfig | undefined, { siteId: string; device: string }>(
  'deviceConfig/getDeviceConfig',
  async ({ siteId, device }): Promise<DeviceConfig | undefined> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getDeviceConfigQuery,
      variables: {
        siteId,
        device,
      },
    });

    const deviceData = get(response, 'data.data.getDevices', undefined);

    if (isEmpty(deviceData) || deviceData.length === 0) {
      window.logger.error(`No device found for siteId ${siteId} with serial number ${device}`);
      return undefined;
    }

    if (deviceData.length > 1) {
      window.logger.warn(
        `Device ${device} for siteId ${siteId} has more than one configuration. Return first configuration ${deviceData[0]}.`,
      );
    }

    return deviceData[0] as DeviceConfig;
  },
);

export interface DeviceConfigState extends State<DeviceConfig | undefined> {}

const initialState: DeviceConfigState = {
  data: undefined,
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const deviceConfigSlice = createSlice({
  name: 'deviceConfigs',
  initialState,
  reducers: {
    setDeviceConfig: (state, { payload }: PayloadAction<DeviceConfig>) => {
      // Ensure that the device config is only updated if the siteId and serialNumber match
      if (state.data?.siteId === payload.siteId && state.data?.serialNumber === payload.serialNumber) {
        state.data = payload;
      }
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DeviceConfigState>) => {
    builder
      .addCase(getDeviceConfig.pending, pendingReducer)
      .addCase(
        getDeviceConfig.fulfilled,
        fulfilledReducer((state, action) => (state.data = action.payload as DeviceConfig)),
      )
      .addCase(getDeviceConfig.rejected, rejectedReducer);
  },
});

export default deviceConfigSlice.reducer;

export const { setDeviceConfig } = deviceConfigSlice.actions;

export const selectDeviceConfig = (state: RootState): DeviceConfig | undefined => state.deviceConfigs.data;

export const selectDeviceTheme = (serialNumber?: string): Selector<string> =>
  createSelector(selectDeviceConfig, (device) => {
    if (serialNumber !== undefined && serialNumber !== '' && device?.serialNumber === serialNumber) {
      return device.theme;
    }
    return 'theme-light';
  });
