import { useEffect } from 'react';
import App from '@/App';
import { store } from '@/stores';
import { getSiteConfig } from '@/stores/siteConfigs';
import { Provider as DataProvider } from 'react-redux';
import TopicProvider from './TopicProvider';
import { isEmpty } from '@/common';
import { useParams } from 'react-router-dom';
import { getDashboardConfig } from '@/stores/sites/dashboardConfigs';
import { getSiteData } from '@/stores/sites/actions';

// Bootstrap the application
// The logic in this file is similar to the logic in src/bootstrap/DashboardBrowser.tsx except for the following:
// - Mosaic is used for the path 'mosaic/'
// - Mosaic doesn't require a dashboardId instead it uses dashboard index 0
const Mosaic = (): JSX.Element => {
  const { siteId, clientId } = useParams();
  // Reset state & load dashboard config from API
  useEffect(() => {
    window.logger.info(`Bootstrap ${siteId}`);

    if (isEmpty(siteId)) {
      window.logger.error('Invalid siteId');
      return;
    }

    window.logger.info(`getSiteConfig ${siteId} ${clientId}`);

    // TODO: Remove this line after migrating all sites to the new 'store' table
    store.dispatch(getSiteConfig({ siteId: siteId! }));

    // Load site target data (from new store table)
    store.dispatch(getSiteData({ clientId: clientId ?? '', siteId: siteId! }));

    store.dispatch(getDashboardConfig({ siteId: siteId! }));

    return () => {
      window.logger.debug(`State cleanup ${siteId}`);
      store.dispatch({ type: 'RESET_STATE', payload: undefined });
    };
  }, [siteId]);

  return (
    <DataProvider store={store}>
      <TopicProvider>
        <App />
      </TopicProvider>
    </DataProvider>
  );
};

export default Mosaic;
