import type { SerializedError } from '@reduxjs/toolkit';

export enum REQUEST_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface State<T> {
  data: T;
  requestStatus: REQUEST_STATUS;
  error: SerializedError | null;
}

export enum TargetType {
  DepartureCount = 'DEPARTURE_COUNT',
  DepartureRate = 'DEPARTURE_RATE',
  BoomGateTime = 'BOOM_GATE_TIME',
  OrderTime = 'ORDER_TIME',
  PayTime = 'PAY_TIME',
  PickupTime = 'PICKUP_TIME',
  ParkedBayTime = 'PARKED_BAY_TIME',
  PullForwardTime = 'PULL_FORWARD_TIME',
  TotalExperienceTime = 'TOTAL_EXPERIENCE_TIME',
  OefdTime = 'OEFD_TIME',
}

export enum TargetGranularity {
  Fixed = 'FIXED',
  Five = '5',
  Ten = '10',
  Fifteen = '15',
  Twenty = '20',
  Thirty = '30',
  Sixty = '60',
}

export enum DayOfWeek {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export type TargetInterval = Record<DayOfWeek, Record<string, number | null>>;

export interface Target {
  type: TargetType;
  granularity: TargetGranularity;
  adjustedInterval?: TargetInterval;
  fixed?: number;
}

export interface SiteTarget {
  siteId: string;
  targets: Target[];
}
