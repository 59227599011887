import { useAppSelector } from '@/common';
import { type RollingType, selectRollingAverages } from '@/stores/averages';
import { selectOldestVehicleEntryTime, selectVehicleCount } from '@/stores/rois';
import get from 'lodash/get';
import { useMemo } from 'react';
import type { TargetType } from '@/stores/sites/types';

export interface TimeAtWindowsData {
  roiId: string;
  vehicleCount: number;
  targetId: string;
  targetType: TargetType;
  label?: string;
  timestamp?: string;
}

export const useTimeAtWindows = (
  roiLabels: Array<{
    roiId: string;
    targetId: string;
    targetType: TargetType;
    label?: string;
  }>,
): TimeAtWindowsData[] => {
  const roiIds = useMemo(() => roiLabels.map((roiLabel) => roiLabel.roiId), [roiLabels]);
  const roisOldestVehicle: Record<string, string> = useAppSelector(selectOldestVehicleEntryTime(roiIds));
  const roisVehicleCount: Record<string, number> = useAppSelector(selectVehicleCount(roiIds));

  return useMemo(
    () =>
      roiLabels.map((roiLabel): TimeAtWindowsData => {
        const { label, roiId, targetId, targetType } = roiLabel;
        const timestamp = roisOldestVehicle[roiId] ?? undefined;
        const vehicleCount = roisVehicleCount[roiId] ?? 0;

        return {
          timestamp,
          label,
          roiId,
          targetId,
          targetType,
          vehicleCount,
        };
      }),
    [roiLabels, roisOldestVehicle, roisVehicleCount],
  );
};

export interface ZoneAverageData {
  averageId: string;
  label?: string;
  header?: string;
  average: number;
}

export const useZoneAverages = (
  zones: Array<{
    averageId: string;
    label?: string;
    header?: string;
  }>,
  rollingType: RollingType,
): ZoneAverageData[] => {
  const averages = useAppSelector(selectRollingAverages(rollingType));

  return useMemo(
    () =>
      zones.map((zone) => {
        const average = get(averages, zone.averageId, 0);

        return {
          ...zone,
          average,
        };
      }),
    [zones, averages],
  );
};
