import { ParentSize } from '@visx/responsive';
import HorizontalRankingBarChart, { type HorizontalBarChartProps } from './HorizontalRankingBarChart';

export interface WrappedHorizontalRankingBarChartProps extends Omit<HorizontalBarChartProps, 'width' | 'height'> {}

const WrappedHorizontalRankingBarChart = (props: WrappedHorizontalRankingBarChartProps): JSX.Element => {
  return (
    <ParentSize>
      {({ width, height }) => <HorizontalRankingBarChart {...props} width={width} height={height} />}
    </ParentSize>
  );
};

export default WrappedHorizontalRankingBarChart;
export { type SiteMetricModel } from './utils';
