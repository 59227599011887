import { useMemo } from 'react';
import classNames from 'classnames';
import WidgetMetricWrapper, { type StandardMetricWrapperProps } from '../wrapper/WidgetMetricWrapper';

interface CardProps extends Omit<StandardMetricWrapperProps, 'children'> {
  targetValue: number;
  invert?: boolean; // default higher value is better
  cardWrapperClassName?: string;
}

export const Card = (props: CardProps): JSX.Element => {
  const { cardWrapperClassName, metricValue, invert, targetValue } = props;

  const metricClassName = useMemo(() => {
    const doesMetricMeetTarget = invert ? metricValue <= targetValue : metricValue >= targetValue;
    return metricValue === 0 ? 'bg-indicator-empty' : doesMetricMeetTarget ? 'bg-indicator-okay' : 'bg-indicator-alert';
  }, [invert, targetValue, metricValue]);

  return (
    <WidgetMetricWrapper
      {...props}
      primaryTextClassName='fill-emphasis-overlay font-semibold'
      secondaryTextClassName='text-emphasis-overlay font-semibold'
    >
      <div className={classNames('h-full w-full', cardWrapperClassName, metricClassName)} />
    </WidgetMetricWrapper>
  );
};
