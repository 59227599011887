import { useAppSelector } from '@/common';
import parseTemplate from '@/pages/templateParser';
import { selectIsLoadingConfig } from '@/stores/siteConfigs';
import DashboardFooter from '@/widgets/dashboardFooter';
import DashboardHeader from '@/widgets/dashboardHeader';
import Placeholder from '@/widgets/placeholder';
import Spinner from '@/widgets/spinner';
import React, { useMemo } from 'react';
import { selectDashboard, type Dashboard as DashboardConfig } from '@/stores/sites/dashboardConfigs';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

interface DashboardOptions extends React.HTMLAttributes<HTMLDivElement> {
  editable?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const Dashboard = (props: DashboardOptions): JSX.Element => {
  const { editable = false, children, className, ...rest } = props;
  const location = useLocation();
  const currentPath = location.pathname;

  const dashboardConfig: DashboardConfig | undefined = useAppSelector(selectDashboard);
  const isLoadingConfig = useAppSelector(selectIsLoadingConfig);
  const dashboard = useMemo(() => {
    if (dashboardConfig === undefined) {
      return undefined;
    }

    return parseTemplate(dashboardConfig.template, editable, !currentPath.includes('templates'));
  }, [dashboardConfig?.template]);

  return (
    <div className={classNames('h-full w-full', className)} {...rest}>
      <div className='h-[6vh]'>
        <DashboardHeader />
      </div>
      {/* 9vh = header height + footer height */}
      {/* Use class 'unset-aspect-ratio' to unset 16/9 ratio for existing dashboard. This class can be safely removed once all dashboards are migrated */}
      <div style={{ height: `calc(-9vh + 100%)` }} className='flex justify-center p-[30px] unset-aspect-ratio'>
        {isLoadingConfig !== undefined && isLoadingConfig ? (
          <div className='h-full w-full'>
            <Spinner />
          </div>
        ) : dashboardConfig === undefined ? (
          <div className='h-full w-full'>
            <Placeholder message='Store configuration is in progress...' />
          </div>
        ) : (
          dashboard
        )}
      </div>
      <footer className='bottom-0 w-full pb-2 pl-2 h-[2.5vh]'>
        <DashboardFooter />
      </footer>

      {children}
    </div>
  );
};

export default Dashboard;
