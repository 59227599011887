import { useAppSelector } from '@/common';

import {
  selectNotifications,
  type Notification,
  removeNotification,
  removeExpiredNotifications,
} from '@/stores/notifications';
import CenteringList from './components/CenteringList';
import { useEffect, useMemo } from 'react';
import { ParentSize } from '@visx/responsive';
import { store } from '@/stores';
import { selectDashboard, type Dashboard as DashboardConfig } from '@/stores/sites/dashboardConfigs';

const NotificationsContainer = (): JSX.Element => {
  const notifications: Notification[] = useAppSelector(selectNotifications);

  const dashboardConfig: DashboardConfig | undefined = useAppSelector(selectDashboard);

  const notificationsForThisDashboard = useMemo(() => {
    return notifications.filter(
      (notification) =>
        notification.destination.dashboardId === dashboardConfig?.id || notification.destination.dashboardId === '*',
    );
  }, [notifications, dashboardConfig?.id]);

  const dismissNotification = (notificationId: string): void => {
    store.dispatch(removeNotification(notificationId));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      store.dispatch(removeExpiredNotifications());
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='h-full w-full'>
      <ParentSize>
        {({ width, height }) => {
          return (
            <CenteringList
              width={width}
              height={height}
              notifications={notificationsForThisDashboard}
              onNotificationDismissed={dismissNotification}
            />
          );
        }}
      </ParentSize>
    </div>
  );
};

export default NotificationsContainer;
