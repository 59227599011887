// Widget metric wrapper is used to wrap the metric text in a svg element, background indicator, and calculate the width and height of the text.
// This component contains: metric value & measure unit (aka primaryText), and secondaryText (e.g. time, rolling period, lane name), and background indicator.
// The primary and secondary text are vertically aligned in the middle of the container. The title is 25px below the primary text.
// Not all widgets are required to wrap with this component. For example, the "graph" widget does not need to wrap with this component.

import { useEffect, useRef, useState } from 'react';
import TextSVG, { type TextSVGProps } from '../textSVG/TextSVG';
import classNames from 'classnames';

export interface StandardMetricWrapperProps extends Partial<TextSVGProps> {
  metricValue: number;
  displayValue?: string;
  placeholder?: string;
  children?: JSX.Element | JSX.Element[];
  secondaryText?: string;
  secondaryTextClassName?: string;
  tertiaryText?: string;
  tertiaryTextClassName?: string;
  inlineSecondaryText?: boolean;
}

const getDOMRect = (refObject: React.RefObject<Element>): DOMRect | undefined => {
  if (refObject?.current !== null) {
    return refObject.current.getBoundingClientRect();
  }

  return undefined;
};

const commonClass = 'absolute w-full h-full items-center justify-center';

const WidgetMetricWrapper = (props: StandardMetricWrapperProps): JSX.Element => {
  const {
    metricValue,
    placeholder = '-',
    displayValue,
    secondaryText,
    tertiaryText,
    textWidthPercentage,
    textHeightPercentage,
    primaryTextClassName = 'fill-emphasis-solid font-semibold',
    secondaryTextClassName = 'text-emphasis-muted font-semibold text-xl',
    tertiaryTextClassName = 'text-emphasis-muted font-normal text-[15px]',
    inlineSecondaryText = false,
    children,
  } = props;

  const divRef = useRef<HTMLDivElement>(null);
  const textSVGRef = useRef<SVGTextElement>(null);
  const [secondaryTextPaddingTop, setSecondaryTextPaddingTop] = useState<number>(0);
  const [tertiaryTextPaddingBottom, setTertiaryTextPaddingBottom] = useState<number>(0);
  const [secondaryTextSize, setSecondaryTextSize] = useState<number>(20);

  useEffect(() => {
    const svgTextDOMRect = getDOMRect(textSVGRef);

    if (svgTextDOMRect !== undefined) {
      setSecondaryTextPaddingTop(svgTextDOMRect.height);
      setTertiaryTextPaddingBottom(svgTextDOMRect.height);
    }
  }, [textSVGRef?.current]);

  useEffect(() => {
    const divDOMRect = getDOMRect(divRef);

    // Responsive font size for secondary text
    if (divDOMRect !== undefined) {
      if (divDOMRect !== undefined) {
        const { height } = divDOMRect;

        if (height <= 50) {
          setSecondaryTextSize(9);
        } else if (height <= 60) {
          setSecondaryTextSize(12);
        } else if (height <= 90) {
          setSecondaryTextSize(16);
        } else {
          setSecondaryTextSize(20);
        }
      }
    }
  }, [divRef?.current]);

  return (
    <div ref={divRef} className='h-full relative'>
      {/* Metric indicator (background) */}
      {children !== undefined && <div className={commonClass}>{children}</div>}

      {/* Tertiary text for any other information to describe main metric */}
      {tertiaryText !== undefined && (
        <div
          style={{ paddingBottom: `${tertiaryTextPaddingBottom}px` }}
          className={classNames(commonClass, 'grid inset-y-0 left-0 z-10')}
        >
          <div className='inline'>
            <span className={classNames(tertiaryTextClassName, 'align-super')}>{tertiaryText}</span>
          </div>
        </div>
      )}

      {/* Main metric text */}
      <div className={classNames(commonClass, 'flex px-5 inset-y-0 left-0 z-10')}>
        {inlineSecondaryText && (
          <span
            style={{ fontSize: `${secondaryTextSize}px` }}
            className={classNames(secondaryTextClassName, 'w-full', 'text-left', 'pr-4', 'pl-4')}
          >
            {secondaryText}
          </span>
        )}
        <TextSVG
          ref={textSVGRef}
          primaryText={metricValue === 0 ? placeholder : displayValue !== undefined ? displayValue : `${metricValue}`}
          primaryTextClassName={classNames(primaryTextClassName, 'roboto-fm-v2')}
          textWidthPercentage={textWidthPercentage}
          textHeightPercentage={textHeightPercentage}
          textDominantBaseline={secondaryText !== undefined && !inlineSecondaryText ? 'middle' : 'central'}
        />
      </div>

      {/* Secondary text such as period/subtitle */}
      {secondaryText !== undefined && !inlineSecondaryText && (
        <div
          style={{ paddingTop: `${secondaryTextPaddingTop}px` }}
          className={classNames(commonClass, 'flex inset-y-0 left-0 z-10')}
        >
          <span className={secondaryTextClassName} style={{ fontSize: `${secondaryTextSize}px` }}>
            {secondaryText}
          </span>
        </div>
      )}
    </div>
  );
};

export default WidgetMetricWrapper;
