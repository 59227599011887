import { type ActionReducerMapBuilder, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '@/stores/sites/utils';
import { type RootState } from '@/stores';
import { type SiteData, getSiteData } from '@/stores/sites/actions';
import { REQUEST_STATUS, type SiteTarget, type State, type Target } from '@/stores/sites/types';

export interface TargetConfigsState extends State<SiteTarget | undefined> {}

const initialState: TargetConfigsState = {
  data: undefined,
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

export const targetConfigSlice = createSlice({
  name: 'targetConfigs',
  initialState,
  reducers: {
    setTargetConfig: (state, { payload }: PayloadAction<SiteTarget>) => {
      // Ensure that the target config is only updated if the siteId matches
      if (state.data?.siteId === payload.siteId) {
        state.data = {
          siteId: payload.siteId,
          targets: payload.targets,
        };
      }
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<TargetConfigsState>) => {
    builder
      .addCase(getSiteData.pending, pendingReducer)
      .addCase(
        getSiteData.fulfilled,
        fulfilledReducer((state, action) => {
          const { siteConfig } = action.payload as SiteData;
          if (siteConfig !== undefined) {
            state.data = {
              siteId: siteConfig.siteId,
              targets: siteConfig.targets as Target[],
            };
          }
        }),
      )
      .addCase(getSiteData.rejected, rejectedReducer);
  },
});

export default targetConfigSlice.reducer;

export const { setTargetConfig } = targetConfigSlice.actions;

export const selectIsTargetsLoading = (state: RootState): boolean =>
  state.targetConfigs.requestStatus === REQUEST_STATUS.LOADING;

export const selectSiteTargets = (state: RootState): SiteTarget | undefined => state.targetConfigs.data;

export const TargetTimeOptions = [
  { value: 'DEPARTURE_COUNT', label: 'Departure count' },
  { value: 'DEPARTURE_RATE', label: 'Departure rate' },
  { value: 'BOOM_GATE_TIME', label: 'Boom gate time' },
  { value: 'ORDER_TIME', label: 'Order time' },
  { value: 'PAY_TIME', label: 'Payment time' },
  { value: 'PICKUP_TIME', label: 'Deliver time' },
  { value: 'PARKED_BAY_TIME', label: 'Waiting time' },
  { value: 'PULL_FORWARD_TIME', label: 'Pull forward time' },
  { value: 'TOTAL_EXPERIENCE_TIME', label: 'Total experience time' },
  { value: 'OEFD_TIME', label: 'Order end to deliver end' },
  { value: 'NULL', label: 'No target' },
];
