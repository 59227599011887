import { REQUEST_STATUS, type State, type DayOfWeek, type Target, TargetGranularity, TargetType } from './types';
import { type SerializedError } from '@reduxjs/toolkit';
import findLast from 'lodash/findLast';
import { type ThisMoment } from '@/types';
import { isEmpty } from '@/common';

export const fulfilledReducer =
  <T>(callback: (state: State<T>, action: { payload: unknown }) => void) =>
  (state: State<T>, action: { payload: unknown }): void => {
    callback(state, action);
    state.requestStatus = REQUEST_STATUS.SUCCEEDED;
  };

export const pendingReducer = <T>(state: State<T>): void => {
  state.requestStatus = REQUEST_STATUS.LOADING;
};

export const rejectedReducer = <T>(state: State<T>, action: { error: SerializedError }): void => {
  state.requestStatus = REQUEST_STATUS.FAILED;
  state.error = action.error;
  window.logger.error(action.error);
};

export const findLastAvailableMoment = (twentyFourHourTime: string, intervals: string[]): string => {
  const moment = findLast(intervals.sort(), (interval) => interval <= twentyFourHourTime);

  if (moment === undefined) {
    return twentyFourHourTime;
  }

  return moment;
};

export const getThisMomentTarget = (
  { twentyFourHourTime = '', dayOfWeek = '' }: Partial<ThisMoment>,
  targetType: TargetType,
  siteTargets: Target[],
  clientTargets?: Target[],
): number | undefined => {
  // Departure rate is calculated based on departure count (granularity * 60 / departure count)
  // If request target type is departure rate, find the target type departure count
  let target = siteTargets.find(
    (t) => t.type === (targetType === TargetType.DepartureRate ? TargetType.DepartureCount : targetType),
  );

  // If target is not found at site level for given type, check at client level
  if (target === undefined && clientTargets !== undefined) {
    target = clientTargets.find(
      (t) => t.type === (targetType === TargetType.DepartureRate ? TargetType.DepartureCount : targetType),
    );
  }

  // If target is not found at client level for given type, return undefined
  if (target === undefined) {
    window.logger.warn(`No target found for type ${targetType}`);
    return undefined;
  }

  // If target granularity is fixed, return fixed target
  if (target.granularity === TargetGranularity.Fixed) {
    if (target.fixed === undefined) {
      window.logger.warn(`No fixed target found for type ${targetType}`);
      return undefined;
    }

    return target.fixed;
  }

  // If target granularity is not fixed, find the target for this moment
  const todayTargets = target.adjustedInterval?.[dayOfWeek.toLowerCase() as DayOfWeek];

  // If today target is not found, return undefined
  if (todayTargets === undefined) {
    window.logger.warn(`No target found for day ${dayOfWeek}`);
    return undefined;
  }

  // Available intervals for today
  // Only select intervals where target value is defined (not null or undefined)
  const intervals = Object.keys(todayTargets).filter((interval) => !isEmpty(todayTargets[interval]));

  if (intervals.length === 0) {
    window.logger.warn(`No intervals found for day ${dayOfWeek}`);
    return undefined;
  }

  const interval = intervals.includes(twentyFourHourTime)
    ? twentyFourHourTime
    : findLastAvailableMoment(twentyFourHourTime, intervals);

  if (todayTargets[interval] === undefined) {
    return undefined;
  }

  const targetValue = Number(todayTargets[interval]);

  if (targetType === TargetType.DepartureRate) {
    // It's safe to assume that granularity is a number because it's validated in the API
    const granularity = Number(target.granularity);
    return targetValue === 0 ? 0 : Math.round((granularity * 60) / targetValue);
  }

  return targetValue;
};
