import { camelcaseKeys } from '@/common';
import { type CrtError, iot, mqtt } from 'aws-iot-device-sdk-v2';
import createHttp from '../http';
import get from 'lodash/get';
import { type IotSettings, type IotDeviceController, type TopicHandler } from './type';
import { CONNECTION_STATE } from '@/types';
import { setConnectionState } from '@/stores/ioTconnections';
import { store } from '@/stores';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';

export default function createDeviceController(iotSettings: IotSettings): IotDeviceController {
  const connectionId = `aws-iot-${uuidv4()}`;
  const subscriptions: Record<string, mqtt.MqttSubscribeRequest | undefined> = {};

  let connectionState = CONNECTION_STATE.DISCONNECTED;
  let connection: mqtt.MqttClientConnection | undefined;

  const getTokenSignature = async (token: string): Promise<string> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: `
        query getTokenSignature($token: String) {
          getTokenSignature(token: $token)
        }
      `,
      variables: {
        token,
      },
    });

    // Logging as we know body may be empty, but do not know what the response is.
    const signature = get(response, 'data.data.getTokenSignature.body', '');
    if (signature === '' || signature === null) {
      window.logger.error(`No body found in query for token signature. Response was: ${JSON.stringify(response)}`);
    }
    return signature;
  };

  const connectRoundRobin = async (): Promise<void> => {
    const { host } = iotSettings;

    const authName = process.env.REACT_IOT_AUTH_NAME ?? 'aws-iot-custom-auth-heimdallr';
    const token = localStorage.getItem('accessToken') ?? '';
    const signature = await getTokenSignature(token);
    const tokenName = 'token';

    if (authName === '') {
      console.error('authName is empty or undefined', { authName });
      throw new Error('authName is required for custom authorizer');
    }
    if (token === '') {
      console.error('token is empty or undefined', { token });
      throw new Error('token is required for custom authorizer');
    }
    if (signature === '') {
      console.error('signature is empty or undefined', { signature });
      throw new Error('signature is required for custom authorizer');
    }
    if (tokenName.length === 0) {
      console.error('tokenName is empty or undefined', { tokenName });
      throw new Error('tokenName is required for custom authorizer');
    }

    const config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
      .with_clean_session(true)
      .with_client_id(connectionId)
      .with_endpoint(host)
      .with_custom_authorizer('', authName, encodeURIComponent(signature), '', tokenName, encodeURIComponent(token))
      .with_keep_alive_seconds(30)
      .build();
    config.use_websocket = true;

    const client = new mqtt.MqttClient();
    connection = client.new_connection(config);

    connection.on('connect', onConnected);

    connection.on('interrupt', onInterrupted);

    connection.on('resume', onResumed);

    connection.on('disconnect', onDisconnected);

    connection.on('error', onError);

    try {
      window.logger.info(`${connectionId} connecting...`);
      await connection.connect();
    } catch (ex) {
      const { siteId, clientId, dashboardId } = useParams();
      window.logger.error(ex, {
        customer: clientId,
        site: siteId,
        dashboard: dashboardId,
      });

      // Re-try connection
      setTimeout(async () => {
        await connectRoundRobin();
      }, 1000);
    }
  };

  const onConnected = (): void => {
    window.logger.info(`${connectionId} connected`);
    updateConnectionState(CONNECTION_STATE.CONNECTED);
  };

  const onDisconnected = (): void => {
    window.logger.warn(`${connectionId} disconnected`);
    updateConnectionState(CONNECTION_STATE.DISCONNECTED);
  };

  const onInterrupted = async (error: CrtError): Promise<void> => {
    if (JSON.stringify(error) !== '-1') {
      window.logger.warn(`${connectionId} interrupted ${JSON.stringify(error)}`);
    }
    store.dispatch(setConnectionState({ connectionId, connectionState: CONNECTION_STATE.INTERRUPTED }));
  };

  const onResumed = async (returnCode: number, sessionPresent: boolean): Promise<void> => {
    window.logger.info(`${connectionId} resumed ${returnCode}. Existing session ${sessionPresent}`);
    store.dispatch(setConnectionState({ connectionId, connectionState: CONNECTION_STATE.RESUMED }));
  };

  const onError = (error: CrtError): void => {
    window.logger.error(`${connectionId} error ${error.message}`);
    store.dispatch(setConnectionState({ connectionId, connectionState: CONNECTION_STATE.ERROR }));
  };

  const subscribe = async (topic: string, handlers: TopicHandler[]): Promise<void> => {
    if (connectionState !== CONNECTION_STATE.CONNECTED) {
      window.logger.warn(`${connectionId} subscribe: not connected`);
      return;
    }

    if (topic in subscriptions && subscriptions[topic] !== undefined) {
      window.logger.info(`${connectionId}: ${topic} was already subscribed`);
      return;
    }

    subscriptions[topic] = await connection?.subscribe(
      topic,
      mqtt.QoS.AtLeastOnce,
      (subscribedTopic: string, payload: unknown) => {
        const decoder = new TextDecoder('utf8');
        const message = decoder.decode(new Uint8Array(payload as ArrayBuffer));
        const jsonMessage = JSON.parse(message);
        const camelCaseMessage = camelcaseKeys(jsonMessage, { deep: true });
        window.logger.debug(`[${connectionId}] ${subscribedTopic}`, camelCaseMessage);
        for (const handler of handlers) {
          handler(camelCaseMessage);
        }
      },
    );
  };

  const unsubscribe = async (topic: string): Promise<void> => {
    if (connectionState !== CONNECTION_STATE.CONNECTED) {
      return;
    }

    if (topic in subscriptions) {
      await connection?.unsubscribe(topic);
    }

    subscriptions[topic] = undefined;
    delete subscriptions.topic;
  };

  const connect = async (): Promise<void> => {
    if (connectionState === CONNECTION_STATE.CONNECTING) {
      return;
    }

    if (connectionState === CONNECTION_STATE.CONNECTED) {
      window.logger.debug(`${connectionId} already connected`);
      onConnected();
      return;
    }

    updateConnectionState(CONNECTION_STATE.CONNECTING);
    await connectRoundRobin();
  };

  const disconnect = async (): Promise<void> => {
    if (connectionState !== CONNECTION_STATE.CONNECTED) {
      return;
    }

    updateConnectionState(CONNECTION_STATE.DISCONNECTING);
    await connection?.disconnect();
  };

  const updateConnectionState = (state: CONNECTION_STATE): void => {
    connectionState = state;
    store.dispatch(setConnectionState({ connectionId, connectionState: state }));
  };

  return {
    connectionId,
    connect,
    disconnect,
    subscribe,
    unsubscribe,
  };
}
