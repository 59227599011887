import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Selector, type RootState } from '..';
import reduce from 'lodash/reduce';
import parseISO from 'date-fns/parseISO';
import min from 'date-fns/min';
import isEqual from 'date-fns/isEqual';

export interface VehicleInfo {
  id: string;
  vehicleId: string;
  entryTime: string;
}

export interface RoiModel {
  roiId: string;
  assetsInfo: VehicleInfo[];
  vehicleCount: number;
  leadCarResetTime: string;
}

export interface RoisState {
  rois: Record<string, RoiModel>;
}

const initialState: RoisState = {
  rois: {},
};

export const rois = createSlice({
  name: 'rois',
  initialState,
  reducers: {
    setRoi: (state, { payload }: PayloadAction<RoiModel>) => {
      const { roiId } = payload;
      state.rois[roiId] = payload;
    },
  },
});

export const selectRois = (state: RootState): Record<string, RoiModel> => state.rois.rois;

// This can be removed along with logic that relies on it, once all sites have the lead_car_reset_time added to their roicount events.
// That will be used for the value for the lead car timer, instead of the age of the oldest vehicle.
export const selectOldestVehicleEntryTime = (roiIds: string[]): Selector<Record<string, string>> =>
  createSelector(
    selectRois,
    (rois): Record<string, string> =>
      reduce(
        roiIds,
        (result: Record<string, string>, roiId: string) => {
          if (rois[roiId] === undefined) {
            return result;
          }

          const roiEvent = rois[roiId];
          if (roiEvent.assetsInfo.length === 0) {
            return result;
          }

          const vehicles = roiEvent.assetsInfo;
          const entryTimes = vehicles.map((vehicle) => parseISO(vehicle.entryTime));
          const oldestEntryTime = min(entryTimes);
          const oldestVehicle = vehicles.find((vehicle) => isEqual(parseISO(vehicle.entryTime), oldestEntryTime));
          if (oldestVehicle === undefined) {
            return result;
          }

          result[roiId] = oldestVehicle.entryTime;

          return result;
        },
        {},
      ),
  );

export const selectLeadCarResetTimes = (roiIds: string[]): Selector<Record<string, string>> =>
  createSelector(
    selectRois,
    (rois): Record<string, string> =>
      reduce(
        roiIds,
        (result: Record<string, string>, roiId: string) => {
          if (rois[roiId] === undefined) {
            return result;
          }
          result[roiId] = rois[roiId].leadCarResetTime;

          return result;
        },
        {},
      ),
  );

export const selectVehicleCount = (roiIds: string[]): Selector<Record<string, number>> =>
  createSelector(
    selectRois,
    (rois): Record<string, number> =>
      reduce(
        roiIds,
        (result: Record<string, number>, roiId: string) => {
          if (rois[roiId] === undefined) {
            return result;
          }

          result[roiId] = rois[roiId].vehicleCount;

          return result;
        },
        {},
      ),
  );

export const { setRoi } = rois.actions;
export default rois.reducer;
