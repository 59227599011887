import { Gauge } from '@/widgets/components/gauge';
import { getThisMomentTargetOrLastAvailable, getThisMomentTimeZone, isEmpty, useAppSelector } from '@/common';
import { type CurrentDepartureRate, selectDepartureRateCurrent } from '@/stores/departures';
import reduce from 'lodash/reduce';
import sum from 'lodash/sum';
import { useMemo } from 'react';
import { WidgetWrapper } from '../components/wrapper';
import { selectSiteTimeZone, selectTargetIntervals } from '@/stores/siteConfigs';
import { StandardWidgetProps } from '@/types';
import { TargetType } from '@/stores/sites/types';
import { selectSiteTargets } from '@/stores/sites/targetConfigs';
import { getThisMomentTarget } from '@/stores/sites/utils';
import { Timed } from 'react-time-sync';
import TimeSync from 'time-sync';
import Validation from '@/widgets/departureCount/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';
import { selectClientTargets } from '@/stores/sites/generalConfigs';

export const widgetMetadata: WidgetMetadata = {
  title: 'departureRate',
  displayName: 'Departure Rate',
  description: 'Placeholder description',
};

export interface DepartureRateProps extends StandardWidgetProps {
  roiType: string;
}

const DepartureRate = (props: DepartureRateProps): JSX.Element => {
  const { roiType } = props;
  const timezone = useAppSelector(selectSiteTimeZone);
  const targetIntervals = useAppSelector(selectTargetIntervals);
  const siteTarget = useAppSelector(selectSiteTargets);
  const clientTargets = useAppSelector(selectClientTargets);

  // Get current departure rate
  const currentDepartureRate = useAppSelector(selectDepartureRateCurrent);

  const zoneDepartureRate = useMemo(
    () =>
      reduce(
        currentDepartureRate,
        (result: Record<string, number[]>, value: CurrentDepartureRate) => {
          (result[value.roiType] ?? (result[value.roiType] = [])).push(value.avgDepartInterval);
          return result;
        },
        {},
      ),
    [currentDepartureRate],
  );

  const zoneDepartureRateSummed = useMemo(() => {
    return zoneDepartureRate[roiType] === undefined || zoneDepartureRate[roiType].length === 0
      ? 0
      : sum(zoneDepartureRate[roiType]) / zoneDepartureRate[roiType].length;
  }, [roiType, zoneDepartureRate]);

  return (
    <Timed interval={TimeSync.MINUTES} unit={1}>
      {() => {
        window.logger.debug(`Get next target rate.`);

        const thisMoment = getThisMomentTimeZone(timezone);

        // Use fallback approach to get target
        // TODO: The 'getThisMomentTargetOrLastAvailable' can be removed after migrating all site to the new 'store' table
        const target =
          (siteTarget !== undefined
            ? getThisMomentTarget(thisMoment, TargetType.DepartureRate, siteTarget.targets, clientTargets)
            : getThisMomentTargetOrLastAvailable(
                { ...thisMoment },
                targetIntervals?.targetRates[thisMoment.dayOfWeek.toLowerCase()] ?? {},
              )) ?? 0;

        return (
          <Gauge
            maxValue={target * 2}
            metricValue={Math.round(Math.max(zoneDepartureRateSummed, 0))}
            targetValue={target}
            invert={true}
            secondaryText='15min'
            isActive={true}
          />
        );
      }}
    </Timed>
  );
};

export default function (props: DepartureRateProps & { children?: JSX.Element }): JSX.Element {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Validation {...props}>
        <DepartureRate {...props} />
      </Validation>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
}
