import { widgetMetadata as activeDepartureRateMetadata } from '../../widgets/activeDepartureRate';
import { widgetMetadata as carDistributionMetadata } from '../../widgets/carDistribution';
import { widgetMetadata as departureArrivalTimelineMetadata } from '../../widgets/departureArrivalTimeline';
import { widgetMetadata as departureCountMetadata } from '../../widgets/departureCount';
import { widgetMetadata as departureCountBarChartMetadata } from '../../widgets/departureCountBarChart';
import { widgetMetadata as departureRateMetadata } from '../../widgets/departureRate';
import { widgetMetadata as departureRateBarChartMetadata } from '../../widgets/departureRateBarChart';
import { widgetMetadata as leadCarTimerMetadata } from '../../widgets/leadCarTimer';
import { widgetMetadata as leadCarTimerV2Metadata } from '../../widgets/leadCarTimerV2';
import { widgetMetadata as mostDeparturesTrackerMetadata } from '../../widgets/mostDeparturesTracker';
import { widgetMetadata as onPaceMetadata } from '../../widgets/onPace';
import { widgetMetadata as perfectCarCountMetadata } from '../../widgets/perfectCarCount';
import { widgetMetadata as timeAtWindowMetadata } from '../../widgets/timeAtWindow';
import { widgetMetadata as timeAtWindowGaugeMetadata } from '../../widgets/timeAtWindowGauge';
import { widgetMetadata as timeSinceLastDepartureMetadata } from '../../widgets/timeSinceLastDeparture';
import { widgetMetadata as zoneAverageMetadata } from '../../widgets/zoneAverage';
import { widgetMetadata as zoneAverageGaugeMetadata } from '../../widgets/zoneAverageGauge';
import { widgetMetadata as leaderBoardMetadata } from '../../widgets/leaderboard';

export interface WidgetMetadata {
  title: string;
  displayName: string;
  description: string;
  orientation?: string;
}

export const availableWidgets = [
  activeDepartureRateMetadata,
  carDistributionMetadata,
  departureArrivalTimelineMetadata,
  departureCountMetadata,
  departureCountBarChartMetadata,
  departureRateMetadata,
  departureRateBarChartMetadata,
  leadCarTimerMetadata,
  leadCarTimerV2Metadata,
  mostDeparturesTrackerMetadata,
  onPaceMetadata,
  perfectCarCountMetadata,
  timeAtWindowMetadata,
  timeAtWindowGaugeMetadata,
  timeSinceLastDepartureMetadata,
  zoneAverageMetadata,
  zoneAverageGaugeMetadata,
  leaderBoardMetadata,
];
