import { getThisMomentTimeZone, useAppSelector, useIntervalEffect } from '@/common';
import { store } from '@/stores';
import { setDatetime } from '@/stores/calendar';
import { selectSiteTimeZone } from '@/stores/siteConfigs';
import { Outlet, useParams } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { selectDeviceTheme } from '@/stores/sites/deviceConfigs';
import classNames from 'classnames';

const App = (): JSX.Element => {
  const timeZone = useAppSelector(selectSiteTimeZone);
  const { serialNumber } = useParams();
  const theme = useAppSelector(selectDeviceTheme(serialNumber));

  useIntervalEffect(() => {
    const { twelveHourTime, shortDate } = getThisMomentTimeZone(timeZone);
    store.dispatch(setDatetime({ date: shortDate, time: twelveHourTime }));
  }, 1000);

  return (
    <div className={classNames('bg-dashboard-background roboto', theme)}>
      <Outlet />
    </div>
  );
};

const launchdarklyContext = localStorage.getItem('launchdarkly.context');

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY ?? '',
  context: JSON.parse(launchdarklyContext ?? '{}'),
  options: { streaming: false },
})(App);
