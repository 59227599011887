import {
  type AnyAction,
  type CombinedState,
  combineReducers,
  configureStore,
  type PreloadedState,
} from '@reduxjs/toolkit';
import preloadedState from './preloadedState';
import departuresReducer, { type DepartureState } from './departures';
import arrivalsReducer, { type ArrivalState } from './arrivals';
import roisReducer, { type RoisState } from './rois';
import storesReducer, { type SiteConfigState } from './siteConfigs';
import queuesReducer, { type QueuesState } from './queues';
import calendarReducer, { type CalendarState } from './calendar';
import logsReducer, { type LogsState } from './logs';
import ioTconnectionsReducer, { type IoTConnectionsState } from './ioTconnections';
import averagesReducer, { type AveragesState } from './averages';
import onPaceReducer, { type OnPaceState } from './onPace';
import targetConfigsReducer, { type TargetConfigsState } from './sites/targetConfigs';
import deviceConfigsReducer, { type DeviceConfigState } from './sites/deviceConfigs';
import dashboardConfigsReducer, { type DashboardConfigState } from './sites/dashboardConfigs';
import generalSiteConfigsReducer, { type GeneralSiteConfigState } from './sites/generalConfigs';
import notificationsReducer, { type NotificationsState } from './notifications';
import leaderboardReducer, { type LeaderboardState } from './leaderboard';

interface CombinedStateType {
  departures: DepartureState;
  arrivals: ArrivalState;
  rois: RoisState;
  stores: SiteConfigState;
  calendar: CalendarState;
  queues: QueuesState;
  logs: LogsState;
  ioTconnections: IoTConnectionsState;
  averages: AveragesState;
  onPace: OnPaceState;
  targetConfigs: TargetConfigsState;
  deviceConfigs: DeviceConfigState;
  dashboardConfigs: DashboardConfigState;
  generalSiteConfigs: GeneralSiteConfigState;
  notifications: NotificationsState;
  leaderboard: LeaderboardState;
}

const appReducer = combineReducers({
  departures: departuresReducer,
  arrivals: arrivalsReducer,
  rois: roisReducer,
  stores: storesReducer,
  calendar: calendarReducer,
  queues: queuesReducer,
  logs: logsReducer,
  ioTconnections: ioTconnectionsReducer,
  averages: averagesReducer,
  onPace: onPaceReducer,
  targetConfigs: targetConfigsReducer,
  deviceConfigs: deviceConfigsReducer,
  dashboardConfigs: dashboardConfigsReducer,
  generalSiteConfigs: generalSiteConfigsReducer,
  notifications: notificationsReducer,
  leaderboard: leaderboardReducer,
});

const rootReducer = (
  state: CombinedState<CombinedStateType> | undefined,
  action: AnyAction,
): CombinedState<CombinedStateType> => {
  if (action.type === 'RESET_STATE') {
    state = action.payload;
  }

  return appReducer(state, action);
};

export const setupStore = (preloadedState?: PreloadedState<RootState>): any => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store = setupStore();
export const storybookPreloadedStore = setupStore(preloadedState); // for storybook
export type RootState = ReturnType<typeof rootReducer>;
export type Selector<S> = (state: RootState) => S;
