import { Bubble } from '@/widgets/components/bubble';
import { Timed } from 'react-time-sync';
import { formatDurationToMinutesAndSeconds, useAppSelector } from '@/common';
import { parseISO, differenceInSeconds } from 'date-fns';
import { selectMostRecentDeparture } from '@/stores/departures';
import { WidgetWrapper } from '../components/wrapper';
import { selectDurationFormat } from '@/stores/sites/generalConfigs';
import { StandardWidgetProps } from '@/types';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';
import MetricTextSVG from '../components/text/MetricTextSVG';

export const widgetMetadata: WidgetMetadata = {
  title: 'timeSinceLastDeparture',
  displayName: 'Time Since Last Departure',
  description: 'Placeholder description',
};

export interface TimeSinceLastDepartureProps extends StandardWidgetProps {}

const MAX_TIME_SINCE_LAST_DEPARTURE = 15 * 60;

const TimeSinceLastDeparture = (props: TimeSinceLastDepartureProps & { children?: JSX.Element }): JSX.Element => {
  const departureEventTime = useAppSelector(selectMostRecentDeparture);
  const durationFormat = useAppSelector(selectDurationFormat);

  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Timed>
        {() => {
          const now = new Date();
          let timeSinceLastDeparture = departureEventTime ? differenceInSeconds(now, parseISO(departureEventTime)) : 0;

          if (timeSinceLastDeparture > MAX_TIME_SINCE_LAST_DEPARTURE) {
            timeSinceLastDeparture = 0;
          }

          return (
            <Bubble>
              <MetricTextSVG className='text-9xl font-semibold'>
                {timeSinceLastDeparture === 0
                  ? '-'
                  : formatDurationToMinutesAndSeconds(timeSinceLastDeparture, durationFormat)}
              </MetricTextSVG>
            </Bubble>
          );
        }}
      </Timed>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

export default TimeSinceLastDeparture;
