import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from './index';

const LOG_RETENTION_COUNT = 50;

export interface Log {
  level: string;
  message: string;
}

export interface LogsState {
  logs: Log[];
}

const initialState: LogsState = {
  logs: [] as Log[],
};

export const logs = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    addLog: (state, { payload }: PayloadAction<Log>) => {
      if (state.logs.length >= LOG_RETENTION_COUNT) {
        state.logs = [] as Log[];
      }

      state.logs.push(payload);
    },
  },
});

export default logs.reducer;

export const { addLog } = logs.actions;
export const selectLogs = (state: RootState): Log[] => state.logs.logs;
