import { useContext, useMemo } from 'react';
import { WidgetContentContext } from '../wrapper/widgetContentContext';
import WidgetMetricWrapper from '../wrapper/WidgetMetricWrapper';
import { useGaugePaddingEffect } from '../hook';

interface BubbleProps {
  children: JSX.Element | JSX.Element[];
}

const Circle = (props: { className: string; strokeScaling: number }): JSX.Element => {
  const { strokeScaling, ...rest } = props;
  const { renderSize: bubbleSize } = useContext(WidgetContentContext);

  const { cx, cy, radius, viewBox, strokeWidth } = useMemo(() => {
    const cx = bubbleSize.width / 2;
    const cy = bubbleSize.height / 2;
    const bubbleDiameter = Math.min(bubbleSize.width, bubbleSize.height);
    const radius = bubbleDiameter * 0.5 * 0.98;
    const strokeWidth = radius * 0.02 * strokeScaling;
    const viewBox = `0 0 ${bubbleSize.width} ${bubbleSize.height}`;

    return { cx, cy, radius, viewBox, strokeWidth };
  }, [bubbleSize.width, bubbleSize.height]);

  return (
    <svg width='100%' height='100%' viewBox={viewBox}>
      <circle cx={cx} cy={cy} r={radius} strokeWidth={strokeWidth} fill='none' {...rest} />
    </svg>
  );
};

export const Bubble = (props: BubbleProps) => {
  const { children } = props;
  const { renderSize } = useContext(WidgetContentContext);
  const diameter = useMemo(() => Math.min(renderSize.width, renderSize.height), [renderSize.width, renderSize.height]);
  const metricRef = useGaugePaddingEffect(renderSize, diameter);

  return (
    <WidgetMetricWrapper component={<Circle className='stroke-indicator-neutral' strokeScaling={1} />}>
      <div
        ref={metricRef}
        className='flex flex-col items-center justify-center h-full w-full z-10 text-emphasis-neutral fill-emphasis-neutral'
      >
        {children}
      </div>
    </WidgetMetricWrapper>
  );
};

export const EmptyBubble = (props: BubbleProps) => {
  const { children } = props;

  return (
    <WidgetMetricWrapper component={<Circle className='stroke-indicator-empty' strokeScaling={0.5} />}>
      <div className='flex h-full w-full items-center justify-center z-10'>
        <span className='text-4xl text-indicator-empty font-normal'>{children}</span>
      </div>
    </WidgetMetricWrapper>
  );
};

export const NotConfiguredBubble = (): JSX.Element => (
  <EmptyBubble>
    <span className='text-5xl text-divider-muted font-normal'>Not configured</span>
  </EmptyBubble>
);
