import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from './index';

export interface OnPaceState {
  data: OnPaceEvent;
}

export interface OnDeparturesPaceForHour {
  prediction: number[]; // Prediction for the departures by the end of the hour. Null if no prediction available
  records: number[]; // Cumulative records for historic best departures for each 15 minute period.
}

export interface OnPaceEvent {
  timestamp: string;
  records: {
    departures: {
      quarterly: number[];
    };
  };
  predictions: {
    departures: {
      hourly: number[];
    };
  };
  version: number;
}

const initialState: OnPaceState = {
  data: {
    timestamp: new Date().toISOString(),
    records: {
      departures: {
        quarterly: [],
      },
    },
    predictions: {
      departures: {
        hourly: [],
      },
    },
    version: 1,
  },
};

export const onPace = createSlice({
  name: 'onPace',
  initialState,
  reducers: {
    setOnPace: (state, { payload }: PayloadAction<OnPaceEvent>) => {
      state.data = payload;
    },
  },
});

export const selectOnPaceForHour = createSelector(
  (state: RootState) => state.onPace,
  (onPace) => {
    const {
      data: {
        records: {
          departures: { quarterly },
        },
        predictions: {
          departures: { hourly },
        },
      },
    } = onPace;

    const onPaceForHour = {
      prediction: hourly,
      records: quarterly,
    };

    return onPaceForHour;
  },
);

export const { setOnPace } = onPace.actions;
export default onPace.reducer;
