interface ModuleProps {
  enabled: boolean;
  children: JSX.Element;
}

const Module = (props: ModuleProps): JSX.Element => {
  const { enabled, children } = props;

  return enabled ? children : <></>;
};

export default Module;
