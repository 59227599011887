// This component is used to connect to ROI events and subscribe to topics

import { useCallback, useEffect, useMemo } from 'react';
import { selectSiteId } from '@/stores/siteConfigs';
import { useAppSelector, useIntervalEffect } from '@/common';
import device, { type TopicToHandlersMap } from '../services/iot';
import defaultTopicToHandlersMap from './topicHandlerMap';
import { type IotDevice } from '@/services/iot/type';
import { selectConnectionState } from '@/stores/ioTconnections';
import { CONNECTION_STATE } from '@/types';
import { selectClientConfig, selectSiteIdV2 } from '@/stores/sites/generalConfigs';
import template from 'lodash/template';

const TopicProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const clientConfig = useAppSelector(selectClientConfig);
  const siteId = useAppSelector(selectSiteId);
  const siteIdV2 = useAppSelector(selectSiteIdV2);
  const connectionState = useAppSelector(selectConnectionState);

  // Filter topics
  // If no topics are defined in the client config, use default topics
  const topicFilter = useMemo<string[]>(() => {
    const topics = clientConfig?.topics;
    return topics !== undefined && topics.length > 0 ? topics : Object.keys(defaultTopicToHandlersMap);
  }, [clientConfig?.topics]);

  // Generate topic handlers map
  const topicToHandlerMap = useMemo<TopicToHandlersMap | undefined>(() => {
    if (siteId !== undefined) {
      return topicFilter.reduce<TopicToHandlersMap>((result, topic) => {
        // Use fallback approach to get siteId
        // TODO: The 'siteId' can be removed after migrating all site to the new 'store' table
        const compiled = template(topic);
        const topicToMap = compiled({ siteId: siteIdV2 ?? siteId });

        const handlers = defaultTopicToHandlersMap[topic];

        if (handlers !== undefined && handlers.length > 0) {
          result[topicToMap] = handlers;
        } else {
          result[topicToMap] = [() => window.logger.warn(`No handler for topic ${topicToMap}`)];
        }

        return result;
      }, {});
    }

    return undefined;
  }, [siteId, siteIdV2, topicFilter]);

  // Create IoT device
  const iotDevice = useMemo<IotDevice | undefined>(() => {
    if (clientConfig?.awsIot !== undefined && topicToHandlerMap !== undefined) {
      return device(clientConfig.awsIot, topicToHandlerMap);
    }

    return undefined;
  }, [clientConfig?.awsIot, topicToHandlerMap]);

  // Reset IoT device callback
  const reset = useCallback(async (): Promise<void> => {
    await iotDevice?.reset();
  }, [iotDevice]);

  // Connect & subscribe to topics
  useEffect(() => {
    const connect = async (): Promise<void> => {
      await iotDevice?.start();
    };

    void connect();

    return () => {
      void iotDevice?.stop();
    };
  }, [iotDevice]);

  useEffect(() => {
    if (connectionState === CONNECTION_STATE.RESUMED) {
      void reset();
    }
  }, [connectionState, iotDevice]);

  // Reset IoT device every 15 minutes
  useIntervalEffect(() => {
    void reset();
  }, 15 * 60000);

  return <>{children}</>;
};

export default TopicProvider;
