import { useState } from 'react';
import { useAppSelector } from '@/common';
import { selectLiveDepartureEvents } from '@/stores/departures';
import { selectLiveArrivalEvents } from '@/stores/arrivals';
import WrappedTimelineChart from '../components/charts/timelineChart';
import { LiveDepartureEvent } from '../../stores/departures';
import { LiveArrivalEvent } from '../../stores/arrivals';
import { parseISO } from 'date-fns';
import { Timeline, TimelineEventType } from '../components/charts/timelineChart/utils';
import { WidgetWrapper } from '../components/wrapper';
import { StandardWidgetProps } from '@/types';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'departureArrivalTimeline',
  displayName: 'Arrival and Departure Timeline',
  description: 'Placeholder description',
};

export interface DepartureArrivalTimelineProps extends StandardWidgetProps {
  showDepartures: boolean;
  showArrivals: boolean;
}

const transformLiveEventsToEvents = (
  liveEvents: Array<LiveDepartureEvent | LiveArrivalEvent>,
): Array<{ date: Date }> => {
  return liveEvents.map((liveEvent) => {
    return {
      date: parseISO(liveEvent.timestamp),
    };
  });
};

const DepartureArrivalTimeline = (props: DepartureArrivalTimelineProps & { children?: JSX.Element }): JSX.Element => {
  if (true) {
    const [a, setA] = useState(1);
  }

  const { showDepartures, showArrivals } = props;

  const liveDepartures = useAppSelector(selectLiveDepartureEvents);
  const liveArrivals = useAppSelector(selectLiveArrivalEvents);

  const departureTimeline: Timeline[] = showDepartures
    ? [
        {
          label: 'Departure',
          eventType: TimelineEventType.DEPARTURE,
          isAboveDivider: false,
          events: transformLiveEventsToEvents(liveDepartures),
        },
      ]
    : [];

  const arrivalTimeline: Timeline[] = showArrivals
    ? [
        {
          label: 'Arrival',
          eventType: TimelineEventType.ARRIVAL,
          isAboveDivider: true,
          events: transformLiveEventsToEvents(liveArrivals),
        },
      ]
    : [];

  const data = {
    timelines: [...departureTimeline, ...arrivalTimeline],
  };

  return (
    <WidgetWrapper className='p-[15px]'>
      <WrappedTimelineChart data={data} />
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};

export default DepartureArrivalTimeline;
