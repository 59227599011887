import { ParentSize } from '@visx/responsive';
import OnPaceChart, { type OnPaceChartProps } from './OnPaceChart';
import React from 'react';

export interface WrappedOnPaceChartProps extends Omit<OnPaceChartProps, 'width' | 'height'> {}

const WrappedOnPaceChart = (props: WrappedOnPaceChartProps): JSX.Element => {
  return (
    <ParentSize>
      {({ width, height }) => {
        return <OnPaceChart {...props} width={width} height={height} />;
      }}
    </ParentSize>
  );
};

export default WrappedOnPaceChart;
export { type OnPaceChartProps } from './OnPaceChart';
export { type DistributionDatum } from './utils';
