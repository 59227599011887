import classNames from 'classnames';
import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const ContainedButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;

  return (
    <button
      className={classNames(
        'rounded-md w-20 px-3 py-2 text-emphasis-overlay font-normal bg-button-primary',
        'duration-[400ms] hover:bg-button-primary-hover hover:shadow-[0_4px_6px_1px_rgba(0,0,0,0.3)]',
        'disabled:cursor-not-allowed disabled:hover:shadow-none disabled:bg-button-primary-disabled disabled:text-emphasis-disabled disabled:ring-1 disabled:ring-button-primary-disabled-ring',
        className,
      )}
      {...rest}
    >
      <span className='flex gap-x-1 text-center justify-center'>{children}</span>
    </button>
  );
};

export const TextButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;

  return (
    <button
      className={classNames(
        'text-base font-normal text-emphasis-primary leading-6',
        'hover:underline',
        'disabled:cursor-not-allowed disabled:text-emphasis-disabled disabled:hover:no-underline',
        className,
      )}
      {...rest}
    >
      <span className='flex gap-x-1 text-center justify-center'>{children}</span>
    </button>
  );
};

export const AddWidgetBlockButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;
  return (
    <button type='button' className={className} {...rest}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='state=green'>
          <mask
            id='mask0_287_45267'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='2'
            y='2'
            width='20'
            height='20'
          >
            <g id='Icon Mask'>
              <path
                id='Round'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 7C11.45 7 11 7.45 11 8V11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13H11V16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V13H16C16.55 13 17 12.55 17 12C17 11.45 16.55 11 16 11H13V8C13 7.45 12.55 7 12 7ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z'
                fill='black'
              />
            </g>
          </mask>
          <g mask='url(#mask0_287_45267)'>
            <rect id='Color Fill' width='24' height='24' fill='#00BD90' />
          </g>
        </g>
      </svg>
    </button>
  );
};

export const RemoveWidgetBlockButton = (props: ButtonProps): JSX.Element => {
  const { className, children, ...rest } = props;
  return (
    <button type='button' className={className} {...rest}>
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <mask
          id='mask0_4316_52009'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='2'
          y='2'
          width='21'
          height='21'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.5874 2.90576C7.0674 2.90576 2.5874 7.38576 2.5874 12.9058C2.5874 18.4258 7.0674 22.9058 12.5874 22.9058C18.1074 22.9058 22.5874 18.4258 22.5874 12.9058C22.5874 7.38576 18.1074 2.90576 12.5874 2.90576ZM7.5874 12.9058C7.5874 13.4558 8.0374 13.9058 8.5874 13.9058H16.5874C17.1374 13.9058 17.5874 13.4558 17.5874 12.9058C17.5874 12.3558 17.1374 11.9058 16.5874 11.9058H8.5874C8.0374 11.9058 7.5874 12.3558 7.5874 12.9058ZM4.5874 12.9058C4.5874 17.3158 8.1774 20.9058 12.5874 20.9058C16.9974 20.9058 20.5874 17.3158 20.5874 12.9058C20.5874 8.49576 16.9974 4.90576 12.5874 4.90576C8.1774 4.90576 4.5874 8.49576 4.5874 12.9058Z'
            fill='black'
          />
        </mask>
        <g mask='url(#mask0_4316_52009)'>
          <rect x='0.587402' y='0.905762' width='24' height='24' fill='#F0028C' />
        </g>
      </svg>
    </button>
  );
};
