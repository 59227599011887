import React, { useEffect, useMemo, useState } from 'react';
import { TimelineEventType, type TimelineChartModel } from './utils';
import { scaleLinear, scaleTime } from '@visx/scale';
import { subMinutes, roundToNearestMinutes, addMinutes, format } from 'date-fns';
import { Circle, Line } from '@visx/shape';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import ScrollingViewport from './ScrollingViewport';
import TimelineLegend from './TimelineLegend';
import { type ScaleLinear, type ScaleTime } from 'd3';

export interface TimelineChartProps {
  width: number;
  height: number;
  data: TimelineChartModel;
}

const TIMELINE_EVENT_TYPE_TO_COLOR_MAP: Record<TimelineEventType, string> = {
  [TimelineEventType.ARRIVAL]: 'fill-[#10AB86B3]',
  [TimelineEventType.DEPARTURE]: 'fill-[#0082CBB3]',
};

const domainMins = 15; // Number of mins to display
const tickSpan = 5; // Mins between x axis ticks

const tickPeriod = 5 * 60 * 1000; // Refresh animation every 5 minutes

const chartFraction = 0.8; // Horizontal fraction of chart allocated to timelines
const legendFraction = 1 - chartFraction; // Horizontal fraction of chart allocated to the legend
const circleFraction = 0.15; // Diameter of the circles as a fraction of chart height

const calculateDomain = (): Date[] => [subMinutes(new Date(), domainMins), new Date()];

const TimelineChart = (props: TimelineChartProps): JSX.Element => {
  const { width, height, data } = props;

  const chartWidth = width * chartFraction;
  const legendWidth = width * legendFraction;
  const circleRadius = height * circleFraction * 0.5;

  const [xDomain, setXDomain] = useState(calculateDomain); // Initial state is a function rather than date constructors, so that we don't pointlessly instantiate dates every render

  const xScale = useMemo(
    () =>
      scaleTime<number>({
        domain: xDomain,
        range: [0, chartWidth],
      }),
    [chartWidth, xDomain],
  );

  const yScale = useMemo(() => scaleLinear<number>({ domain: [0, 1], range: [height, 0] }), [height]);

  const legends = data.timelines.map((timeline) => {
    return {
      label: timeline.label,
      isAboveDivider: timeline.isAboveDivider,
    };
  });

  useEffect(() => {
    setXDomain(calculateDomain());
    const intervalNum = setInterval(() => {
      setXDomain(calculateDomain());
    }, tickPeriod);
    return () => {
      clearInterval(intervalNum);
    };
  }, [setXDomain]);

  const axisTicks = useMemo(() => {
    const minDate: Date = xDomain[0];
    const leftBound = subMinutes(minDate, tickSpan);
    const roundedLeftBound = roundToNearestMinutes(leftBound, {
      nearestTo: tickSpan,
      roundingMethod: 'floor',
    });

    const numTicksToRender = domainMins / tickSpan + 3; // Render more ticks than we can see in the viewport initially, to account for ticks partially off the left and scrolling on from the right
    return Array.from({ length: numTicksToRender }, (_, index) => {
      const date = addMinutes(roundedLeftBound, index * tickSpan);

      return {
        date,
        key: date.toISOString(),
        x: xScale(date),
        label: format(date, 'HH:mm'),
      };
    });
  }, [xDomain, xScale, domainMins, tickSpan]);

  return (
    <>
      <svg width={width} height={height}>
        <ScrollingViewport width={chartWidth} height={height} xDomain={xDomain} tickPeriod={tickPeriod} xScale={xScale}>
          <g>
            <Group top={yScale(0.8)}>
              {/* XAxis */}
              {axisTicks.map((tick, index) => {
                const { key, label, x } = tick;
                const textWidth = 100;
                return (
                  <Group key={`${key}-${index}`} left={x}>
                    <Text width={textWidth} textAnchor='middle' className='fill-emphasis-base font-bold'>
                      {label}
                    </Text>
                    {/* Vertical tick lines */}
                    <Line
                      from={{ x: 0, y: yScale(0.95) }}
                      to={{ x: 0, y: yScale(0.85) }}
                      className='stroke-divider-muted'
                      strokeWidth={1}
                    />
                  </Group>
                );
              })}
            </Group>

            {/* HorizontalDivider Line sitting at 2/6, dividing the timelines at 1/6 and 3/6 */}
            <Line
              from={{ x: 0, y: yScale(0.33333) }}
              to={{ x: width, y: yScale(0.33333) }}
              className='stroke-divider-muted'
              strokeWidth={1}
            />

            {/* Draw one Timeline plot for each timeline of events */}
            {data.timelines.map((timeline) => {
              return (
                <Timeline
                  key={timeline.label}
                  isAboveDivider={timeline.isAboveDivider}
                  events={timeline.events}
                  xScale={xScale}
                  yScale={yScale}
                  eventType={timeline.eventType}
                  circleDiameter={circleRadius}
                />
              );
            })}
          </g>
        </ScrollingViewport>
        {/* Draw the legend outside of the animating viewport, so that it stays static */}
        <TimelineLegend xStart={chartWidth} width={legendWidth} height={height} legends={legends} yScale={yScale} />
      </svg>
    </>
  );
};

interface TimelineProps {
  isAboveDivider: boolean;
  events: Array<{ date: Date }>;
  yScale: ScaleLinear<number, number, never>;
  xScale: ScaleTime<number, number, never>;
  eventType: TimelineEventType;
  circleDiameter: number;
}

const Timeline = (props: TimelineProps): JSX.Element => {
  const { isAboveDivider, events, xScale, yScale, eventType, circleDiameter } = props;

  const yFraction = isAboveDivider ? (1 / 6) * 3 : (1 / 6) * 1; // Position top line at 3 sixths, bottom line at one sixth, and the divider line will be between them at 2 sixths
  const y = yScale(yFraction);

  const circles = useMemo(() => {
    return events.map((event) => {
      return {
        key: event.date.toISOString(),
        cx: xScale(event.date),
        cy: 0,
        r: circleDiameter,
        color: TIMELINE_EVENT_TYPE_TO_COLOR_MAP[eventType],
      };
    });
  }, [events, xScale, eventType, circleDiameter]);

  return (
    <Group top={y}>
      {circles.map((event) => (
        <Circle key={event.key} cx={event.cx} cy={event.cy} r={event.r} className={event.color} />
      ))}
    </Group>
  );
};

export default TimelineChart;
