import { scaleLinear } from '@visx/scale';

import { animated, useSpring, easings } from '@react-spring/web';
import CollapsingList, { ITEM_HEIGHT_PERCENT, GAP_HEIGHT_PERCENT } from './CollapsingList';
// import TransparentOverlay from './transparentOverlay';
import { type NotificationEvent } from '@/stores/notifications';
import { useMemo } from 'react';
import TransparentOverlay from './TransparentOverlay';

interface CenteringListProps {
  width: number;
  height: number;
  notifications: NotificationEvent[];
  onNotificationDismissed: (notificationId: string) => void;
}

const MAX_NOTIFICATIONS = 3;

const CenteringList = (props: CenteringListProps): JSX.Element => {
  const { width, height, notifications, onNotificationDismissed } = props;

  const numNotifications = notifications.length;

  const { itemHeight, gap } = useMemo(() => {
    return {
      itemHeight: ITEM_HEIGHT_PERCENT * height,
      gap: GAP_HEIGHT_PERCENT * height,
    };
  }, [height]);

  // The top of the list is either 0, 1, or 2 half notification heights down, depending on if there are 3, 2, or 1 notifications
  const yScale = useMemo(() => {
    return scaleLinear({
      range: [height / 2 + itemHeight / 2, gap],
      domain: [0, MAX_NOTIFICATIONS],
    });
  }, [height]);

  const animationProps = useSpring({
    from: { top: yScale(0) },
    to: { top: yScale(numNotifications) },
    config: { duration: 1000, easing: easings.easeOutCubic },
  });

  return (
    <div className='relative h-full'>
      <TransparentOverlay isShowing={numNotifications > 0} />
      <animated.div
        id='centering_list_animated_part'
        style={{
          left: 0,
          ...animationProps,
        }}
        className={'absolute w-full'}
      >
        <CollapsingList
          notifications={notifications}
          height={height}
          width={width}
          onNotificationDismissed={onNotificationDismissed}
        />
      </animated.div>
    </div>
  );
};

export default CenteringList;
