import { createAsyncThunk } from '@reduxjs/toolkit';
import createHttp from '@/services/http';
import get from 'lodash/get';
import { processSiteData } from './utils';
import { type SiteConfigState } from './types';
import groupBy from 'lodash/groupBy';

const getSiteConfigQuery = `
  query getSiteConfig($siteId: String) {
    getStoreData(site_id: $siteId){
      client_id,
      site_id,
      timezone,
      target_intervals,
      store_name,
      target_times {
        boomGate,
        order,
        pay,
        pickup,
        parkedBay,
        pullForward,
        oefd, 
        tet
      }
    }
  }`;

export const getSiteConfig = createAsyncThunk<SiteConfigState, { siteId: string }>(
  'stores/getConfig',
  async ({ siteId }): Promise<SiteConfigState> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getSiteConfigQuery,
      variables: {
        siteId,
      },
    });

    const siteData = get(response, 'data.data.getStoreData', undefined);

    return {
      siteConfig: processSiteData(siteData),
    };
  },
);

const getRoiByTypeQuery = `
  query getRoiData($siteId: String, $type: String) {
    getRoiData(siteId: $siteId, type: $type) {
      id,
      type
    }
  }`;

export const getRoiByType = createAsyncThunk<Record<string, Array<{ id: string; type: string }>>, { siteId: string }>(
  'stores/getRoiByType',
  async ({ siteId }): Promise<Record<string, Array<{ id: string; type: string }>>> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getRoiByTypeQuery,
      variables: {
        siteId,
      },
    });

    const data = get(response, 'data.data.getRoiData', undefined);

    if (data === undefined || data === null) {
      window.logger.error(`No ROI data found for siteId ${siteId}`);
      return {};
    }

    return groupBy(data, (item) => item.type);
  },
);
