import WrappedHorizontalRankingBarChart from '@/widgets/components/charts/horizontalRankingBarChart';
import { WidgetWrapper } from '@/widgets/components/wrapper';
import { useAppSelector } from '@/common';
import { selectSiteIdV2 } from '@/stores/sites/generalConfigs';
import { LeaderboardMetric, selectLeaderboardByMetric } from '@/stores/leaderboard';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';
import { selectSiteId } from '@/stores/siteConfigs';

export const widgetMetadata: WidgetMetadata = {
  title: 'leaderboard',
  displayName: 'Leaderboard',
  description: 'Placeholder description',
};

export interface LeaderboardProps {
  metric?: LeaderboardMetric;
  boardTitle?: string;
  description?: string;
}

const Leaderboard = (props: LeaderboardProps): JSX.Element => {
  const { metric = LeaderboardMetric.PERFECT_CAR_COUNT, boardTitle, description } = props;
  const siteId = useAppSelector(selectSiteId);
  const siteIdV2 = useAppSelector(selectSiteIdV2);
  const data = useAppSelector(selectLeaderboardByMetric(metric));

  return (
    <>
      <div className='flex flex-col pb-4'>
        <span className='text-[2.3vh] font-medium text-emphasis-base'>{boardTitle ?? ''}</span>
        <span className='text-base font-normal text-emphasis-base'>{description ?? ''}</span>
      </div>
      {/* Use fallback approach to get siteId
        TODO: The 'siteId' can be removed after migrating all site to the new 'store' table*/}
      <WrappedHorizontalRankingBarChart siteId={siteIdV2 ?? siteId ?? ''} data={data} />
    </>
  );
};

export default function (props: LeaderboardProps & { children?: JSX.Element }): JSX.Element {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Leaderboard {...props} />
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
}
