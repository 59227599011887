import {
  type ActionReducerMapBuilder,
  createAction,
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { type RootState } from './index';
import { subMinutes } from 'date-fns';
import { filterTimestampedObjectsByDate } from './utils';

const LIVE_DEPARTURE_EVENT_RETENTION_PERIOD_MINUTES = 15;

export interface CurrentDepartureRate {
  roiId: number;
  avgDepartInterval: number;
  roiType: string;
  version: number;
}

export interface CurrentDepartureCarCount {
  roiId: number;
  departCars: number;
  roiType: string;
  version: number;
}

export interface ActiveDepartureRateTopicMessage {
  version: number;
  interval: number;
  lanes: LaneActiveDepartureRate[];
  isActive: boolean; // Total
  activeDepartureRate: number; // Total
}

export interface LaneActiveDepartureRate {
  roiId: string;
  lane: number;
  activeDepartureRate: number;
  isActive: boolean;
}

export interface TotalActiveDepartureRate {
  activeDepartureRate: number;
  isActive: boolean;
}

export interface ActiveDepartureRate {
  lanes: LaneActiveDepartureRate[];
  total: TotalActiveDepartureRate;
  interval: number;
}

export interface MostDepartureCount {
  fromTime: string;
  toTime: string;
  departCars: number;
  version: number;
}
export interface HistoricalDepartureRate {
  roiId: number;
  roiType: string;
  intervalStartTime: string;
  departRate: number;
}

export interface HistoricalDepartureCarCount {
  roiId: number;
  roiType: string;
  intervalStartTime: string;
  departCarCount: number;
}

export interface LiveDepartureEvent {
  cameraId: string;
  roiId: string;
  lane: number;
  roiType: string;
  siteId: string;
  timestamp: string;
  vehicleId: string;
}

export interface HistoricalDepartureRateState {
  interval15Min: HistoricalDepartureRate[];
}

export interface HistoricalDepartureCarCountState {
  interval15Min: HistoricalDepartureCarCount[];
}

export interface DepartureState {
  departRate: {
    current: CurrentDepartureRate[];
    interval15Min: HistoricalDepartureRate[];
  };
  departCarCount: {
    current: CurrentDepartureCarCount[];
    interval15Min: HistoricalDepartureCarCount[];
  };
  liveDepartureEvents: LiveDepartureEvent[];
  mostDepartureCount: MostDepartureCount;
  activeDepartureRate: ActiveDepartureRate;
}

const initialState: DepartureState = {
  departRate: {
    current: [],
    interval15Min: [],
  },
  departCarCount: {
    current: [],
    interval15Min: [],
  },
  liveDepartureEvents: [],
  mostDepartureCount: {
    fromTime: '',
    toTime: '',
    departCars: 0,
    version: 1,
  },
  activeDepartureRate: {
    lanes: [],
    total: {
      isActive: false,
      activeDepartureRate: 0,
    },
    interval: 15,
  },
};

export const resetBestPeriodCount = createAction('departures/resetBestPeriodCount');

export const departures = createSlice({
  name: 'departures',
  initialState,
  reducers: {
    setCurrentDepartureRate: (state, { payload }: PayloadAction<CurrentDepartureRate>) => {
      state.departRate.current = [payload];
    },
    setCurrentDepartureCarCount: (state, { payload }: PayloadAction<CurrentDepartureCarCount>) => {
      state.departCarCount.current = [payload];
    },
    setHistoricalDepartureRate: (state, { payload }: PayloadAction<HistoricalDepartureRateState>) => {
      state.departRate.interval15Min = payload.interval15Min;
    },
    setHistoricalDepartureCarCount: (state, { payload }: PayloadAction<HistoricalDepartureCarCountState>) => {
      state.departCarCount.interval15Min = payload.interval15Min;
    },
    setMostDepartureCount: (state, { payload }: PayloadAction<MostDepartureCount>) => {
      state.mostDepartureCount = payload;
    },
    setActiveDepartureRate: (state, { payload }: PayloadAction<ActiveDepartureRateTopicMessage>) => {
      const { isActive, activeDepartureRate } = payload;
      state.activeDepartureRate.total = { isActive, activeDepartureRate };
    },
    addLiveDepartureEvent: (state, { payload }: PayloadAction<LiveDepartureEvent>) => {
      const retentionPeriodStart = subMinutes(new Date(), LIVE_DEPARTURE_EVENT_RETENTION_PERIOD_MINUTES);
      // Filter out departure events that are older than we display
      const currentDepartureEvents = filterTimestampedObjectsByDate(state.liveDepartureEvents, retentionPeriodStart);
      // and add the latest departure event
      currentDepartureEvents.push(payload);

      state.liveDepartureEvents = currentDepartureEvents;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DepartureState>) => {
    builder.addCase(resetBestPeriodCount, (state) => {
      state.mostDepartureCount = {
        fromTime: '',
        toTime: '',
        departCars: 0,
        version: 1,
      };
    });
  },
});

const selectDepartureRateInterval15Min = (state: RootState): HistoricalDepartureRate[] =>
  state.departures.departRate.interval15Min;
const selectDepartureCountInterval15Min = (state: RootState): HistoricalDepartureCarCount[] =>
  state.departures.departCarCount.interval15Min;

export const selectDepartureRateCurrent = (state: RootState): CurrentDepartureRate[] =>
  state.departures.departRate.current;
export const selectDepartureCarCountCurrent = (state: RootState): CurrentDepartureCarCount[] =>
  state.departures.departCarCount.current;
export const selectDepartureRateHistorical15Min = (
  roiType: string,
): ((state: RootState) => HistoricalDepartureRate[]) =>
  createSelector([selectDepartureRateInterval15Min], (departRateInterval15Min) =>
    departRateInterval15Min.filter((item) => item.roiType === roiType).reverse(),
  );
export const selectDepartureCarCountHistorical15Min = (
  roiType: string,
): ((state: RootState) => HistoricalDepartureCarCount[]) =>
  createSelector([selectDepartureCountInterval15Min], (departCountInterval15Min) =>
    departCountInterval15Min.filter((item) => item.roiType === roiType).reverse(),
  );
export const selectLiveDepartureEvents = (state: RootState): LiveDepartureEvent[] =>
  state.departures.liveDepartureEvents;

export const selectMostDepartureCount = (state: RootState): MostDepartureCount => state.departures.mostDepartureCount;

export const selectMostRecentDeparture = (state: RootState): string | null => {
  const liveDepartureEvents = state.departures.liveDepartureEvents;

  if (liveDepartureEvents.length > 0) {
    const mostRecentDeparture = liveDepartureEvents.slice(-1);
    return mostRecentDeparture[0].timestamp;
  }

  return null;
};

export const selectTotalActiveDepartureRate = (state: RootState): TotalActiveDepartureRate => {
  return state.departures.activeDepartureRate.total;
};

export const {
  setCurrentDepartureRate,
  setCurrentDepartureCarCount,
  addLiveDepartureEvent,
  setHistoricalDepartureCarCount,
  setHistoricalDepartureRate,
  setMostDepartureCount,
  setActiveDepartureRate,
} = departures.actions;
export default departures.reducer;
