import classNames from 'classnames';

export const ConfigIcon = (): JSX.Element => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g x='5' y='5' width='20' height='20'>
        <path
          d='M15.3343 8.81587C13.9142 7.39579 11.7876 7.10613 10.0779 7.9398L12.6425 10.5044C12.918 10.78 12.918 11.225 12.6425 11.5006L11.5192 12.6239C11.2436 12.9065 10.7985 12.9065 10.523 12.6239L7.95837 10.0593C7.13176 11.7761 7.42142 13.8886 8.8415 15.3086C10.1556 16.6227 12.0773 16.9689 13.7093 16.3543L19.3331 21.978C20.0608 22.7057 21.2336 22.7057 21.9542 21.978C22.6819 21.2503 22.6819 20.0776 21.9542 19.3569L16.3587 13.7543C17.0087 12.1011 16.6696 10.1512 15.3343 8.81587Z'
          fill='#00B780'
        />
      </g>
    </svg>
  );
};

export const SwapIcon = (): JSX.Element => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g x='5' y='5' width='20' height='20'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.888 9.87913L22.2047 12.2041C22.363 12.3625 22.363 12.6291 22.1964 12.7875L19.8797 15.1125C19.6214 15.3791 19.1714 15.1958 19.1714 14.8208V13.3291H14.163C13.7047 13.3291 13.3297 12.9541 13.3297 12.4958C13.3297 12.0375 13.7047 11.6625 14.163 11.6625H19.1797V10.1708C19.1797 9.80413 19.6297 9.61247 19.888 9.87913ZM7.79635 17.2041L10.113 14.8791C10.3713 14.6125 10.8213 14.7958 10.8213 15.1708V16.6625H15.8297C16.288 16.6625 16.663 17.0375 16.663 17.4958C16.663 17.9541 16.288 18.3291 15.8297 18.3291H10.8213V19.8291C10.8213 20.1958 10.3713 20.3875 10.113 20.1208L7.79635 17.7958C7.63801 17.6291 7.63801 17.3708 7.79635 17.2041Z'
          fill='#00B780'
        />
      </g>
    </svg>
  );
};

export const SpinnerIcon = (props: any): JSX.Element => (
  <svg
    aria-hidden='true'
    className={classNames('w-8 h-8 text-gray-200 animate-spin fill-indicator-okay', props.className)}
    viewBox='0 0 100 100'
    fill='none'
  >
    <path
      d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
      fill='currentColor'
    />
    <path
      d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
      fill='currentFill'
    />
  </svg>
);
