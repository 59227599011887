import { ParentSize } from '@visx/responsive';
import TimeSeriesBarChart, { type TimeSeriesBarChartProps } from './TimeSeriesBarChart';

export interface WrappedTimeSeriesBarChartProps extends Omit<TimeSeriesBarChartProps, 'width' | 'height'> {}

const WrappedTimeSeriesBarChart = (props: WrappedTimeSeriesBarChartProps): JSX.Element => {
  return (
    <ParentSize>{({ width, height }) => <TimeSeriesBarChart {...props} width={width} height={height} />}</ParentSize>
  );
};

export default WrappedTimeSeriesBarChart;
export { ChartSize } from './TimeSeriesBarChart';
export { type TimeSeriesBarChartModel, AggregationMethod } from './utils';
