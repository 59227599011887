import { FingermarkLogo } from './components/logos';
import Battery from './components/battery';
import { isEmpty, useAppSelector } from '@/common';
import { selectTime } from '@/stores/calendar';
import { useMemo } from 'react';
import { selectClientConfig } from '@/stores/sites/generalConfigs';

const DashboardHeader = (): JSX.Element => {
  const time = useAppSelector(selectTime);
  const clientConfig = useAppSelector(selectClientConfig);

  const clientId = useMemo(() => clientConfig?.id, [clientConfig]);
  const logoUrl = `${process.env.REACT_APP_EYECUE_DASHBOARD}/logos/${clientId}.svg `;
  const blankLogo = `${process.env.REACT_APP_EYECUE_DASHBOARD}/logos/blank_logo.svg `;

  return (
    <div className='border-b border-divider-loud'>
      <div className='flex items-center justify-between px-8 py-4' aria-label='Global'>
        <div className='flex flex-1'>
          <div className='-m-1.5 p-1.5'>
            <FingermarkLogo />
          </div>
        </div>
        <div className='flex'>
          {!isEmpty(clientId) && logoUrl ? <img src={logoUrl} alt='Logo' /> : <img src={blankLogo} alt='Blank Logo' />}
        </div>
        <div className='flex flex-1 justify-end'>
          <span className='pr-[50px] text-emphasis-loud text-[1rem] font-semibold'>{time}</span>
          <Battery />
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
