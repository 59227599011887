import createAudioPlayer from '@/services/audioPlayer';
import { useEffect } from 'react';
import eventBus, { AudioEvent, CustomEventType } from '@/services/eventBus';

const AudioPlayer = (): JSX.Element => {
  const player = createAudioPlayer();

  const playAudio = (event: CustomEvent<AudioEvent>) => {
    window.logger.debug(`Playing audio: ${event.detail.src}`);
    player.stop();
    player.play(event.detail.src);
  };

  useEffect(() => {
    eventBus.on(CustomEventType.AudioEvent, playAudio);

    return () => {
      eventBus.off(CustomEventType.AudioEvent, playAudio);
    };
  }, []);

  return <></>;
};

export default AudioPlayer;
