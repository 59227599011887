export interface Size {
  width: number;
  height: number;
}

export interface StandardWidgetProps {
  title?: string;
}

export enum RoiType {
  ORDER = 'ORDER',
  PAYMENT = 'PAYMENT',
  DELIVER = 'DELIVER',
  PULL_FORWARD = 'PULL_FORWARD',
  WAITING = 'WAITING',
  FINISH = 'FINISH',
  LOST_ASSET = 'LOST_ASSET',
  START = 'START',
}

export enum CONNECTION_STATE {
  DISCONNECTED,
  DISCONNECTING,
  CONNECTED,
  CONNECTING,
  INTERRUPTED,
  RESUMED,
  ERROR,
}

export interface ThisMoment {
  year: number;
  month: number;
  dateOfMonth: number;
  hour: number;
  minute: number;
  day: number;
  dayOfWeek: string;
  durationFromMidnightInMins: number;
  dateTimeISO9075: string;
  twelveHourTime: string;
  twentyFourHourTime: string;
  timeZone: string;
  shortDate: string;
}

export type DailyRollingTarget = Record<string, Record<string, number>>;

export enum FeatureFlags {
  // Permanent feature flags go here, along with explanation of their purpose

  // eyecueDashboardUseDebug was added on 2024-02-20 to support troubleshooting live dashboard running inside tablet.
  eyecueDashboardUseDebug = 'eyecueDashboardUseDebug',

  // Future feature flags go here, along with explanation of their purpose and when they can be cleaned up

  // When OnPace widget was released, projection did not yet work on the core side.
  // This flag was added 2024-03-17 to enable Projection display in the OnPace widget for those sites that have projection returned by the OnPace topic.
  // This flag can be removed once on pace projection is enabled for all sites.
  eyecueDashboardUseOnPaceProjection = 'eyecueDashboardUseOnPaceProjection',

  // This flag was added 2024-04-16, to enable dashboards to display notification recieved from the eyecue-notification-service
  // This flag can be removed once notifications are enabled for all sites.
  eyecueDashboardUseNotificationModule = 'eyecueDashboardUseNotificationModule',

  // This flag was added 2024-06-27, to enable rolling type configuration for 'average' type widgets
  // This flag can be removed once /eyeq/rolling-15-mins is deployed to all sites
  eyecueDashboardUseRollingTypeConfiguration = 'eyecueDashboardUseRollingTypeConfiguration',

  // This flag was added 2024-07-12, to enable dashboards to post heartbeats to Victoria Metrics via eycue-heartbeat-service
  // This flag can be removed once heartbeats are enabled for all sites.
  eyecueDashboardUseHeartbeatModule = 'eyecueDashboardUseHeartbeatModule',

  // This flag was added 2024-08-06, to enable the first iteration of the swap widget functionality
  // This flag can be removed once additional work has been completed on the 'Swap Widget' UI
  eyecueDashboardUseSwapWidget = 'eyecueDashboardUseSwapWidget',
}
