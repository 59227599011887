import React, { useEffect, useState } from 'react';
import { isEmpty } from '@/common';
import { EmptyBox } from '@/widgets/components/box';
import { type DepartureRateBarChartProps } from '@/widgets/departureRateBarChart';

const Validation = (props: DepartureRateBarChartProps & { children: JSX.Element }) => {
  const { roiType, children } = props;

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(isEmpty(roiType));
  }, [roiType]);

  return hasError ? <EmptyBox placeholder='Not configured' /> : children;
};

export default Validation;
