import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

export interface TextSVGProps extends React.SVGProps<SVGSVGElement> {
  children: string;
}

const MetricTextSVG = (props: TextSVGProps): JSX.Element => {
  const { children, className, ...rest } = props;
  const textRef = useRef(null);
  const [computedTextLength, setComputedTextLength] = useState<number>(0);
  const [computedLineHeight, setComputedLineHeight] = useState<number>(0);

  useEffect(() => {
    if (textRef?.current !== null) {
      const textLength = (textRef.current as any).getComputedTextLength();
      const lineHeight = window.getComputedStyle(textRef.current).lineHeight;
      setComputedLineHeight(parseFloat(lineHeight));
      setComputedTextLength(textLength);
    }
  }, [children, textRef.current]);

  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox={`0 0 ${computedTextLength} ${computedLineHeight}`}
      className={classNames('roboto-fm-v2', className)}
      preserveAspectRatio={'xMidYMid meet'}
      {...rest}
    >
      {/* Using dominantBaseline & alignmentBaseline to ensure it works on Chrome, Safari, & Firefox */}
      <text ref={textRef} x='50%' y='50%' dominantBaseline='middle' alignmentBaseline='central' textAnchor='middle'>
        {children}
      </text>
    </svg>
  );
};

export default MetricTextSVG;
