import { ConfigIcon, SwapIcon } from '@/common/assets/icons';
import { NavLink } from 'react-router-dom';

export const ConfigWidgetLink = (props: { to: string }): JSX.Element => {
  const { to } = props;

  return (
    <NavLink type='button' className='rounded-full bg-white p-2 text-white shadow-sm hover:bg-slate-200' to={to}>
      <ConfigIcon />
    </NavLink>
  );
};

export const SwapWidgetLink = (props: { to: string }): JSX.Element => {
  const { to } = props;

  return (
    <NavLink type='button' className='rounded-full bg-white p-2 text-white shadow-sm hover:bg-slate-200' to={to}>
      <SwapIcon />
    </NavLink>
  );
};

interface ToolBoxProps {
  children: JSX.Element | JSX.Element[];
  hide?: boolean;
}

const ToolBox = (props: ToolBoxProps): JSX.Element => {
  const { children, hide } = props;

  return hide ? (
    <></>
  ) : (
    <div className='flex group absolute h-full w-full justify-center items-center z-50 bg-opacity-0'>
      <div className='hidden rounded-[10px] justify-center items-center transition-opacity gap-x-4 px-12 py-2 bg-button-primary-hover group-hover:flex'>
        {children}
      </div>
    </div>
  );
};

export default ToolBox;
