import { type ScaleTime, type ScaleLinear } from 'd3';
import { Chronology, type DepartureDatum } from '../../types';
import { DeparturesSegment } from '../DeparturesSegment';

export interface HistoricalDeparturesAreaProps {
  data: DepartureDatum[];
  xScale: ScaleTime<number, number, never>;
  yScale: ScaleLinear<number, number, never>;
  height: number;
}

const createPairs = <T,>(inputArray: T[]): Array<[T, T]> => {
  return inputArray.slice(0, -1).map((element, index) => [element, inputArray[index + 1]]);
};

export const HistoricalDeparturesArea = (props: HistoricalDeparturesAreaProps): JSX.Element => {
  const { data, xScale, yScale, height } = props;

  const segments = createPairs(data);

  return (
    <g>
      {segments.map((segment) => (
        <DeparturesSegment
          key={`${segment[0].date}-${segment[1].date}`}
          departures={segment}
          xScale={xScale}
          yScale={yScale}
          height={height}
          segmentType={Chronology.PAST}
        />
      ))}
    </g>
  );
};
