import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/common/assets/style/index.css';
import reportWebVitals from './reportWebVitals';
import { createCustomLogger } from '@fingermarkglobal/eyecue-app-common/logging';
import { styleTags } from './common/style-loader';
import AppRouter from './routing';

declare global {
  interface Window {
    logger: any;
    renderDashboard: (containerId: string) => void;
    unmountDashboard: (containerId: string) => void;
  }
}

// Global logger
const logger = createCustomLogger({ label: 'eyecue-dashboard' });
window.logger = logger;

// Running dashboard as standalone app
const dashboardRootEl = document.getElementById('dashboard-root');
if (dashboardRootEl !== null) {
  document.head.append(...styleTags);
  const root = ReactDOM.createRoot(dashboardRootEl);

  // Use preloaded state & sample template when run as standalone app
  // This mode will use data from './src/stores/preloadedState.ts'
  root.render(<AppRouter />);
}

let reactDOMRoot: ReactDOM.Root | undefined;

// Global method to mount dashboard as micro front-end into shawdow DOM
window.renderDashboard = (containerId: string): void => {
  document.head.append(...styleTags);
  const el = document.getElementById(containerId) as HTMLElement;

  reactDOMRoot = ReactDOM.createRoot(el);

  console.info(`renderDashboard ${containerId}`);

  reactDOMRoot.render(<AppRouter />);
};

window.unmountDashboard = (containerId: string): void => {
  console.info(`unmountDashboard ${containerId}`);

  // Remove the last style tag from the head to avoid style conflicts
  // ECM-3545: Migrate eyecue-dashboard to using css modules
  styleTags.forEach((styleTag) => {
    styleTag.remove();
  });

  reactDOMRoot?.unmount();
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
