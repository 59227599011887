import * as DOMPurify from 'dompurify';
import parse, { type Element, type DOMNode } from 'html-react-parser';
import LazyModule from '@/widgets/Lazy';
import ToolBox, { ConfigWidgetLink, SwapWidgetLink } from '@/widgets/components/toolBox';
import { type Template } from '@/stores/sites/dashboardConfigs';
import Module from '@/modules/Module';
import { FeatureFlags } from '@/types';
import Feature from '@/common/feature';

const isSlotElement = (domNode: DOMNode): boolean => {
  const isTag = domNode.type === 'tag';
  const hasAttributes = (domNode as Element).attribs !== undefined;
  const isSlot = (domNode as Element).name === 'slot';

  return isTag && hasAttributes && isSlot;
};

// XSS sanitizer for HTML
const cleanHtml = (htmlString: string): string =>
  DOMPurify.sanitize(htmlString, { ADD_TAGS: ['slot'], ADD_ATTR: ['className', 'style'] });

const parseTemplate = (
  template: Template,
  editable: boolean,
  allowSwapWidget: boolean,
): string | JSX.Element | JSX.Element[] => {
  const { grid, slots } = template;

  return parse(cleanHtml(grid), {
    replace: (domNode: DOMNode) => {
      if (isSlotElement(domNode)) {
        const slotName = (domNode as Element).attribs.name;

        if (slotName === '') {
          window.logger.warn('Missing name attribute for template slot');
          return <></>;
        }

        const slot = slots[slotName];

        if (slot === undefined) {
          window.logger.warn(`Widget for slot ${slotName} wasn't found in template`);
          return <></>;
        }

        return (
          <LazyModule component={slot.name} moduleProps={slot.config}>
            <Module enabled={editable}>
              <ToolBox>
                <ConfigWidgetLink to={`configuration?widget=${slot.name}&slot=${slotName}`} />
                <Feature flag={FeatureFlags.eyecueDashboardUseSwapWidget}>
                  <>{allowSwapWidget && <SwapWidgetLink to={`swap?widget=${slot.name}&slot=${slotName}`} />}</>
                </Feature>
              </ToolBox>
            </Module>
          </LazyModule>
        );
      }
    },
  });
};

export default parseTemplate;
