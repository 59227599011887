import { store } from '@/stores';
import createDeviceController from './awsIoT';
import { type IotDeviceController, type IotDevice, type IotSettings, type TopicToHandlersMap } from './type';
import getUnixTime from 'date-fns/getUnixTime';
import { setConnectionId } from '@/stores/ioTconnections';

export default function device(iotSettings: IotSettings, topicHandlers: TopicToHandlersMap): IotDevice {
  // Create a unique id for the device
  const id: number = getUnixTime(new Date());

  const init = (): IotDeviceController => createDeviceController(iotSettings);

  let controller: IotDeviceController = init();

  // Connect & subcribe to topics
  const start = async (): Promise<void> => {
    window.logger.debug(`Starting device ${id}`);
    store.dispatch(setConnectionId(controller.connectionId));

    await controller.connect();

    for (const topic of Object.keys(topicHandlers)) {
      await controller.subscribe(topic, topicHandlers[topic]);
    }
  };

  // Disconnect
  const stop = async (): Promise<void> => {
    window.logger.debug(`Stopping device ${id}`);
    await controller.disconnect();
  };

  // Unsubscribe from topics (still maintain connection)
  const pause = async (): Promise<void> => {
    for (const topic of Object.keys(topicHandlers)) {
      await controller.unsubscribe(topic);
    }
  };

  // Subscribe to topics
  const resume = async (): Promise<void> => {
    for (const topic of Object.keys(topicHandlers)) {
      await controller.subscribe(topic, topicHandlers[topic]);
    }
  };

  // Reset connection (swapping iot controllers)
  const reset = async (): Promise<void> => {
    window.logger.debug(`Resetting device ${id}`);

    // Swap controllers
    const temp = controller;
    controller = init();

    await start();

    // Disconnect inactive controller
    await temp.disconnect();
  };

  return {
    start,
    stop,
    pause,
    resume,
    reset,
  };
}

export { type TopicToHandlersMap };
