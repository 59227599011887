import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from './index';
import { subMinutes } from 'date-fns';
import { filterTimestampedObjectsByDate } from './utils';

const LIVE_ARRIVAL_EVENT_RETENTION_PERIOD_MINUTES = 15;

export interface LiveArrivalEvent {
  cameraId: string;
  roiId: string;
  lane: number;
  roiType: string;
  siteId: string;
  timestamp: string;
  vehicleId: string;
}

export interface ArrivalState {
  liveArrivalEvents: LiveArrivalEvent[];
}

const initialState: ArrivalState = {
  liveArrivalEvents: [],
};

export const arrivals = createSlice({
  name: 'arrivals',
  initialState,
  reducers: {
    addLiveArrivalEvent: (state, { payload }: PayloadAction<LiveArrivalEvent>) => {
      const retentionPeriodStart = subMinutes(new Date(), LIVE_ARRIVAL_EVENT_RETENTION_PERIOD_MINUTES);
      // Filter out arrival events that are older than we display
      const currentArrivalEvents = filterTimestampedObjectsByDate(state.liveArrivalEvents, retentionPeriodStart);

      // and add the latest arrival event
      currentArrivalEvents.push(payload);

      state.liveArrivalEvents = currentArrivalEvents;
    },
  },
});

export const selectLiveArrivalEvents = (state: RootState): LiveArrivalEvent[] => state.arrivals.liveArrivalEvents;

export const { addLiveArrivalEvent } = arrivals.actions;
export default arrivals.reducer;
