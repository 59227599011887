import { useAppSelector, getThisMomentTimeZone } from '@/common';
import { selectSiteTimeZone, selectTargetIntervals } from '@/stores/siteConfigs';
import { selectDepartureCarCountHistorical15Min } from '@/stores/departures';
import WrappedTimeSeriesBarChart, {
  AggregationMethod,
  ChartSize,
} from '@/widgets/components/charts/timeSeriesBarChart';
import { useMemo } from 'react';
import { WidgetWrapper } from '../components/wrapper';
import parseISO from 'date-fns/parseISO';
import { filterAndDecorateData } from '../components/charts/timeSeriesBarChart/utils';
import { isEmpty } from 'lodash';
import { StandardWidgetProps } from '@/types';
import { TargetType } from '@/stores/sites/types';
import { selectSiteTargets } from '@/stores/sites/targetConfigs';
import Validation from '@/widgets/departureCountBarChart/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';

export const widgetMetadata: WidgetMetadata = {
  title: 'departureCountBarChart',
  displayName: 'Departure Count Bar Chart',
  description: 'Placeholder description',
};
export interface DepartureCountBarChartProps extends StandardWidgetProps {
  roiType: string;
  chartSize?: ChartSize;
  hoursToDisplay?: number;
}

const DepartureCountBarChart = (props: DepartureCountBarChartProps): JSX.Element => {
  const { roiType, chartSize = ChartSize.LARGE, hoursToDisplay = 4 } = props;
  const timeZone = useAppSelector(selectSiteTimeZone);
  const targetIntervals = useAppSelector(selectTargetIntervals);
  const departCarCountHistoricalData = useAppSelector(selectDepartureCarCountHistorical15Min(roiType));
  const siteTarget = useAppSelector(selectSiteTargets);

  const barChartData = useMemo(() => {
    const graphData = departCarCountHistoricalData.map((item) => ({
      timestamp: parseISO(item.intervalStartTime).toISOString(),
      value: item.departCarCount !== null ? item.departCarCount : 0,
    }));
    const thisMoment = getThisMomentTimeZone(timeZone);
    return filterAndDecorateData(
      graphData,
      timeZone,
      hoursToDisplay,
      TargetType.DepartureCount,
      targetIntervals?.targetCounts[thisMoment.dayOfWeek.toLowerCase()],
      siteTarget,
    );
  }, [departCarCountHistoricalData, hoursToDisplay, siteTarget, targetIntervals, timeZone]);

  return (
    <WrappedTimeSeriesBarChart
      data={barChartData}
      chartSize={chartSize}
      aggregationMethod={AggregationMethod.SUM}
      hideTargetLine={isEmpty(targetIntervals) || isEmpty(targetIntervals?.targetCounts)}
    />
  );
};

export default function (props: DepartureCountBarChartProps & { children?: JSX.Element }): JSX.Element {
  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <Validation {...props}>
        <DepartureCountBarChart {...props} />
      </Validation>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
}
