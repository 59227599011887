import classNames from 'classnames';

interface DialogWrapperProps {
  open: boolean;
  onClose?: () => void;
  children: JSX.Element | JSX.Element[];
}

const DialogWrapper = (props: DialogWrapperProps): JSX.Element => {
  const { open, onClose } = props;

  return (
    <div
      className={classNames(
        open ? 'flex' : 'hidden',
        'flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full',
      )}
    >
      <div
        className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity block'
        onClick={onClose ?? (() => void 0)}
      />
      <div className='relative p-4 w-[736px] max-h-full'>
        <div className='relative bg-white rounded-lg shadow'>{props.children}</div>
      </div>
    </div>
  );
};

export const ModalHeader = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return <div className='flex h-16 items-center px-6 bg-white shadow sticky rounded-t-lg top-0 z-10'>{children}</div>;
};

export const ModalTitle = ({ children }: { children: JSX.Element | string }): JSX.Element => {
  return <span className='text-2xl font-light text-emphasis-muted'>{children}</span>;
};

export const ModalContent = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return <div className='bg-white px-4 py-5 rounded-b-lg'>{children}</div>;
};

export const CloseButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <button
      type='button'
      onClick={onClick}
      className='text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-gray-700'
      data-modal-hide='default-modal'
    >
      <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
        />
      </svg>
      <span className='sr-only'>Close</span>
    </button>
  );
};

export default DialogWrapper;
