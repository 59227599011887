import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '@/stores/index';

export enum LeaderboardMetric {
  PERFECT_CAR_COUNT = 'perfectCarCount',
  OEPE = 'oepe',
}

export const LeaderboardMetricOptions = [
  { value: LeaderboardMetric.PERFECT_CAR_COUNT, label: 'Perfect Car Count' },
  { value: LeaderboardMetric.OEPE, label: 'Order END to deliver end' },
];

export interface LeaderboardModel {
  siteId: string;
  siteName: string;
  metric: number;
}

export type LeaderboardState = Record<LeaderboardMetric, LeaderboardModel[]>;

const initialState: LeaderboardState = {
  [LeaderboardMetric.PERFECT_CAR_COUNT]: [],
  [LeaderboardMetric.OEPE]: [],
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    setLeaderboardByMetric: (
      state,
      { payload }: PayloadAction<{ metric: LeaderboardMetric; data: LeaderboardModel[] }>,
    ) => {
      switch (payload.metric) {
        case LeaderboardMetric.PERFECT_CAR_COUNT: {
          payload.data.sort((a, b) => b.metric - a.metric);
          break;
        }
        case LeaderboardMetric.OEPE: {
          payload.data.sort((a, b) => a.metric - b.metric);
          break;
        }
      }
      state[payload.metric] = payload.data;
    },
  },
});

export const { setLeaderboardByMetric } = leaderboardSlice.actions;

const selectLeaderboard = (state: RootState): LeaderboardState => state.leaderboard;

export const selectLeaderboardByMetric = (metric: LeaderboardMetric): ((state: RootState) => LeaderboardModel[]) =>
  createSelector([selectLeaderboard], (leaderboard) => leaderboard[metric]);

export default leaderboardSlice.reducer;
