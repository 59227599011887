export interface TimelineChartModel {
  timelines: Timeline[];
}

export interface Timeline {
  label: string;
  eventType: TimelineEventType;
  isAboveDivider: boolean; // Arrivals are above the divider line, departures are below the divider line
  events: Array<{ date: Date }>;
}

export enum TimelineEventType {
  ARRIVAL = 'ARRIVAL',
  DEPARTURE = 'DEPARTURE',
}
