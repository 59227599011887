import WidgetMetricWrapper from '../components/wrapper/WidgetMetricWrapper';

const Placeholder = (props: { message: string }): JSX.Element => {
  return (
    <WidgetMetricWrapper
      metricValue={0}
      placeholder={props.message}
      primaryTextClassName='fill-indicator-empty font-semibold roboto'
    />
  );
};

export default Placeholder;
