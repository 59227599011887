import { FeatureFlags } from '@/types';
import AudioPlayer from '@/modules/audio';
import Debug from '@/modules/debug';
import NotificationsContainer from '@/modules/notifications/NotificationsContainer';
import Dashboard from '@/pages/Dashboard';
import Feature from '@/common/feature';
import Heartbeat from '@/modules/heartbeat/index';

const Live = (): JSX.Element => {
  const { innerWidth: width } = window;

  return (
    <div style={{ height: '100vh' }}>
      <div
        style={{ transform: `scale(${width < 1920 ? innerWidth / 1920 : 1})` }}
        className='min-w-[1920px] min-h-[1080px] h-full w-full origin-top-left'
      >
        <Dashboard>
          {/* Dashboard plugin */}
          {/* Debug info overlay */}
          <Feature flag={FeatureFlags.eyecueDashboardUseDebug}>
            <Debug />
          </Feature>

          {/* Heartbeat */}
          <Feature flag={FeatureFlags.eyecueDashboardUseHeartbeatModule}>
            <Heartbeat />
          </Feature>

          {/* Audio */}
          <AudioPlayer />

          {/* Notifications */}
          {/* Disable notifications when in preview (when 'editable') so that config overlay and notification overlay don't conflict */}
          <Feature flag={FeatureFlags.eyecueDashboardUseNotificationModule}>
            {/* Size the same as the other 'dashboard-except-header' component, so that it overlays}
              {/* 9vh = header height + footer height */}
            {/* Use class 'unset-aspect-ratio' to unset 16/9 ratio for existing dashboard. This class can be safely removed once all dashboards are migrated */}
            <div
              style={{ height: `calc(-9vh + 100%)`, position: 'relative', top: `calc(61px - 100%)`, zIndex: 10 }}
              className='w-full unset-aspect-ratio'
            >
              <NotificationsContainer />
            </div>
          </Feature>
        </Dashboard>
      </div>
    </div>
  );
};

export default Live;
