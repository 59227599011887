import { useAppSelector, formatDurationToMinutesAndSeconds } from '@/common';
import { selectDurationFormat } from '@/stores/sites/generalConfigs';
import { Box } from '@/widgets/components/box';
import { WidgetWrapper } from '../components/wrapper';
import { StandardWidgetProps } from '@/types';
import { useZoneAverages, ZoneAverageData } from '../commonData';
import { RollingType } from '@/stores/averages';
import Validation from '@/widgets/zoneAverage/validation';
import { WidgetMetadata } from '@/modules/swapWidget/availableWidgets';
import MetricTextSVG from '../components/text/MetricTextSVG';

export const widgetMetadata: WidgetMetadata = {
  title: 'zoneAverage',
  displayName: 'Zone Average',
  description: 'Placeholder description',
};

export interface ZoneAverage {
  averageId: string;
  header?: string;
  label?: string;
}

export interface ZoneAveragesProps extends StandardWidgetProps {
  zones: ZoneAverage[];
  rollingType?: RollingType;
}

const ZoneAverages = (props: ZoneAveragesProps & { children?: JSX.Element }): JSX.Element => {
  const { zones, rollingType = RollingType.ROLLING_60_MINUTES_AVERAGES } = props;
  const durationFormat = useAppSelector(selectDurationFormat);
  const zoneAverages = useZoneAverages(zones, rollingType);

  return (
    <WidgetWrapper {...props} className='pt-5 pb-[15px] px-[30px]'>
      <ul role='list' className='grid grid-flow-col auto-cols-fr gap-5 h-full'>
        {zoneAverages.map((data: ZoneAverageData, index: number) => {
          const { header, label, average } = data;

          return (
            <li key={index}>
              <Validation {...data}>
                <Box>
                  <span className='text-[15px] font-normal'>{header ?? 'Average 60'}</span>
                  <MetricTextSVG className='text-9xl font-semibold'>
                    {average === 0 ? '-' : formatDurationToMinutesAndSeconds(average, durationFormat)}
                  </MetricTextSVG>
                  <>{label !== undefined && <span className='text-xl font-semibold'>{label}</span>}</>
                </Box>
              </Validation>
            </li>
          );
        })}
      </ul>
      {/* Widget config toolbox */}
      {props.children ?? <></>}
    </WidgetWrapper>
  );
};
export default ZoneAverages;
