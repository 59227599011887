import { ParentSize } from '@visx/responsive';
import DistributionChart, { type DistributionChartProps } from './DistributionChart';
import React from 'react';

export interface WrappedTimelineChartProps extends Omit<DistributionChartProps, 'width' | 'height'> {}

const WrappedDistributionChart = (props: WrappedTimelineChartProps): JSX.Element => {
  return (
    <ParentSize>
      {({ width, height }) => {
        return <DistributionChart {...props} width={width} height={height} />;
      }}
    </ParentSize>
  );
};

export default WrappedDistributionChart;
export { type DistributionChartProps } from './DistributionChart';
export { type DistributionDatum } from './utils';
